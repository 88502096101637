import React, { useEffect, useState } from 'react';
import { FixedObject } from 'gatsby-image';

import {
  StartScreenWrapper,
  StartScreenContent,
  StartScreenBody,
  StartScreenImage,
} from './index.styled';
import StartScreenImageClipPath from './clipPaths';
import './assets/styles.css';

type Props = {
  /**
   * Основной заголовок.
   */
  websiteTitle?: React.ReactNode;

  /**
   * Текст до стрелки.
   */
  title: React.ReactNode;

  /**
   * Текст после стрелки.
   */
  subtitle: React.ReactNode;

  /**
   * Текст сверху после точки.
   */
  text: string;

  /**
   * Изображения, которые выводятся сверху справа.
   */
  image: FixedObject[];
};

const StartScreen: React.FC<Props> = ({
  websiteTitle,
  title = 'design',
  subtitle = 'delivery',
  text,
  image,
}: Props) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  return (
    <>
      <StartScreenWrapper id="start-page">
        <StartScreenContent
          style={{ display: isInitialized ? 'flex' : 'none' }}
        >
          {image && (
            <div className="image-wrapper">
              <StartScreenImage fixed={image} />
              <StartScreenImageClipPath />
            </div>
          )}

          <StartScreenBody>
            <div className="desc-wrap">
              <div className="marker" />
              <h3 className="text">{text}</h3>
            </div>

            <h1 className="title-wrap">
              {websiteTitle && (
                <span className="website-title">{websiteTitle}</span>
              )}

              <span className="website-title-wrap">
                <span className="website-title-wrap__title">{title}</span>
                <span className="website-title-wrap__arrow" />
                <span className="website-title-wrap__subtitle">{subtitle}</span>
              </span>
              <span className="wrapper-point" />
            </h1>
          </StartScreenBody>
        </StartScreenContent>
      </StartScreenWrapper>
    </>
  );
};

export default StartScreen;
