import React from 'react';
import TweenOne from 'rc-tween-one';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SvgDrawPlugin from 'rc-tween-one/es/plugin/SvgDrawPlugin';

TweenOne.plugins.push(SvgDrawPlugin);

type Props = {
  isOpen: boolean;
};

const MenuIcon: React.FC<Props> = ({ isOpen }: Props) => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
      <circle cx="30" cy="30" r="29.5" stroke="#FFFFFF" />

      <g className="open">
        <path
          d="M22 30.5C22 31.8807 20.8807 33 19.5 33C18.1193 33 17 31.8807 17 30.5C17 29.1193 18.1193 28 19.5 28C20.8807 28 22 29.1193 22 30.5Z"
          fill="white"
        />
        <path
          d="M42 30.5C42 31.8807 40.8807 33 39.5 33C38.1193 33 37 31.8807 37 30.5C37 29.1193 38.1193 28 39.5 28C40.8807 28 42 29.1193 42 30.5Z"
          fill="white"
        />
        <path
          d="M29.5 23C28.1193 23 27 21.8807 27 20.5C27 19.1193 28.1193 18 29.5 18C30.8807 18 32 19.1193 32 20.5C32 21.8807 30.8807 23 29.5 23Z"
          fill="white"
        />
        <path
          d="M29.5 43C28.1193 43 27 41.8807 27 40.5C27 39.1193 28.1193 38 29.5 38C30.8807 38 32 39.1193 32 40.5C32 41.8807 30.8807 43 29.5 43Z"
          fill="white"
        />
      </g>

      <g className="hover">
        <path
          d="M22 30.5C22 31.8807 20.8807 33 19.5 33C18.1193 33 17 31.8807 17 30.5C17 29.1193 18.1193 28 19.5 28C20.8807 28 22 29.1193 22 30.5Z"
          fill="white"
        />
        <path
          d="M42 30.5C42 31.8807 40.8807 33 39.5 33C38.1193 33 37 31.8807 37 30.5C37 29.1193 38.1193 28 39.5 28C40.8807 28 42 29.1193 42 30.5Z"
          fill="white"
        />
        <path
          d="M29.5 23C28.1193 23 27 21.8807 27 20.5C27 19.1193 28.1193 18 29.5 18C30.8807 18 32 19.1193 32 20.5C32 21.8807 30.8807 23 29.5 23Z"
          fill="white"
        />
        <path
          d="M29.5 43C28.1193 43 27 41.8807 27 40.5C27 39.1193 28.1193 38 29.5 38C30.8807 38 32 39.1193 32 40.5C32 41.8807 30.8807 43 29.5 43Z"
          fill="white"
        />
      </g>

      <g className="close" style={{ opacity: isOpen ? 1 : 0 }}>
        <TweenOne
          animation={{ SVGDraw: isOpen ? '100%' : 0, duration: 500 }}
          style={{
            fill: 'none',
            strokeLinejoin: 'round',
          }}
          component="path"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          d="M21 21 L30 30 Z"
        />
        <TweenOne
          animation={{ SVGDraw: isOpen ? '100%' : 0, duration: 500 }}
          style={{
            fill: 'none',
            strokeLinejoin: 'round',
          }}
          component="path"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          d="M39 21 L30 30 Z"
        />
        <TweenOne
          animation={{ SVGDraw: isOpen ? '100%' : 0, duration: 500 }}
          style={{
            fill: 'none',
            strokeLinejoin: 'round',
          }}
          component="path"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          d="M39 39 L30 30 Z"
        />
        <TweenOne
          animation={{ SVGDraw: isOpen ? '100%' : 0, duration: 500 }}
          style={{
            fill: 'none',
            strokeLinejoin: 'round',
          }}
          component="path"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          d="M21 39 L30 30 Z"
        />
      </g>
    </svg>
  );
};

export default MenuIcon;
