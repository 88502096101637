import React from 'react';

type Props = {
  width?: number;
  height?: number;
  stroke?: string;
  id?: string;
};

const IconCross: React.FC<Props> = (props: Props) => {
  const { width, height, stroke, id } = props;
  const useTag = `<use xlink:href="#${id || `cross_${Date.now()}`}"><svg
        id="${id || `cross_${Date.now()}`}"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1.36816 17.2634L17.6316 0.999972" stroke=${stroke} stroke-width="2"/>
        <path d="M1 1.13159L17.2635 17.395" stroke=${stroke}  stroke-width="2"/>
      </svg></use>`;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: useTag }}
    />
  );
};

IconCross.defaultProps = {
  width: 19,
  height: 19,
  stroke: '#060606',
};

export default IconCross;
