import { useCallback, useEffect } from 'react';

type KeyPressUtilsConfig = {
  onKeyPress?: (keyCode: number) => void;
};

export enum Keys {
  ARROW_LEFT = 37,
  ARROW_RIGHT = 39,
}

const useKeyPressUtils = (config: KeyPressUtilsConfig = {}): void => {
  const handleKeyPress = useCallback(
    (e): void => {
      if (typeof config.onKeyPress === 'function') {
        config.onKeyPress(e.keyCode);
      }
    },
    [config],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);
};

export default useKeyPressUtils;
