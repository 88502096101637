import React, { useContext } from 'react';
import { Modal } from 'antd';
import NavigationMenu from '../NavigationMenu';
import MenuIcon from './svg/MenuIcon';
import { HeaderWrapper, MenuButtonWrapper, MenuButton } from './index.styled';
import './assets/styles.css';
import HeaderLogo from './logo';
import PageBlockContext from '../PageBlock/context/context';
import useResponsiveUtils from '../../utils/useResponsiveUtils';

const Header: React.FC = () => {
  const [opened, setOpened] = React.useState(false);
  const handleOpenMenu = () => {
    setOpened(!opened);
  };

  const { isMobile } = useResponsiveUtils();
  const pageBlockContextData = useContext(PageBlockContext);
  const currentBlock =
    pageBlockContextData && pageBlockContextData.currentBlock;
  const pageBlockTheme =
    currentBlock && (isMobile ? currentBlock.themeMobile : currentBlock.theme);

  return (
    <>
      <HeaderWrapper>
        <HeaderLogo />
      </HeaderWrapper>
      <MenuButtonWrapper>
        <button type="button" className="header-menu" onClick={handleOpenMenu}>
          <MenuButton state={opened} theme={pageBlockTheme || 'black'}>
            <MenuIcon isOpen={opened} />
          </MenuButton>
        </button>
      </MenuButtonWrapper>
      <NavigationMenu toggleMenu={handleOpenMenu} isOpen={opened} />
      <Modal
        visible={opened}
        maskStyle={{ display: 'none' }}
        wrapClassName="support-modal-wrapper"
      />
    </>
  );
};

export default Header;
