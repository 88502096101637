import React from 'react';

const Next: React.FC = () => {
  return (
    <svg width="42" height="25" viewBox="0 0 42 25" fill="none">
      <path
        d="M40 12.6139L40.6605 13.3647L41.5056 12.6213L40.6678 11.8696L40 12.6139ZM0 13.6139H40V11.6139H0V13.6139ZM40.6678 11.8696L27.7244 0.255699L26.3887 1.7443L39.3322 13.3582L40.6678 11.8696ZM39.3395 11.863L26.3961 23.2492L27.7171 24.7508L40.6605 13.3647L39.3395 11.863Z"
        fill="#FFFDF6"
      />
    </svg>
  );
};

export default Next;
