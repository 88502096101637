import React from 'react';
import styled from 'styled-components';
import transitions from 'polished/lib/shorthands/transitions';
import theme from '../../styles/theme';

type BlockNavProps = {
  stretch: boolean;
};

export const BlockNav = styled.div<BlockNavProps>`
  font-weight: 500;
  font-size: 16px;
  height: 100%;
  line-height: 1.1em;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  justify-content: ${props => (props.stretch ? 'space-between' : 'start')};

  @media (min-width: ${theme['screen-md']}) {
    font-size: 14px;
  }

  @media (min-width: ${theme['screen-lg']}) {
    font-size: 22px;
    line-height: 24px;
    padding-right: 0;
  }
`;

type BlockNavItemProps = {
  active?: boolean;
  onClick: () => void;
  children: React.ReactNode;
  contentType?: 'text' | 'image';
};

export const BlockNavItem = styled.a<BlockNavItemProps>`
  display: block;
  color: ${theme['text-color']};
  margin-bottom: 10px;
  text-transform: lowercase;
  display: flex;
  align-items: center;
  opacity: ${props =>
    props.contentType === 'image' && !props.active ? 0.3 : 1};
  text-align: ${props => (props.contentType === 'image' ? 'center' : 'left')};
  ${transitions(['all'], '0.3s cubic-bezier(0.420, 0.000, 0.580, 1.000) 0s')};
  transform: ${props =>
    props.contentType !== 'image' && props.active
      ? `translate(0)`
      : 'translate(-27px)'};

  @media (min-width: ${theme['screen-lg']}) {
    margin-bottom: 20px;
  }

  &:hover {
    color: ${theme.blue};
    transform: translate(0);

    svg {
      opacity: 1;

      path {
        stroke: ${theme.blue};
      }
    }
  }

  svg {
    ${transitions(
      ['opacity'],
      '0.3s cubic-bezier(0.420, 0.000, 0.580, 1.000) 0s',
    )};
    opacity: ${props =>
      props.contentType !== 'image' && props.active ? `1` : '0'};

    path {
      ${transitions(
        ['fill'],
        '0.3s cubic-bezier(0.420, 0.000, 0.580, 1.000) 0s',
      )};
    }
  }
`;

export const BlockNavItemArrow = styled.span`
  display: block;
  width: 15px;
  margin-right: 12px;
  pointer-events: none;
`;

export const BlockNavImage = styled.img``;
