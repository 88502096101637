import styled from 'styled-components';
import transitions from 'polished/lib/shorthands/transitions';
import { Modal } from 'antd';
import theme from '../../styles/theme';

export const BLOCK_WIDTH = 400;
export const BLOCK_MARGIN = 40;
export const BLOCK_WIDTH_MOBILE = 300;
export const BLOCK_MARGIN_MOBILE = 20;

const CAROUSEL_WIDTH = BLOCK_WIDTH * 5 + BLOCK_MARGIN * 5;
const CAROUSEL_WIDTH_MOBILE = BLOCK_WIDTH_MOBILE * 3 + BLOCK_MARGIN_MOBILE * 3;

type CarouselWrapperProps = {
  color: 'black' | 'white';
};

export const CarouselWrapper = styled.div<CarouselWrapperProps>`
  overflow: hidden;
  height: 500px;
  padding-top: 0px;
  @media (min-width: ${theme['screen-sm']}) {
    margin-left: -80px;
    margin-right: -80px;
    overflow: hidden;
    height: 700px;
  }

  .ant-carousel {
    width: ${CAROUSEL_WIDTH}px;
    color: ${props =>
      props.color === 'white'
        ? theme['text-color']
        : theme['text-color-inverse']};
    position: absolute;
    left: 50%;
    margin-left: ${-CAROUSEL_WIDTH / 2}px;

    @media (max-width: ${theme['screen-sm']}) {
      width: ${CAROUSEL_WIDTH_MOBILE}px;
      margin-left: ${-CAROUSEL_WIDTH_MOBILE / 2}px;
    }
  }
  .slick-slide {
    margin: 0 30px;
    @media (min-width: ${theme['screen-sm']}) {
      padding: 0 ${BLOCK_MARGIN / 2}px;
      margin: auto;
    }
  }
  .slick-slide > div {
    @media (min-width: ${theme['screen-md']}) {
      margin: 0;
    }
  }

  .slick-track {
    display: flex;
    align-items: center;
    @media (min-width: ${theme['screen-md']}) {
      display: block;
    }
  }

  .slick-list {
    padding: 0 19px !important;
    margin: 0 -30px;
    @media (min-width: ${theme['screen-md']}) {
      padding: 0 50px !important;
      margin: 0;
    }
  }

  .slick-dots {
    bottom: -40px;
    top: auto;
    margin: 0;
    height: 25px;

    @media (min-width: ${theme['screen-md']}) {
      bottom: 150px;
      max-width: 360px;
      left: 50%;
      transform: translateX(-50%);
      padding: 0;
    }

    ul {
      margin: 0;
      padding: 0;

      @media (min-width: ${theme['screen-md']}) {
        padding: 0 72px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    li {
      height: 2px;
      width: 30px;
      margin-right: 3.5px;
      margin-left: 3.5px;
      vertical-align: middle;
      @media (min-width: ${theme['screen-md']}) {
        width: auto;
      }
      flex: 1;
      button {
        height: 2px;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 16px;
        width: 30px;
        @media (min-width: ${theme['screen-md']}) {
          width: 100%;
        }
      }
      &.slick-active {
        width: 30px;
        height: 2px;
        @media (min-width: ${theme['screen-md']}) {
          width: auto;
        }
        button {
          background-color: #fff;
        }
      }
    }
    .prev,
    .next {
      border: none;
      background: none;
      cursor: pointer;
      display: block;
      width: 42px;
      height: 25px;
      outline: none;
      padding: 0;
    }
  }
`;

type ContentItemProps = {
  blank?: boolean;
  active?: boolean;
  current?: boolean;
  direction?: string;
  onClick?: () => void;
  hasModal?: boolean;
  transitionType?: 'default' | 'horizontal';
};

export const ContentItem = styled.div<ContentItemProps>`
  display: block;
  cursor: ${props =>
    props.hasModal || !props.current ? 'pointer' : 'inherit'};
  margin: 0;
  padding: 0 5px;

  @media (min-width: ${theme['screen-md']}) {
    ${transitions(['opacity', 'transform'], '600ms')};
    transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    ${props => (props.current ? `opacity: 1` : ``)};
    ${props => (props.active ? `opacity: 0.4` : ``)};
    ${props => (!props.active && !props.current ? `opacity: 0` : ``)};

    ${props => (props.current ? `transform: translateY(0)` : ``)};
    ${props =>
      props.active && props.transitionType === 'default'
        ? `transform: translateY(118px)`
        : ``};
    ${props =>
      !props.current &&
      !props.active &&
      props.direction === 'left' &&
      props.transitionType === 'default'
        ? `transform: translateY(236px)`
        : ``};
    ${props =>
      !props.current &&
      !props.active &&
      props.direction === 'right' &&
      props.transitionType === 'default'
        ? `transform: translateY(100%)`
        : ``};
    margin: 0;
    padding: 0 0 236px;
  }
`;

export const CardModal = styled(Modal).attrs({
  footer: null,
  width: '100%',
})`
  height: 100% !important;
  top: 0;
  background: #fff;
  margin: 0;
  max-width: 100%;

  @media (min-width: ${theme['screen-md']}) {
    padding: 0 !important;
    display: flex;
    align-items: center;
  }

  .ant-modal-content {
    max-width: 850px;
    margin: 0 auto;
    box-shadow: none !important;
    padding: 19px;
    position: static;
    @media (min-width: ${theme['screen-md']}) {
      position: relative;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close {
    bottom: 19px;
    right: 19px;
    top: auto;

    @media (min-width: ${theme['screen-md']}) {
      top: 0;
      right: 19px;
      bottom: auto;
    }

    .anticon {
      vertical-align: middle;
    }
    .ant-modal-close-x {
      border: 1px solid #060606;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      line-height: normal;

      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      -o-transition: all 300ms;
      transition: all 300ms;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

      &:hover {
        border: 1px solid #1b61da;
        #modal-close path {
          stroke: #1b61da;
        }
      }

      @media (min-width: ${theme['screen-md']}) {
        width: 60px;
        height: 60px;
      }

      & > div {
        width: 100%;
        height: 100%;
        display: inline-block;
        text-align: center;
        line-height: 36px;
        @media (min-width: ${theme['screen-md']}) {
          line-height: 56px;
        }
      }
    }
  }
`;
