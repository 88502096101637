import React, { useState } from 'react';
import StoryContext, { StoryContextData } from './context';

type Props = { children: React.ReactNode };

const StoryProvider: React.FunctionComponent<Props> = ({ children }: Props) => {
  const [storyIndex, setStoryIndex] = useState('');

  const contextData: StoryContextData = {
    storyIndex,
    setStoryIndex,
  };

  return (
    <StoryContext.Provider value={contextData}>
      {children}
    </StoryContext.Provider>
  );
};

export default StoryProvider;
