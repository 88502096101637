import React from 'react';

export const Bottom: React.FC = () => (
  <svg width="2" height="75" viewBox="0 0 2 75" fill="none" className="bottom">
    <path
      d="M 0 75 L 0 0"
      stroke="#FFFDF6"
      strokeWidth="2"
      strokeDasharray="4"
      strokeDashoffset="4"
    />
  </svg>
);

export const Right: React.FC = () => (
  <svg width="40" height="2" viewBox="0 0 40 2" fill="none" className="right">
    <path
      d="M 40 0 L 0 0"
      stroke="#FFFDF6"
      strokeWidth="2"
      strokeDasharray="4"
      strokeDashoffset="4"
    />
  </svg>
);

export const Split: React.FC = () => (
  <svg
    width="40"
    height="140"
    viewBox="0 0 40 140"
    fill="none"
    className="split"
  >
    <path
      d="M 40 0 L 20 0 L 20 70 L 0 70"
      stroke="#FFFDF6"
      strokeWidth="2"
      strokeDasharray="4"
      strokeDashoffset="4"
    />
    <path
      d="M 40 140 L 20 140 L 20 70 L 0 70"
      stroke="#FFFDF6"
      strokeWidth="2"
      strokeDasharray="4"
      strokeDashoffset="4"
    />
  </svg>
);

export const Merge: React.FC = () => (
  <svg
    width="40"
    height="140"
    viewBox="0 0 40 140"
    fill="none"
    className="merge"
  >
    <path
      d="M 40 70 L 20 70 L 20 0 L 0 0"
      stroke="#FFFDF6"
      strokeWidth="2"
      strokeDasharray="4"
      strokeDashoffset="4"
    />
    <path
      d="M 40 70 L 20 70 L 20 140 L 0 140"
      stroke="#FFFDF6"
      strokeWidth="2"
      strokeDasharray="4"
      strokeDashoffset="4"
    />
  </svg>
);
