import styled from 'styled-components';
import { Modal } from 'antd';
import theme from '../../styles/theme';

export const Address = styled.address`
  cursor: pointer;
  transition: color ${theme.transitionSmall};

  &:hover {
    color: ${theme.blue};
  }
`;

export const ModalWrapper = styled(Modal).attrs({
  footer: null,
  width: '100%',
})`
  height: 100% !important;
  top: 0;
  background: #fff;
  margin: 0;
  max-width: 100%;

  @media (min-width: ${theme['screen-md']}) {
    padding: 0 !important;
    display: flex;
    align-items: center;
  }

  .ant-modal-content {
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
    box-shadow: none !important;
    padding: 19px;
    position: static;
    @media (min-width: ${theme['screen-md']}) {
      position: relative;
    }
  }

  .ant-modal-content {
    height: 100%;
    @media (min-width: ${theme['screen-md']}) {
      height: auto;
    }
    .ant-modal-body {
      height: 100%;
      padding: 0 0 35px;

      @media (min-width: ${theme['screen-md']}) {
        height: auto;
        padding: 0;
      }
    }
  }
  .ant-modal-close {
    bottom: 19px;
    right: 19px;
    top: auto;

    @media (min-width: ${theme['screen-md']}) {
      top: 0;
      right: 19px;
      bottom: auto;
    }

    .anticon {
      vertical-align: middle;
    }
    .ant-modal-close-x {
      border: 1px solid #060606;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      line-height: normal;
      //line-height: 38px;

      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      -o-transition: all 300ms;
      transition: all 300ms;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

      &:hover {
        border: 1px solid #1b61da;
        #modal-close path {
          stroke: #1b61da;
        }
      }

      @media (min-width: ${theme['screen-md']}) {
        width: 60px;
        height: 60px;
        //line-height: 58px;
      }

      & > div {
        width: 100%;
        height: 100%;
        display: inline-block;
        text-align: center;
        line-height: 36px;
        @media (min-width: ${theme['screen-md']}) {
          line-height: 56px;
        }
      }
    }
  }
`;
