import React from 'react';
import {
  ContentItemImage,
  ContentItemText,
  ContentItemTitle,
  ContentItemWrapper,
} from './index.styled';

type Props = {
  id: string;
  image?: string;
  children?: React.ReactNode;
  title: string;
};
const CultureCard: React.FC<Props> = ({
  id,
  image,
  title,
  children,
}: Props) => {
  return (
    <ContentItemWrapper id={id} className="culture-card">
      <ContentItemImage bg={image} />
      <ContentItemTitle>{title}</ContentItemTitle>
      {children && <ContentItemText>{children}</ContentItemText>}
    </ContentItemWrapper>
  );
};

export default CultureCard;
