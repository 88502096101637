import React from 'react';
import Texty from 'rc-texty';

import {
  Content,
  MenuItem,
  MenuItemContent,
  Label,
  MenuItemName,
  A1,
  A2,
  A3,
  A4,
  A5,
  // B1,
  // B2,
  // B3,
  // B4,
  BottomConnect,
} from './index.styled';

import { MobileBlock } from './assets/figures';
import { Bottom } from './assets/connects';

type Props = {
  toggleMenu: () => void;
};

const MobileMenu: React.FC<Props> = ({ toggleMenu }: Props) => {
  const textyProps = {
    interval: 20,
  };
  const { interval } = textyProps;

  return (
    <Content>
      <A1 delay="0.2">
        <MenuItem to="/#anchor-1" title="О нас">
          <MenuItemContent onClick={toggleMenu}>
            <MobileBlock />
            <Label delay={800}>a1</Label>
            <MenuItemName>
              <Texty delay={800} type="alpha" interval={interval}>
                О нас
              </Texty>
            </MenuItemName>

            <BottomConnect>
              <Bottom />
            </BottomConnect>
          </MenuItemContent>
        </MenuItem>
      </A1>
      <A2 delay="0.3">
        <MenuItem to="/#anchor-21" title="Подход">
          <MenuItemContent onClick={toggleMenu}>
            <MobileBlock />
            <Label delay={900}>A2</Label>
            <MenuItemName>
              <Texty delay={900} type="alpha" interval={interval}>
                Подход
              </Texty>
            </MenuItemName>

            <BottomConnect>
              <Bottom />
            </BottomConnect>
          </MenuItemContent>
        </MenuItem>
      </A2>
      <A3 delay="0.4">
        <MenuItem to="/#anchor-22" title="Услуги">
          <MenuItemContent onClick={toggleMenu}>
            <MobileBlock />
            <Label delay={1000}>A3</Label>
            <MenuItemName>
              <Texty delay={1000} type="alpha" interval={interval}>
                Услуги
              </Texty>
            </MenuItemName>

            <BottomConnect>
              <Bottom />
            </BottomConnect>
          </MenuItemContent>
        </MenuItem>
      </A3>
      <A4 delay="0.5">
        <MenuItem to="/#anchor-4" title="Отзывы клиентов">
          <MenuItemContent onClick={toggleMenu}>
            <MobileBlock />
            <Label delay={1100}>A4</Label>
            <MenuItemName>
              <Texty delay={1100} type="alpha" interval={interval}>
                Отзывы клиентов
              </Texty>
            </MenuItemName>

            <BottomConnect>
              <Bottom />
            </BottomConnect>
          </MenuItemContent>
        </MenuItem>
      </A4>
      <A5 delay="0.6">
        <MenuItem to="/#anchor-5" title="Контакты">
          <MenuItemContent onClick={toggleMenu}>
            <MobileBlock />
            <Label delay={1200}>A5</Label>
            <MenuItemName>
              <Texty delay={1200} type="alpha" interval={interval}>
                Контакты
              </Texty>
            </MenuItemName>
          </MenuItemContent>
        </MenuItem>
      </A5>

      {/* <B1 delay="0.7">
        <MenuItem to="/team#anchor-1" title="Команда">
          <MenuItemContent onClick={toggleMenu}>
            <MobileBlock />
            <Label delay={1300}>B1</Label>
            <MenuItemName>
              <Texty delay={1300} type="alpha" interval={interval}>
                Команда
              </Texty>
            </MenuItemName>

            <BottomConnect>
              <Bottom />
            </BottomConnect>
          </MenuItemContent>
        </MenuItem>
      </B1>
      <B2 delay="0.8">
        <MenuItem to="/team#anchor-21" title="Культура">
          <MenuItemContent onClick={toggleMenu}>
            <MobileBlock />
            <Label delay={1400}>B2</Label>
            <MenuItemName>
              <Texty delay={1400} type="alpha" interval={interval}>
                Культура
              </Texty>
            </MenuItemName>

            <BottomConnect>
              <Bottom />
            </BottomConnect>
          </MenuItemContent>
        </MenuItem>
      </B2>
      <B3 delay="0.9">
        <MenuItem to="/team#anchor-31" title="Жизнь в компании">
          <MenuItemContent onClick={toggleMenu}>
            <MobileBlock />
            <Label delay={1500}>B3</Label>
            <MenuItemName>
              <Texty delay={1500} type="alpha" interval={interval}>
                Жизнь в компании
              </Texty>
            </MenuItemName>

            <BottomConnect>
              <Bottom />
            </BottomConnect>
          </MenuItemContent>
        </MenuItem>
      </B3>
      <B4 delay="1">
        <MenuItem to="/team#anchor-4" title="Вакансии">
          <MenuItemContent onClick={toggleMenu}>
            <MobileBlock />
            <Label delay={1600}>B4</Label>
            <MenuItemName>
              <Texty delay={1600} type="alpha" interval={interval}>
                Вакансии
              </Texty>
            </MenuItemName>
          </MenuItemContent>
        </MenuItem>
      </B4> */}
    </Content>
  );
};

export default MobileMenu;
