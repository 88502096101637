import React from 'react';

export type StoryContextData = {
  storyIndex: string;
  setStoryIndex: (StoryIndex: string) => void;
};

const StoryContext = React.createContext<StoryContextData>({
  storyIndex: '',
  setStoryIndex: () => {
    // setStoryIndex is not implemented
  },
});

export default StoryContext;
