import { useCallback, useEffect, useState } from 'react';
import theme from '../styles/theme';

export interface ResponsiveInfo {
  isSSR: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isTouch: boolean;
}

export enum ScreenType {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}

const windowExists = typeof window !== 'undefined';
const getScreenType = (): ScreenType => {
  let screen;

  if (window.innerWidth >= parseInt(theme['screen-lg'], 10)) {
    screen = ScreenType.DESKTOP;
  } else if (window.innerWidth >= parseInt(theme['screen-md'], 10)) {
    screen = ScreenType.TABLET;
  } else {
    screen = ScreenType.MOBILE;
  }

  return screen;
};

type ResponsiveUtilsConfig = {
  onResize?: (width: number, height: number, screenType: ScreenType) => void;
};
const useResponsiveUtils = (
  config: ResponsiveUtilsConfig = {},
): ResponsiveInfo => {
  const [screenWidth, setScreenWidth] = useState<ScreenType>(
    windowExists ? getScreenType() : ScreenType.DESKTOP,
  );

  const handleResize = useCallback((): void => {
    if (!windowExists) {
      return;
    }
    const screenType = getScreenType();
    setScreenWidth(screenType);

    if (typeof config.onResize === 'function') {
      config.onResize(window.innerWidth, window.innerHeight, screenType);
    }
  }, [config]);

  const isSSR = !windowExists;
  const isMobile = screenWidth === ScreenType.MOBILE;
  const isTablet = screenWidth === ScreenType.TABLET;
  const isDesktop = screenWidth === ScreenType.DESKTOP;

  const isTouch = windowExists && 'ontouchstart' in window;

  useEffect(() => {
    if (windowExists) {
      window.addEventListener('resize', handleResize, { passive: true });
      window.addEventListener('load', handleResize, { passive: true });

      handleResize();
    }

    return () => {
      if (windowExists) {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('load', handleResize);
      }
    };
  }, []);

  return {
    isSSR,
    isDesktop,
    isTablet,
    isMobile,
    isTouch,
  };
};

export default useResponsiveUtils;
