import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import favicon from '../images/favicon.ico';

type MetaRecord = {
  name: string;
  content: string;
};

type Props = {
  description?: string;
  keywords?: string;
  lang?: string;
  meta?: MetaRecord[];
  title?: string;
};

const SEO: React.FC<Props> = ({
  description = 'Мы проектируем, создаем и доставляем до пользователей цифровые решения для государственных, промышленных и фармацевтических компаний, которые проходят процесс цифровой трансформации',
  keywords = 'цифровизация каналов взаимодействия с клиентами,автоматизация повторяющихся бизнес-процессов,управление активами организации на основе данных,управление активами организации на основе данных',
  lang = 'ru',
  meta = [],
  title,
}: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate="%s"
      link={[
        {
          rel: 'icon',
          href: favicon,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: 'https://2nova.ru',
        },
        {
          property: `og:image`,
          content: 'https://2nova.ru/images/share/2nova.jpg',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `format-detection`,
          content: 'telephone=no',
        },
      ].concat(meta)}
    />
  );
};

export default SEO;
