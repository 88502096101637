import React, { useCallback, useRef, useState } from 'react';
import { Carousel } from 'antd';

import Prev from './assets/prev';
import Next from './assets/next';
import { Wrapper, CarouselWrapper, NavButton, Slide } from './index.styled';
import useResponsiveUtils from '../../utils/useResponsiveUtils';
import { moveCarousel } from '../../utils/helpers';
import useKeyPressUtils from '../../utils/useKeyPressUtils';

type Props = {
  children: React.ReactNode[];
};

const StorySlides: React.FC<Props> = ({ children }: Props) => {
  const slickRef = useRef<Carousel>(null);
  const { isTablet } = useResponsiveUtils();
  const [activeItemIndex, setActiveIndex] = useState(0);

  const onSlideChange = (_from: number, to: number) => {
    setActiveIndex(to);
  };

  const prev = () => {
    if (slickRef.current) {
      slickRef.current.prev();
    }
  };

  const next = () => {
    if (slickRef.current) {
      slickRef.current.next();
    }
  };

  const slickWrapperRef = useRef<HTMLDivElement>(null);

  const onKeyPress = useCallback((keyCode: number): void => {
    moveCarousel(slickRef.current, slickWrapperRef.current, keyCode);
  }, []);

  useKeyPressUtils({ onKeyPress });

  return (
    <>
      <Wrapper>
        <NavButton type="button" hoverEffect="left" onClick={prev}>
          <Prev />
        </NavButton>

        <CarouselWrapper ref={slickWrapperRef}>
          <Carousel
            ref={slickRef}
            dots={false}
            centerMode={isTablet}
            draggable={isTablet}
            variableWidth={!isTablet}
            cssEase="cubic-bezier(0.420, 0.000, 0.580, 1.000)"
            beforeChange={onSlideChange}
          >
            {children.map((item, idx) => (
              <div key={String(idx)}>
                <Slide active={idx === activeItemIndex}>{item}</Slide>
              </div>
            ))}
          </Carousel>
        </CarouselWrapper>

        <NavButton type="button" hoverEffect="right" onClick={next}>
          <Next />
        </NavButton>
      </Wrapper>
    </>
  );
};

export default StorySlides;
