import styled from 'styled-components';
import theme from '../../styles/theme';

export const Wrapper = styled.div`
  width: 100vw;
  max-width: 420px;
  margin: auto;

  @media (min-width: ${theme['screen-sm']}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 20px;
    width: 100%;
    max-width: 100vw;
  }

  @media (min-width: ${theme['screen-lg']}) {
    padding: 22px 110px 20px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;

  @media (min-width: ${theme['screen-md']}) {
    height: auto;
    font-size: 0;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 290px;
  object-fit: cover;

  @media (min-width: ${theme['screen-md']}) {
    height: 420px;
  }
`;

export const ImageTrack = styled.div`
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  margin-top: 4px;
  gap: 4px;
`;

type ImageTrackItemProps = {
  isActive: boolean;
};

export const ImageTrackItem = styled.div<ImageTrackItemProps>`
  position: relative;
  flex-grow: 1;
  padding-bottom: 30%;
  opacity: ${props => (props.isActive ? 1 : 0.5)};
  transition: opacity ${theme.transitionSmall};

  &:not(:last-child) {
    margin-right: 4px;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Info = styled.div`
  padding: 10px 18px;
  color: ${theme.white};

  @media (min-width: ${theme['screen-md']}) {
    flex: 0 0 42%;
    padding-left: 20px;
  }

  @media (min-width: ${theme['screen-lg']}) {
    padding: 5px 0 0 62px;
  }
`;

export const SlideIndex = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 15px;

  @media (min-width: ${theme['screen-md']}) {
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
  }
`;

export const SlideDesc = styled.div`
  margin: 0;
  font-size: 16px;
  line-height: 19px;
  margin-top: 12px;

  @media (min-width: ${theme['screen-md']}) {
    line-height: 24px;
    margin-top: 27px;
  }
`;
