import React from 'react';

export const Start: React.FC = () => (
  <svg
    width="220"
    height="220"
    viewBox="0 0 220 220"
    fill="none"
    className="circle-start"
  >
    <circle
      className="figure"
      strokeDasharray="800"
      strokeDashoffset="800"
      cx="50%"
      cy="50%"
      r="109"
      stroke="#fdfdfd"
      strokeWidth="2"
    />
  </svg>
);

export const Select: React.FC = () => (
  <svg
    width="173"
    height="173"
    viewBox="0 0 173 173"
    fill="none"
    className="romb"
  >
    <rect
      className="figure"
      strokeDasharray="800"
      strokeDashoffset="800"
      x="86.2555"
      y="0.353553"
      width="121.468"
      height="121.468"
      stroke="#FFFDF6"
      strokeWidth="1"
      rx="9.75"
      transform="rotate(45 86.2555 0.353553)"
    />
  </svg>
);

export const Option: React.FC = () => (
  <svg
    width="180"
    height="60"
    viewBox="0 0 180 60"
    fill="none"
    className="option"
  >
    <rect
      className="figure"
      strokeDasharray="800"
      strokeDashoffset="800"
      x="0.25"
      y="0.25"
      width="179.5"
      height="59.5"
      stroke="#FFFDF6"
      strokeWidth="1"
      rx="9.75"
    />
  </svg>
);

export const Block: React.FC = () => (
  <svg
    width="180"
    height="122"
    viewBox="0 0 180 122"
    fill="none"
    className="rect"
  >
    <rect
      className="figure"
      strokeDasharray="800"
      strokeDashoffset="800"
      x="0.25"
      y="0.25"
      width="179.5"
      height="121.5"
      stroke="#FFFDF6"
      strokeWidth="1"
      rx="9.75"
    />
  </svg>
);

export const MobileBlock: React.FC = () => (
  <svg
    width="290"
    height="60"
    viewBox="0 0 290 60"
    fill="none"
    className="rect"
  >
    <rect
      className="figure"
      strokeDasharray="800"
      strokeDashoffset="800"
      x="0.25"
      y="0.25"
      width="289.5"
      height="59.5"
      stroke="#FFFDF6"
      strokeWidth="1"
    />
  </svg>
);

export const Finish: React.FC = () => (
  <svg
    width="182"
    height="182"
    viewBox="0 0 182 182"
    fill="none"
    className="circle-finish"
  >
    <circle
      className="figure"
      strokeDasharray="800"
      strokeDashoffset="800"
      cx="50%"
      cy="50%"
      r="90"
      stroke="#fdfdfd"
      strokeWidth="1"
    />
  </svg>
);
