import React from 'react';
import {
  ContentItemImage,
  ContentItemLink,
  ContentItemLinks,
  ContentItemNumber,
  ContentItemText,
  ContentItemTitle,
  ContentItemWrapper,
} from './index.styled';

type Props = {
  idx: number;
  image?: string;
  text?: string | React.ReactNode;
  title: string | React.ReactNode;
  tags?: string[];
  nowrap?: boolean;
};
const ValueCard: React.FC<Props> = ({
  idx,
  image,
  title,
  text,
  tags = [],
  nowrap = false,
}: Props) => {
  return (
    <ContentItemWrapper>
      <ContentItemNumber>{`0${idx}`}</ContentItemNumber>
      {image && (
        <ContentItemImage>
          <img src={image} alt="" />
        </ContentItemImage>
      )}
      <ContentItemTitle whiteSpace={nowrap}>{title}</ContentItemTitle>
      {text && (
        <ContentItemText>
          <span>{text}</span>
        </ContentItemText>
      )}
      {tags?.length > 0 && (
        <ContentItemLinks>
          {tags.map(tag => (
            <ContentItemLink key={tag}>{tag}</ContentItemLink>
          ))}
        </ContentItemLinks>
      )}
    </ContentItemWrapper>
  );
};

export default ValueCard;
