import styled from 'styled-components';
// import transitions from 'polished/lib/shorthands/transitions';
import theme from '../../styles/theme';

export const Before = styled.div``;
export const After = styled.div``;
export const Content = styled.div`
  margin-right: 8px;
`;

export const Component = styled.div`
  display: flex;
  height: 26px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  text-transform: uppercase;
  border: 0.5px solid ${theme.black};
  border-radius: 13px;
  padding: 0 10px;
  margin-right: 10px;
  cursor: pointer;

  @media (min-width: ${theme['screen-md']}) {
    font-size: 14px;
  }

  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    border: 0.5px solid #1b61da;
    color: #1b61da;

    svg rect {
      fill: #1b61da;
    }
  }
`;
