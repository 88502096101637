import React from 'react';

const Prev: React.FC = () => {
  return (
    <svg width="42" height="25" viewBox="0 0 42 25" fill="none">
      <path
        d="M2 12.3861L1.33951 11.6353L0.494397 12.3787L1.33215 13.1304L2 12.3861ZM42 11.3861L2 11.3861L2 13.3861L42 13.3861L42 11.3861ZM1.33215 13.1304L14.2756 24.7443L15.6113 23.2557L2.66785 11.6418L1.33215 13.1304ZM2.6605 13.137L15.6039 1.75083L14.2829 0.249166L1.33951 11.6353L2.6605 13.137Z"
        fill="#FFFDF6"
      />
    </svg>
  );
};

export default Prev;
