import React from 'react';
import { FixedObject } from 'gatsby-image';
import { FooterImage, FooterImageWrapper, LayoutWrapper } from './index.styled';
import '../../styles/fonts.css';
import '../../styles/body.css';
import 'antd/dist/antd.less';
import Analytics from '../Analytics';
import FooterClipPathDesktop from './ClipPathDesktop';
import FooterClipPathMobile from './ClipPathMobile';
import PageBlockContextProvider from '../PageBlock/context/provider';

type Props = {
  children: React.ReactNode;
  footerImage?: FixedObject;
};

const Layout: React.FC<Props> = ({ children, footerImage }: Props) => {
  return (
    <>
      <LayoutWrapper>
        <Analytics />
        <PageBlockContextProvider>{children}</PageBlockContextProvider>
        <FooterImageWrapper>
          <FooterClipPathDesktop />
          <FooterClipPathMobile />
          {footerImage && <FooterImage fixed={footerImage} />}
        </FooterImageWrapper>
      </LayoutWrapper>
    </>
  );
};

export default Layout;
