import React, { useEffect, useState } from 'react';
import { random } from 'lodash';
import StartScreenImageClipPath1 from './ClipPath1';
import StartScreenImageClipPath3 from './ClipPath3';

const availablePaths = [
  <StartScreenImageClipPath1 />,
  <StartScreenImageClipPath3 />,
];

const StartScreenImageClipPath: React.FC = () => {
  const [activePath, setActivePath] = useState(0);

  useEffect(() => {
    setActivePath(random(0, availablePaths.length - 1));
  }, []);

  return <>{availablePaths[activePath]}</>;
};

export default StartScreenImageClipPath;
