import React from 'react';

const Chevron: React.FC = () => {
  return (
    <svg width="22" height="13" viewBox="0 0 22 13" fill="none">
      <path d="M1 1L11 11.5L21 1" stroke="#fdfdfd" strokeWidth="2" />
    </svg>
  );
};

export default Chevron;
