import styled from 'styled-components';
import theme from '../../styles/theme';

export const ContactsWrapper = styled.div`
  margin-bottom: 70px;

  @media (min-width: ${theme['screen-lg']}) {
    display: grid;
    grid-template-columns: var(--page-block-left) var(--page-block-right) min-content;
    align-items: center;
  }
`;

export const ContactsImgWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  @media (min-width: ${theme['screen-md']}) {
    margin-right: 40px;
    margin-bottom: 0;
  }

  @media (min-width: ${theme['screen-lg']}) {
    margin-right: 140px;
  }
`;

export const ContactsImg = styled.div`
  position: relative;
  width: 290px;
  height: 290px;
  overflow: hidden;

  @media (min-width: ${theme['screen-xl']}) {
    flex: 0 0 400px;
    width: 400px;
    height: 400px;
  }

  &:hover svg {
    transform: scale(0.5);
    filter: blur(5px);
  }
`;

export const ContactsSystem = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transform: rotateX(65deg) rotateY(-65deg);
  animation: animSystem 24s linear infinite;

  @keyframes animSystem {
    0% {
      transform: rotateX(65deg) rotateY(0deg);
    }
    100% {
      transform: rotateX(65deg) rotateY(-360deg);
    }
  }
`;

export const ContactsSun = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform-style: preserve-3d;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  margin-top: -1em;
  margin-left: -1em;
  font-size: 7em;
  transform: rotateX(-90deg);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transform: scale(2);
    transition: all 1s cubic-bezier(0.42, 0, 0.58, 1);

    @media (min-width: ${theme['screen-xl']}) {
      transform: scale(2.5);
    }
  }
`;

type ContactsOrbitProps = {
  index: number;
};

export const ContactsOrbit = styled.div<ContactsOrbitProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform-style: preserve-3d;
  border-radius: 50%;
  width: 20em;
  height: 20em;
  margin-top: -10em;
  margin-left: -10em;
  border: 5px dashed ${theme.white};
  transform: ${props => `rotateY(-${(props.index - 1) * 60}deg)`};
  animation: ${props => `animOrbit${props.index}`} 12s linear infinite,
    animOrbitBorder 12s ${props => `${(props.index - 1) * 4}s`} ease-in-out
      infinite;

  @media (min-width: ${theme['screen-xl']}) {
    width: 24em;
    height: 24em;
    margin-top: -12em;
    margin-left: -12em;
  }

  @keyframes animOrbit1 {
    0% {
      transform: rotateZ(0deg);
    }

    100% {
      transform: rotateZ(-360deg);
    }
  }

  @keyframes animOrbit2 {
    0% {
      transform: rotateY(-60deg) rotateZ(0deg);
    }

    100% {
      transform: rotateY(-60deg) rotateZ(-360deg);
    }
  }

  @keyframes animOrbit3 {
    0% {
      transform: rotateY(-120deg) rotateZ(0deg);
    }

    100% {
      transform: rotateY(-120deg) rotateZ(360deg);
    }
  }

  @keyframes animOrbitBorder {
    0% {
      border-color: ${theme.white};
    }

    50% {
      border-color: ${theme.blue};
    }

    100% {
      border-color: ${theme.white};
    }
  }
`;

export const ContactsInfo = styled.div`
  max-width: 420px;
  text-align: center;
  margin: auto;

  @media (min-width: ${theme['screen-lg']}) {
    max-width: none;
    text-align: left;
    margin-left: 0;
  }
`;

export const ContactsTitle = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: -0.015em;
  color: white;

  @media (min-width: ${theme['screen-md']}) {
    font-size: 42px;
    line-height: 46px;
  }

  a {
    color: inherit;

    &:hover {
      color: ${theme.blue};
    }
  }
`;

export const ContactsDesc = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin-top: 40px;

  @media (min-width: ${theme['screen-md']}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (min-width: ${theme['screen-lg']}) {
    max-width: 70%;
  }
`;
