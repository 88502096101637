import styled from 'styled-components';
import theme from '../../styles/theme';
import Button from '../../l1_atoms/Button';

export const NavigatedContentWrapper = styled.div`
  width: 100vw;
  margin: 0 -16px;

  @media (min-width: ${theme['screen-md']}) {
    width: 100%;
    margin: 0;
    display: grid;
    grid-template-columns: calc(var(--page-block-left) - 20px) var(
        --page-block-right
      );
  }

  @media (min-width: ${theme['screen-lg']}) {
    display: grid;
    grid-template-columns: calc(var(--page-block-left) - 20px) calc(
        var(--page-block-right) + 100px
      );
  }

  .ant-carousel .slick-track {
    display: flex;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100vw;

  @media (min-width: ${theme['screen-md']}) {
    width: calc(100vw - var(--page-block-left) - 40px);
    padding-right: 60px;
  }

  @media (min-width: ${theme['screen-lg']}) {
    width: 100%;
  }
`;

type SlideWrapperProps = {
  isOut: boolean;
  isActive: boolean;
  isIn: boolean;
};

export const SlideWrapper = styled.div<SlideWrapperProps>`
  width: 100vw;
  padding: 0 16px;

  @media (min-width: ${theme['screen-md']}) {
    width: 100%;
    transform: translate(${props => props.isOut && -20}px);
    transform: translate(${props => props.isActive && 0}px);
    transform: translate(${props => props.isIn && 20}px);
    transition: transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
    margin-left: 20px;
  }
`;

export const NextButton = styled(Button)`
  display: none;
  overflow: hidden;
  height: 33px;
  padding: 4px 5px;
  position: absolute;
  right: 0;
  top: 5px;
  background-size: contain;
  cursor: pointer;

  @media (min-width: ${theme['screen-lg']}) {
    display: block;
  }

  &:hover {
    svg {
      transform: translate(5px);

      path {
        fill: ${theme.blue};
      }
    }
  }

  svg {
    transition: transform 0.2s cubic-bezier(0.42, 0, 0.58, 1);

    path {
      transition: fill 0.2s cubic-bezier(0.42, 0, 0.58, 1);
    }
  }
`;
