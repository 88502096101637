const defaultTheme = require('antd/dist/default-theme');
const merge = require('lodash/merge');

const COLOR_BLACK = '#0e0e0e';
const COLOR_WHITE = '#fdfdfd';
const COLOR_BLUE = '#1b61da';
const COLOR_GREEN = '#3ca475';
const COLOR_RED = '#fc7f84';
const COLOR_TEXT = '#060606';
const TRANSITION = '200ms cubic-bezier(0.42, 0, 0.58, 1)';

const GRAIN_BLACK = `url(/styles/images/grain-black.png) ${COLOR_BLACK}`;
const GRAIN_WHITE = `url(/styles/images/grain-white.png) ${COLOR_WHITE}`;

module.exports = merge(defaultTheme, {
  black: COLOR_BLACK,
  white: COLOR_WHITE,
  blue: COLOR_BLUE,
  green: COLOR_GREEN,
  red: COLOR_RED,
  backgroundBlack: GRAIN_BLACK,
  backgroundWhite: GRAIN_WHITE,
  transitionSmall: TRANSITION,
  'text-color': COLOR_TEXT,
  'text-color-inverse': COLOR_WHITE,
  'layout-body-background': GRAIN_BLACK,
  'btn-default-border': COLOR_TEXT,
  'font-family':
    "'TT Norms', 'Roboto', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
});
