const navMenu = {
  a: {
    name: 'a',
    1: {
      name: 'О нас',
    },
    2: {
      name: 'Подход',
      1: 'Как мы работаем',
      2: 'Инженерная культура',
    },
    3: {
      name: 'Услуги',
    },
    4: {
      name: 'Отзывы клиентов',
    },
    5: {
      name: 'Контакты',
    },
  },
  b: {
    name: 'b',
    1: {
      name: 'Команда',
    },
    2: {
      name: 'Наша культура',
      1: 'Мы гордимся нашей командой и культурой',
      2: 'Наши ценности',
    },
    3: {
      name: 'Жизнь в компании',
      1: 'Лидеры направлений',
      2: 'Истории',
    },
    4: {
      name: 'Вакансии',
    },
  },
  c: {
    name: 'c',
    1: {
      name: 'Поход',
    },
  },
};

export default navMenu;
