import React, { useMemo, useState } from 'react';
import PageBlockContext, { PageBlockContextData } from './context';
import { PageBlockProps } from '../typings';

type Props = { children: React.ReactNode };

const PageBlockContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [currentBlock, setCurrentBlock] = useState<PageBlockProps | null>(null);

  const contextData: PageBlockContextData = useMemo(
    () => ({
      currentBlock,
      setCurrentBlock,
    }),
    [currentBlock],
  );

  return (
    <PageBlockContext.Provider value={contextData}>
      {children}
    </PageBlockContext.Provider>
  );
};

export default PageBlockContextProvider;
