import React, { useState } from 'react';
import { navigate } from '@reach/router';
import TweenOne from 'rc-tween-one';
import { Button, LinkSuffix, PageLink } from './index.styled';
import IconEllipse from '../../l1_atoms/IconEllipse';
import useResponsiveUtils from '../../utils/useResponsiveUtils';

type Props = {
  link: string;
  linkTitle: string;
  leftIcon?: boolean;
};

const PageBlockLink: React.FC<Props> = ({
  link,
  linkTitle,
  leftIcon = false,
}: Props) => {
  const ANIMATION_DURATION = 500;
  const [isHover, setIsHover] = useState(false);
  const [isAnimationProgress, setIsAnimationProgress] = useState(false);
  const { isTouch } = useResponsiveUtils();

  const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (isTouch) {
      await new Promise(
        resolve => setTimeout(resolve, ANIMATION_DURATION), // 200ms pause after animation for better visual
      );
    }
    await navigate(link);
  };

  const arrowPositionStart = leftIcon ? { right: '-12px' } : { left: '14px' };
  const arrowPositionFinish = leftIcon ? { right: '4px' } : { left: '50%' };

  return (
    <PageLink>
      <Button
        type="button"
        reverse={leftIcon}
        onClick={onClick}
        onTouchStart={() => {
          setIsHover(true);
          setIsAnimationProgress(true);
        }}
        onMouseEnter={() => {
          setIsHover(true);
          setIsAnimationProgress(true);
        }}
        onMouseLeave={async () => {
          setIsHover(false);
          setIsAnimationProgress(true);
        }}
      >
        {linkTitle}
        <LinkSuffix marginRight={leftIcon}>
          <TweenOne
            paused={!isHover && !isAnimationProgress}
            reverse={!isHover}
            animation={[
              {
                ...arrowPositionFinish,
                duration: ANIMATION_DURATION,
                onComplete: () => setIsAnimationProgress(false),
              },
            ]}
            style={{
              ...arrowPositionStart,
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              top: '50%',
            }}
          >
            <svg
              width="28"
              height="15"
              viewBox="0 0 28 15"
              fill="none"
              style={{ transform: `rotate(${leftIcon ? '180' : '0'}deg)` }}
            >
              <path
                d="M26 7.56436L26.6339 8.33778L27.5683 7.57198L26.6414 6.79713L26 7.56436ZM0 8.56436H26V6.56436H0V8.56436ZM26.6414 6.79713L18.789 0.232776L17.5063 1.76722L25.3586 8.33158L26.6414 6.79713ZM25.3661 6.79093L17.5138 13.2266L18.7815 14.7734L26.6339 8.33778L25.3661 6.79093Z"
                fill="#FFFDF6"
              />
            </svg>
          </TweenOne>

          <TweenOne
            paused={!isHover && !isAnimationProgress}
            reverse={!isHover}
            animation={[
              {
                rotate: 360,
                duration: ANIMATION_DURATION,
                opacity: 1,
              },
            ]}
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              opacity: 0,
            }}
          >
            <span>
              <IconEllipse width={66} height={66} />
            </span>
          </TweenOne>
        </LinkSuffix>
      </Button>
    </PageLink>
  );
};

export default PageBlockLink;
