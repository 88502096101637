import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Carousel } from 'antd';
import { FixedObject } from 'gatsby-image';

import BlockImgNavigation, {
  BlockImgNavigationItem,
} from '../../l2_molecules/BlockImgNavigation';
import Next from './assets/next';
import useItemsData from '../../components/Item/useItemsData';
import {
  ContentWrapper,
  NavigatedContentWrapper,
  SlideWrapper,
  NextButton,
} from './index.styled';
import useResponsiveUtils from '../../utils/useResponsiveUtils';
import { moveCarousel } from '../../utils/helpers';
import useKeyPressUtils from '../../utils/useKeyPressUtils';

type Props = {
  children: React.ReactNode;
};

type ItemData = {
  title: string;
  image: FixedObject;
  name: string;
  position: string;
  children: React.ReactNode;
};

const NavigatedContent: React.FC<Props> = ({ children }: Props) => {
  const data = useItemsData<ItemData>(children);
  const slickRef = useRef<Carousel>(null);
  const { isMobile } = useResponsiveUtils();

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  const [activeItemIndex, setActiveIndex] = useState(0);

  const next = () => {
    if (slickRef.current) {
      slickRef.current.next();

      setActiveIndex(
        activeItemIndex + 1 !== data.length ? activeItemIndex + 1 : 0,
      );
    }
  };

  const onItemClick = (idx: number) => {
    if (slickRef.current) {
      slickRef.current.goTo(idx);
    }

    setActiveIndex(idx);
  };

  const sliderSpeed = isMobile ? 300 : 500;
  const cssEase = isMobile
    ? 'ease'
    : 'cubic-bezier(0.420, 0.000, 0.580, 1.000)';

  const slickWrapperRef = useRef<HTMLDivElement>(null);

  const onKeyPress = useCallback((keyCode: number): void => {
    moveCarousel(slickRef.current, slickWrapperRef.current, keyCode);
  }, []);

  useKeyPressUtils({ onKeyPress });

  if (!isInitialized) {
    return <></>;
  }

  return (
    <NavigatedContentWrapper>
      <BlockImgNavigation
        items={data.map(
          (item, idx): BlockImgNavigationItem => ({
            id: idx,
            title: item.title,
            image: item.image,
          }),
        )}
        onClick={(clickedItemId: number): void => onItemClick(clickedItemId)}
        activeItemId={activeItemIndex}
      />

      <ContentWrapper ref={slickWrapperRef}>
        <Carousel
          ref={slickRef}
          initialSlide={activeItemIndex}
          draggable={isMobile}
          fade={!isMobile}
          adaptiveHeight={isMobile}
          dots={false}
          speed={sliderSpeed}
          cssEase={cssEase}
          variableWidth={isMobile}
          afterChange={(current: number) => {
            if (activeItemIndex !== current) {
              setActiveIndex(current);
            }
          }}
          beforeChange={(_currentSlide: number, nextSlide: number) => {
            setActiveIndex(nextSlide);
          }}
        >
          {data.map((review, idx) => (
            <div key={String(idx)}>
              <SlideWrapper
                isOut={idx === activeItemIndex}
                isActive={idx === activeItemIndex + 1}
                isIn={idx === activeItemIndex + 2}
              >
                {review.children}
              </SlideWrapper>
            </div>
          ))}
        </Carousel>

        <NextButton onClick={next}>
          <Next />
        </NextButton>
      </ContentWrapper>
    </NavigatedContentWrapper>
  );
};

export default NavigatedContent;
