import React from 'react';
import {
  YMaps,
  Map,
  Placemark,
  ZoomControl,
  Polyline,
} from 'react-yandex-maps';
import { ModalBody, ModalWrapper } from './index.styled';
import theme from '../../styles/theme';

const AddressModal: React.FC = () => {
  const mapData = {
    center: [59.927128, 30.322945],
    zoom: 16,
    controls: [],
  };

  const coordinates = [[59.927128, 30.322945]];

  return (
    <ModalWrapper>
      <ModalBody>
        <YMaps>
          <Map
            modules={['package.full']}
            height="100%"
            width="-1"
            state={mapData}
          >
            {coordinates.map(coordinate => (
              <Placemark key={0} geometry={coordinate} />
            ))}
            <ZoomControl />
            <Polyline
              geometry={[
                [59.927057, 30.3205],
                [59.927637, 30.32013],
                [59.928456, 30.322055],
                [59.927556, 30.323373],
                [59.92718, 30.322847],
                [59.927128, 30.322945],
              ]}
              options={{
                balloonCloseButton: false,
                strokeColor: theme.blue,
                strokeWidth: 4,
                strokeOpacity: 0.5,
              }}
            />
          </Map>
        </YMaps>
      </ModalBody>
    </ModalWrapper>
  );
};

export default AddressModal;
