import styled from 'styled-components';
import transitions from 'polished/lib/shorthands/transitions';
import theme from '../../styles/theme';
import Button from '../../l1_atoms/Button';
import TrackPng from './assets/track.png';

export const CarouselWrapper = styled.div`
  @media (min-width: ${theme['screen-lg']}) {
    overflow: hidden;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  padding-top: 26px;
  padding-bottom: 110px;
  @media (min-width: ${theme['screen-lg']}) {
    padding-top: 40px;
    padding-bottom: 0;
  }
  .slick-list {
    overflow: visible;
    padding-left: var(--page-block-left);
  }
  .slick-slide {
    position: relative;
    pointer-events: auto !important;
    cursor: pointer;
    div > div > div {
      opacity: 0.4;
      ${transitions(['opacity'], '500ms')};
    }
  }
  .slick-current {
    div {
      color: #fff;
      cursor: default;
    }
    div > div > div {
      opacity: 1;
      ${transitions(['opacity'], '500ms')};
    }
  }
  .slick-dots-bottom {
    bottom: -47px;
    margin: 0;
    li {
      height: 2px;
      width: 30px;
      margin-right: 3.5px;
      margin-left: 3.5px;
      button {
        height: 2px;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 16px;
        width: 30px;
      }
      &.slick-active {
        width: 30px;
        height: 2px;
        button {
          background-color: #fff;
        }
      }
    }
  }
`;

export const NextButton = styled(Button)`
  position: relative;
  padding-left: calc(var(--page-block-left) - 26px);

  @media (min-width: ${theme['screen-lg']}) {
    margin-left: 0;
    margin-right: 0;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: url(${TrackPng}) center left repeat-x;
    left: 0;
    top: 50%;
  }

  .button {
    width: 300px;
    background: ${theme.backgroundBlack};
    border: 1px solid white;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: white;
    height: 70px;
    border-radius: 34px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 0 13px 0 23px;
    cursor: pointer;
    text-transform: lowercase;
    transform: translateZ(0);
    position: relative;
    z-index: 3;
    outline: none;
    font-size: 18px;
    max-width: 360px;

    -webkit-transition: border 300ms;
    -moz-transition: border 300ms;
    -o-transition: border 300ms;
    transition: border 300ms;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

    @media (min-width: ${theme['screen-lg']}) {
      padding: 0 30px 0 26px;
      width: 360px;
      font-size: 22px;
      height: 68px;
    }

    &.not-clicked {
      border: 1px solid #fff !important;
    }

    &:hover {
      border: 1px solid #1b61da;
    }

    &__content {
      & > div {
        position: absolute;
        left: 26px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        max-width: 220px;
        text-overflow: ellipsis;
        width: 100%;
        height: 100%;
        @media (min-width: ${theme['screen-lg']}) {
          max-width: 265px;
        }
        
        & > span {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            margin-top: -2px;
            display: flex !important;
            align-items: center;
            text-align: left !important;
        }
      }
      /*span {
        position: absolute;
        left: 26px;
        top: 0px;
        display: block;
        line-height: 66px;
        white-space: nowrap;
        overflow: hidden;
        max-width: 220px;
        text-overflow: ellipsis;
        @media (min-width: ${theme['screen-lg']}) {
          line-height: 62px;
        }
      }*/
    }

    &__after {
      margin-right: 0;
      margin-left: 15px;
      height: 17px;
      @media (min-width: ${theme['screen-lg']}) {
        margin-left: auto;
        height: auto;
      }
    }
  }
`;
