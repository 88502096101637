import { HTMLAttributes } from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div``;
export const Before = styled.div``;
export const After = styled.div``;
export const Content = styled.div``;

interface BtnProps {
  type: string;
}

export const Btn = styled.button.attrs<BtnProps>(() => ({
  type: 'button',
}))<HTMLAttributes<HTMLElement>>`
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  cursor: pointer;
`;
