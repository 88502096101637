import React, { useEffect, useState } from 'react';
import TweenOne from 'rc-tween-one';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Logo from './svg/Logo';
import { HeaderLogoWrapper, SubLogo } from './index.styled';

const START_TIMEOUT = 1500;
const TIMER_TICK_INTERVAL = 700;

const HeaderLogo: React.FC = () => {
  const [isHover, setIsHover] = useState(false);
  const [hoverTimeoutId, setHoverTimeoutId] = useState<number | null>(null);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  const texts = [
    'interactive',
    'interactive',
    'interactive',
    'creative',
    'playful',
    'intelligent',
    'awesome',
    'React.createElement(LogoText, {})',
    'デザインあ',
    'constantly changing',
    '👍😎🚀',
    '🤍 you',
    'continuous improvement',
    'join us',
  ];

  const mouseEnter = () => {
    setHoverTimeoutId(setTimeout(() => setIsHover(true), START_TIMEOUT));
  };

  const mouseLeave = () => {
    setIsHover(false);
    if (hoverTimeoutId) {
      clearTimeout(hoverTimeoutId);
    }
  };

  const timerTick = () => {
    setCurrentTextIndex(value =>
      value < texts.length - 1 ? value + 1 : value,
    );
  };

  useEffect(() => {
    if (isHover) {
      const timerId = setInterval(timerTick, TIMER_TICK_INTERVAL);
      return () => {
        clearInterval(timerId);
        setCurrentTextIndex(0);
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [isHover]);

  return (
    <AnchorLink to="/" className="header-logo" title="2nova">
      <HeaderLogoWrapper onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        <Logo />
        {isHover && (
          <TweenOne
            animation={{ opacity: 1, y: 0, duration: 300 }}
            style={{ opacity: 0, transform: 'translateY(-30px)' }}
          >
            <SubLogo>{texts[currentTextIndex]}</SubLogo>
          </TweenOne>
        )}
      </HeaderLogoWrapper>
    </AnchorLink>
  );
};

export default HeaderLogo;
