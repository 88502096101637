import React from 'react';
import {
  get,
  omit,
  isArray,
  isObject,
  isString,
  isNumber,
  isBoolean,
} from 'lodash';

function getItemData(
  item: React.ReactNode,
): Record<string, string | React.ReactNode> | null {
  if (!item || isString(item) || isNumber(item) || isBoolean(item)) {
    return null;
  }
  const propsObject = isObject(get(item, 'props')) ? get(item, 'props') : {};
  const children = get(propsObject, 'children', null) as React.ReactNode | null;

  const rest = omit(propsObject, ['children']);

  return {
    children,
    ...rest,
  };
}

function useItemsData<T = Record<string, string | React.ReactNode>>(
  items?: React.ReactNode,
): T[] {
  let result = [];

  if (!isArray(items)) {
    result = [items];
  } else {
    result = items;
  }

  const data = result.map(getItemData).filter(item => item) as unknown[];

  return data as T[];
}

export default useItemsData;
