import React, { useState } from 'react';
import {
  Wrapper,
  ImageWrapper,
  Image,
  ImageTrack,
  ImageTrackItem,
  Info,
  SlideIndex,
  SlideDesc,
} from './index.styled';

type Props = {
  name: string;
  images?: string[];
  number: string;
  children?: React.ReactNode | string;
};

const StorySlide: React.FC<Props> = ({
  images = [''],
  name,
  number,
  children,
}: Props) => {
  const [currentImage, setCurrentImage] = useState(images[0]);

  return (
    <Wrapper>
      <ImageWrapper>
        {images && <Image src={currentImage} alt={name} />}

        {images?.length > 1 && (
          <ImageTrack>
            {images.map((item, idx) => (
              <ImageTrackItem
                key={String(idx)}
                isActive={item === currentImage}
                onClick={() => {
                  setCurrentImage(images[idx]);
                }}
              >
                <img src={item} alt="" />
              </ImageTrackItem>
            ))}
          </ImageTrack>
        )}
      </ImageWrapper>
      <Info>
        <SlideIndex>{number}</SlideIndex>
        <SlideDesc>{children}</SlideDesc>
      </Info>
    </Wrapper>
  );
};

export default StorySlide;
