import React, { useCallback, useEffect } from 'react';
import { isVisibleOnTop } from '../../../utils/helpers';

const usePageBlockScrollHandler = ({
  ref,
  onVisibleChange,
}: {
  ref: React.RefObject<HTMLDivElement>;
  onVisibleChange: (value: boolean) => void;
}): void => {
  const handleScroll = useCallback((): void => {
    if (ref.current) {
      const visible = isVisibleOnTop(ref.current);

      if (typeof onVisibleChange === 'function') {
        onVisibleChange(visible);
      }
    }
  }, [onVisibleChange]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);
};

export default usePageBlockScrollHandler;
