import React from 'react';
import Texty from 'rc-texty';

import {
  Content,
  MenuItem,
  MenuItemContent,
  Label,
  MenuItemName,
  Branch,
  BranchItem,
  BranchItemContent,
  BranchItemName,
  A1,
  A2,
  A3,
  A4,
  A5,
  // B1,
  // B2,
  // B3,
  // B4,
  RightConnect,
  // BottomConnect,
  SplitConnect,
  MergeConnect,
} from './index.styled';

import { Start, Select, Option, Block, Finish } from './assets/figures';
import {
  // Bottom,
  Right,
  Split,
  Merge,
} from './assets/connects';

type Props = {
  toggleMenu: () => void;
};

const DesktopMenu: React.FC<Props> = ({ toggleMenu }: Props) => {
  const textyProps = {
    interval: 20,
  };
  const { interval } = textyProps;

  return (
    <Content>
      <A1 delay="0.2">
        <MenuItem to="/#anchor-1" title="О нас">
          <MenuItemContent onClick={toggleMenu}>
            <Start />
            <Label delay={800}>a1</Label>
            <MenuItemName>
              <Texty delay={800} type="alpha" interval={interval}>
                О нас
              </Texty>
            </MenuItemName>

            <RightConnect>
              <Right />
            </RightConnect>

            {/* <BottomConnect>
              <Bottom />
            </BottomConnect> */}
          </MenuItemContent>
        </MenuItem>
      </A1>
      <A2 delay="0.3">
        <MenuItem to="/#anchor-21" title="Подход">
          <MenuItemContent onClick={toggleMenu}>
            <Select />
            <Label delay={900}>A2</Label>
            <MenuItemName>
              <Texty delay={900} type="alpha" interval={interval}>
                Подход
              </Texty>
            </MenuItemName>

            <SplitConnect>
              <Split />
            </SplitConnect>
          </MenuItemContent>
        </MenuItem>
        <Branch delay="0.4">
          <BranchItem to="/#anchor-21" title="Как мы работаем">
            <BranchItemContent onClick={toggleMenu}>
              <Option />
              <BranchItemName>
                <Texty delay={1000} type="alpha" interval={interval}>
                  Как мы
                </Texty>
                <Texty delay={1000} type="alpha" interval={interval}>
                  работаем
                </Texty>
              </BranchItemName>
            </BranchItemContent>
          </BranchItem>
          <BranchItem to="/#anchor-22" title="Инженерная культура">
            <BranchItemContent onClick={toggleMenu}>
              <Option />
              <BranchItemName>
                <Texty delay={1000} type="alpha" interval={interval}>
                  Инженерная
                </Texty>
                <Texty delay={1000} type="alpha" interval={interval}>
                  культура
                </Texty>
              </BranchItemName>
            </BranchItemContent>
          </BranchItem>

          <MergeConnect>
            <Merge />
          </MergeConnect>
        </Branch>
      </A2>
      <A3 delay="0.5">
        <MenuItem to="/#anchor-3" title="Услуги">
          <MenuItemContent onClick={toggleMenu}>
            <Block />
            <Label delay={1100}>A3</Label>
            <MenuItemName>
              <Texty delay={1100} type="alpha" interval={interval}>
                Услуги
              </Texty>
            </MenuItemName>

            <RightConnect>
              <Right />
            </RightConnect>
          </MenuItemContent>
        </MenuItem>
      </A3>
      <A4 delay="0.6">
        <MenuItem to="/#anchor-4" title="Отзывы клиентов">
          <MenuItemContent onClick={toggleMenu}>
            <Block />
            <Label delay={1200}>A4</Label>
            <MenuItemName>
              <Texty delay={1200} type="alpha" interval={interval}>
                Отзывы
              </Texty>
              <Texty delay={1200} type="alpha" interval={interval}>
                клиентов
              </Texty>
            </MenuItemName>

            <RightConnect>
              <Right />
            </RightConnect>
          </MenuItemContent>
        </MenuItem>
      </A4>
      <A5 delay="0.7">
        <MenuItem to="/#anchor-5" title="Контакты">
          <MenuItemContent onClick={toggleMenu}>
            <Finish />
            <Label delay={1300}>A5</Label>
            <MenuItemName>
              <Texty delay={1300} type="alpha" interval={interval}>
                Контакты
              </Texty>
            </MenuItemName>
          </MenuItemContent>
        </MenuItem>
      </A5>
      {/* <B1 delay="0.3">
        <MenuItem to="/team#anchor-1" title="Команда">
          <MenuItemContent onClick={toggleMenu}>
            <Start />
            <Label delay={900}>B1</Label>
            <MenuItemName>
              <Texty delay={900} type="alpha" interval={interval}>
                Команда
              </Texty>
            </MenuItemName>

            <RightConnect>
              <Right />
            </RightConnect>
          </MenuItemContent>
        </MenuItem>
      </B1>
      <B2 delay="0.4">
        <MenuItem to="/team#anchor-21" title="Культура">
          <MenuItemContent onClick={toggleMenu}>
            <Select />
            <Label delay={1000}>B2</Label>
            <MenuItemName>
              <Texty delay={1000} type="alpha" interval={interval}>
                Культура
              </Texty>
            </MenuItemName>

            <SplitConnect>
              <Split />
            </SplitConnect>
          </MenuItemContent>
        </MenuItem>
        <Branch delay="0.5">
          <BranchItem to="/team#anchor-21" title="Горизонтальная структура">
            <BranchItemContent onClick={toggleMenu}>
              <Option />
              <BranchItemName>
                <Texty delay={1100} type="alpha" interval={interval}>
                  Горизонтальная
                </Texty>
                <Texty delay={1100} type="alpha" interval={interval}>
                  структура
                </Texty>
              </BranchItemName>
            </BranchItemContent>
          </BranchItem>
          <BranchItem to="/team#anchor-22" title="Ценности">
            <BranchItemContent onClick={toggleMenu}>
              <Option />
              <BranchItemName>
                <Texty delay={1100} type="alpha" interval={interval}>
                  Ценности
                </Texty>
              </BranchItemName>
            </BranchItemContent>
          </BranchItem>

          <MergeConnect>
            <Merge />
          </MergeConnect>
        </Branch>
      </B2>
      <B3 delay="0.6">
        <MenuItem to="/team#anchor-31" title="Жизнь в компании">
          <MenuItemContent onClick={toggleMenu}>
            <Block />
            <Label delay={1200}>B3</Label>
            <MenuItemName>
              <Texty delay={1200} type="alpha" interval={interval}>
                Жизнь в
              </Texty>
              <Texty delay={1200} type="alpha" interval={interval}>
                компании
              </Texty>
            </MenuItemName>

            <SplitConnect>
              <Split />
            </SplitConnect>
          </MenuItemContent>
        </MenuItem>
        <Branch delay="0.7">
          <BranchItem to="/team#anchor-31" title="Лидеры направлений">
            <BranchItemContent onClick={toggleMenu}>
              <Option />
              <BranchItemName>
                <Texty delay={1300} type="alpha" interval={interval}>
                  Лидеры
                </Texty>
                <Texty delay={1300} type="alpha" interval={interval}>
                  направлений
                </Texty>
              </BranchItemName>
            </BranchItemContent>
          </BranchItem>
          <BranchItem to="/team#anchor-32" title="Истории">
            <BranchItemContent onClick={toggleMenu}>
              <Option />
              <BranchItemName>
                <Texty delay={1300} type="alpha" interval={interval}>
                  Истории
                </Texty>
              </BranchItemName>
            </BranchItemContent>
          </BranchItem>

          <MergeConnect>
            <Merge />
          </MergeConnect>
        </Branch>
      </B3>
      <B4 delay="0.8">
        <MenuItem to="/team#anchor-4" title="Вакансии">
          <MenuItemContent onClick={toggleMenu}>
            <Finish />
            <Label delay={1400}>B4</Label>
            <MenuItemName>
              <Texty delay={1400} type="alpha" interval={interval}>
                Вакансии
              </Texty>
            </MenuItemName>
          </MenuItemContent>
        </MenuItem>
      </B4> */}
    </Content>
  );
};

export default DesktopMenu;
