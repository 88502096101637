import styled from 'styled-components';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Texty from 'rc-texty';
import theme from '../../styles/theme';

const speed = 1;
const func = 'cubic-bezier(0.42, 0, 0.58, 1)';
const figureDash = `figure-dash ${speed}s ${func} both`;

type WrapperProps = {
  show: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 100px 0 40px;
  opacity: ${props => (props.show ? '1' : '0')};
  transition: opacity ${theme.transitionSmall};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Container = styled.div`
  padding: 0 80px;
  width: 100%;
  min-height: calc(100vh - 140px);

  &::before {
    content: '';
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: calc(100% + 140px);
    background: ${theme.backgroundBlack};
    pointer-events: none;
  }

  @media (min-width: ${theme['screen-xl']}) {
    display: flex;
    align-items: center;
  }
`;

export const Content = styled.div`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${theme['screen-xl']}) {
    display: grid;
    grid-template-areas:
      'a1 a2 a2 a3 a4 a5'
      'b1 b2 b2 b3 b3 b4';
    grid-row-gap: 75px;
    grid-column-gap: 40px;
    justify-content: center;
    align-items: center;
  }

  > div:not(:last-child) {
    margin-bottom: 20px;

    @media (min-width: ${theme['screen-xl']}) {
      margin-bottom: 0;
    }
  }

  @keyframes figure-dash {
    from {
      stroke-dashoffset: 800;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`;

export const MenuItem = styled(AnchorLink)`
  display: block;
  cursor: pointer;

  .figure {
    fill: transparent;
    transition: fill ${theme.transitionSmall};
  }

  &:hover {
    .item-label {
      color: ${theme.black};
    }

    .item-name {
      color: ${theme.black};
    }

    .figure {
      fill: ${theme.white};
    }
  }
`;

export const MenuItemContent = styled.span`
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.backgroundBlack};
`;

export const MenuItemName = styled.div.attrs({
  className: 'item-name',
})`
  position: absolute;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  margin: 0;
  color: ${theme.white};
  line-height: 1em;
  font-weight: 500;
  text-transform: lowercase;
  text-align: center;
  width: 100%;
  padding: 0 20px;
  transition: color ${theme.transitionSmall};
`;

export const Label = styled(Texty).attrs({
  type: 'scale',
  interval: 25,
  className: 'item-label',
})`
  position: absolute;
  left: auto;
  right: auto;
  color: ${theme.white};
  font-size: 12px;
  line-height: 1em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  transition: color ${theme.transitionSmall};
`;

type BranchProps = {
  delay?: string;
};

export const Branch = styled.div<BranchProps>`
  position: relative;
  display: grid;
  grid-row-gap: 80px;

  a:first-of-type {
    .option rect {
      animation: ${figureDash};
      animation-delay: ${props => props.delay}s;
    }
  }

  a:last-of-type {
    .option rect {
      animation: ${figureDash};
      animation-delay: ${props => Number(props.delay) + 0.1}s;
    }
  }

  a div {
    animation-delay: ${props => Number(props.delay) + 0.8}s;
  }
`;

export const BranchItem = styled(AnchorLink)`
  display: block;
  color: ${theme.white};
  font-size: 18px;
  line-height: 1.1em;
  cursor: pointer;
  transition: color ${theme.transitionSmall};

  .figure {
    fill: transparent;
    transition: fill ${theme.transitionSmall};
  }

  &:hover {
    color: ${theme.black};

    .figure {
      fill: ${theme.white};
    }
  }
`;

export const BranchItemContent = styled.span`
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BranchItemName = styled.div`
  position: absolute;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  text-transform: lowercase;
  padding: 0 10px;
  overflow: hidden;
`;

type A1Props = {
  delay?: string;
};

export const A1 = styled.div<A1Props>`
  grid-area: a1;
  position: relative;

  .item-label {
    top: 11px;
    left: 13px;

    @media (min-width: ${theme['screen-xl']}) {
      top: 25%;
      left: auto;
    }
  }

  .item-name {
    font-size: 28px;

    @media (min-width: ${theme['screen-xl']}) {
      font-size: 42px;
    }
  }

  .circle-start circle {
    animation: ${figureDash};
    animation-delay: ${props => props.delay}s;
  }

  .rect rect {
    animation: ${figureDash};
    animation-delay: ${props => props.delay}s;
    rx: 20px;
  }

  .right path,
  .bottom path {
    animation-delay: ${props => props.delay}s;
  }
`;

type A2Props = {
  delay?: string;
};

export const A2 = styled.div<A2Props>`
  grid-area: a2;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 40px;

  @media (min-width: ${theme['screen-xl']}) {
    display: grid;
    grid-template-columns: auto auto;
  }

  .item-label {
    top: 11px;
    left: 13px;

    @media (min-width: ${theme['screen-xl']}) {
      top: 20%;
      left: auto;
    }
  }

  .item-name {
    font-size: 28px;
  }

  .romb rect {
    animation: ${figureDash};
    animation-delay: ${props => props.delay}s;
  }

  .rect rect {
    animation: ${figureDash};
    animation-delay: ${props => props.delay}s;
  }

  .split path,
  .merge path,
  .bottom path {
    animation-delay: ${props => props.delay}s;
  }

  .split-wrap::after {
    animation-delay: ${props => Number(props.delay) + 0.1}s;
  }
`;

type A3Props = {
  delay?: string;
};

export const A3 = styled.div<A3Props>`
  grid-area: a3;

  .item-label {
    top: 11px;
    left: 13px;
  }

  .item-name {
    font-size: 28px;
  }

  .rect rect {
    animation: ${figureDash};
    animation-delay: ${props => props.delay}s;
  }

  .right path,
  .bottom path {
    animation-delay: ${props => props.delay}s;
  }
`;

type A4Props = {
  delay?: string;
};

export const A4 = styled.div<A4Props>`
  grid-area: a4;

  .item-label {
    top: 11px;
    left: 13px;
  }

  .item-name {
    font-size: 28px;
  }

  .rect rect {
    animation: ${figureDash};
    animation-delay: ${props => props.delay}s;
  }

  .right path,
  .bottom path {
    animation-delay: ${props => props.delay}s;
  }
`;

type A5Props = {
  delay?: string;
};

export const A5 = styled.div<A5Props>`
  grid-area: a5;

  .item-label {
    top: 11px;
    left: 13px;

    @media (min-width: ${theme['screen-xl']}) {
      top: 25%;
      left: auto;
    }
  }

  .item-name {
    font-size: 28px;
  }

  .circle-finish circle {
    animation: ${figureDash};
    animation-delay: ${props => props.delay}s;
  }

  .rect rect {
    animation: ${figureDash};
    animation-delay: ${props => props.delay}s;
    rx: 20px;
  }
`;

type B1Props = {
  delay?: string;
};

export const B1 = styled.div<B1Props>`
  grid-area: b1;
  margin-top: 30px;

  @media (min-width: ${theme['screen-xl']}) {
    margin-top: 0;
  }

  .item-label {
    top: 11px;
    left: 13px;

    @media (min-width: ${theme['screen-xl']}) {
      top: 25%;
      left: auto;
    }
  }

  .item-name {
    font-size: 28px;

    @media (min-width: ${theme['screen-xl']}) {
      font-size: 42px;
    }
  }

  .circle-start circle {
    animation: ${figureDash};
    animation-delay: ${props => props.delay}s;
  }

  .rect rect {
    animation: ${figureDash};
    animation-delay: ${props => props.delay}s;
    rx: 20px;
  }

  .right path,
  .bottom path {
    animation-delay: ${props => props.delay}s;
  }
`;

type B2Props = {
  delay?: string;
};

export const B2 = styled.div<B2Props>`
  grid-area: b2;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 40px;

  @media (min-width: ${theme['screen-xl']}) {
    display: grid;
    grid-template-columns: auto auto;
  }

  .item-label {
    top: 11px;
    left: 13px;

    @media (min-width: ${theme['screen-xl']}) {
      top: 20%;
      left: auto;
    }
  }

  .item-name {
    font-size: 28px;
  }

  .romb rect {
    animation: ${figureDash};
    animation-delay: ${props => props.delay}s;
  }

  .rect rect {
    animation: ${figureDash};
    animation-delay: ${props => props.delay}s;
  }

  .split path,
  .merge path,
  .bottom path {
    animation-delay: ${props => props.delay}s;
  }

  .split-wrap::after {
    animation-delay: ${props => Number(props.delay) + 0.1}s;
  }
`;

type B3Props = {
  delay?: string;
};

export const B3 = styled.div<B3Props>`
  grid-area: b3;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 40px;

  @media (min-width: ${theme['screen-xl']}) {
    display: grid;
    grid-template-columns: auto auto;
  }

  .item-label {
    top: 11px;
    left: 13px;
  }

  .item-name {
    font-size: 28px;
  }

  .rect rect {
    animation: ${figureDash};
    animation-delay: ${props => props.delay}s;
  }

  .split path,
  .merge path,
  .bottom path {
    animation-delay: ${props => props.delay}s;
  }

  .split-wrap::after {
    animation-delay: ${props => Number(props.delay) + 0.5}s;
  }
`;

type B4Props = {
  delay?: string;
};

export const B4 = styled.div<B4Props>`
  grid-area: b4;

  .item-label {
    top: 11px;
    left: 13px;

    @media (min-width: ${theme['screen-xl']}) {
      top: 25%;
      left: auto;
    }
  }

  .item-name {
    font-size: 28px;
  }

  .circle-finish circle {
    animation: ${figureDash};
    animation-delay: ${props => props.delay}s;
  }

  .rect rect {
    animation: ${figureDash};
    animation-delay: ${props => props.delay}s;
    rx: 20px;
  }
`;

export const RightConnect = styled.div`
  position: absolute;
  top: auto;
  bottom: auto;
  left: 100%;
  width: 40px;
  height: 2px;
  font-size: 0;

  svg path {
    animation: right-dash ${speed}s both ${func};
  }

  @keyframes right-dash {
    from {
      stroke-dasharray: 40;
      stroke-dashoffset: 40;
    }
    to {
      stroke-dasharray: 4;
      stroke-dashoffset: 4;
    }
  }
`;

export const BottomConnect = styled.div`
  position: absolute;
  top: 100%;
  left: auto;
  right: auto;
  width: 2px;
  height: 75px;
  font-size: 0;

  svg path {
    animation: bottom-dash ${speed}s both ${func};
  }

  @keyframes bottom-dash {
    from {
      stroke-dasharray: 75;
      stroke-dashoffset: 75;
    }
    to {
      stroke-dasharray: 4;
      stroke-dashoffset: 4;
    }
  }
`;

export const SplitConnect = styled.div.attrs({
  className: 'split-wrap',
})`
  position: absolute;
  left: 100%;
  top: auto;
  bottom: auto;

  &::after {
    content: 'or';
    position: absolute;
    display: block;
    font-size: 10px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${theme.white};
    width: 26px;
    height: 26px;
    border: 2px solid #fffdf6;
    border-radius: 50%;
    top: calc(50% - 13px);
    left: calc(50% - 13px);
    background: ${theme.backgroundBlack};
    animation: split-icon ${speed}s both ${func};
  }

  svg path {
    animation: split-dash ${speed}s both ${func};
  }

  @keyframes split-dash {
    from {
      stroke-dasharray: 120;
      stroke-dashoffset: 120;
    }
    to {
      stroke-dasharray: 4;
      stroke-dashoffset: 4;
    }
  }

  @keyframes split-icon {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const MergeConnect = styled.div`
  position: absolute;
  left: 100%;
  top: calc(50% - 70px);
  width: 40px;
  height: 140px;

  svg path {
    animation: merge-dash ${speed}s both ${func};
  }

  @keyframes merge-dash {
    from {
      stroke-dasharray: 120;
      stroke-dashoffset: 120;
    }
    to {
      stroke-dasharray: 4;
      stroke-dashoffset: 4;
    }
  }
`;
