import React from 'react';

const ContactSvg: React.FC = () => {
  return (
    <svg width="318" height="318" viewBox="0 0 318 319" fill="none">
      <path
        d="M167.188 94.4597L151.322 225.034"
        stroke="#1B61DA"
        strokeWidth="2"
      />
      <path
        d="M98.3977 135.209L220.395 184.32"
        stroke="#1B61DA"
        strokeWidth="2"
      />
      <path
        d="M224.5 150.525L94.2911 169.005"
        stroke="#1B61DA"
        strokeWidth="2"
      />
      <path
        d="M106.832 120.254L211.96 199.273"
        stroke="#1B61DA"
        strokeWidth="2"
      />
      <path
        d="M219.893 133.986L98.8992 185.542"
        stroke="#1B61DA"
        strokeWidth="2"
      />
      <path
        d="M211.162 119.205L107.63 200.323"
        stroke="#1B61DA"
        strokeWidth="2"
      />
      <path
        d="M118.848 107.993L199.944 211.535"
        stroke="#1B61DA"
        strokeWidth="2"
      />
      <path
        d="M133.628 99.2598L185.164 220.268"
        stroke="#1B61DA"
        strokeWidth="2"
      />
      <path
        d="M198.904 107.188L119.888 212.341"
        stroke="#1B61DA"
        strokeWidth="2"
      />
      <path
        d="M150.164 94.6501L168.629 224.879"
        stroke="#1B61DA"
        strokeWidth="2"
      />
      <path
        d="M183.953 98.7537L134.84 220.775"
        stroke="#1B61DA"
        strokeWidth="2"
      />
      <path
        d="M224.531 167.674L93.9796 151.819"
        stroke="#1B61DA"
        strokeWidth="2"
      />
      <circle
        cx="159.491"
        cy="159.95"
        r="14.5762"
        transform="rotate(-54.097 159.491 159.95)"
        fill="#060606"
      />
      <circle
        cx="159.664"
        cy="159.818"
        r="3.31277"
        transform="rotate(-54.097 159.664 159.818)"
        fill="#1B61DA"
      />
      <path
        d="M167.188 94.4597L151.322 225.034"
        stroke="#FDFDFD"
        strokeWidth="2"
      />
      <path
        d="M98.3977 135.209L220.395 184.32"
        stroke="#FDFDFD"
        strokeWidth="2"
      />
      <path
        d="M224.5 150.525L94.2911 169.005"
        stroke="#FDFDFD"
        strokeWidth="2"
      />
      <path
        d="M106.832 120.254L211.96 199.273"
        stroke="#FDFDFD"
        strokeWidth="2"
      />
      <path
        d="M219.893 133.986L98.8992 185.542"
        stroke="#FDFDFD"
        strokeWidth="2"
      />
      <path
        d="M211.162 119.205L107.63 200.323"
        stroke="#FDFDFD"
        strokeWidth="2"
      />
      <path
        d="M118.848 107.993L199.944 211.535"
        stroke="#FDFDFD"
        strokeWidth="2"
      />
      <path
        d="M133.628 99.2598L185.164 220.268"
        stroke="#FDFDFD"
        strokeWidth="2"
      />
      <path
        d="M198.904 107.188L119.888 212.341"
        stroke="#FDFDFD"
        strokeWidth="2"
      />
      <path
        d="M150.164 94.6501L168.629 224.879"
        stroke="#FDFDFD"
        strokeWidth="2"
      />
      <path
        d="M183.953 98.7537L134.84 220.775"
        stroke="#FDFDFD"
        strokeWidth="2"
      />
      <path
        d="M224.531 167.674L93.9796 151.819"
        stroke="#FDFDFD"
        strokeWidth="2"
      />
      <circle
        cx="159.491"
        cy="159.95"
        r="14.5762"
        transform="rotate(-54.097 159.491 159.95)"
        fill="#060606"
      />
      <circle
        cx="159.664"
        cy="159.818"
        r="3.31277"
        transform="rotate(-54.097 159.664 159.818)"
        fill="#FDFDFD"
      />
    </svg>
  );
};

export default ContactSvg;
