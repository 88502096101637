import styled from 'styled-components';
import theme from '../../styles/theme';

export const Wrapper = styled.div`
  position: relative;
  margin: 0 -16px;
  max-width: 1060px;

  @media (min-width: ${theme['screen-md']}) {
    border-radius: 16px;
    margin: auto;
  }
`;

export const CarouselWrapper = styled.div`
  .slick-slider {
    padding-top: 50px;

    @media (min-width: ${theme['screen-xl']}) {
      padding-top: 0;
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-dots {
    bottom: -50px;

    @media (min-width: ${theme['screen-md']}) {
      bottom: 9px;
      width: 44%;
      margin-right: 0;
      margin-left: auto;
    }
  }
`;

type NavButtonProps = {
  hoverEffect: 'left' | 'right';
};

export const NavButton = styled.button<NavButtonProps>`
  display: block;
  position: absolute;
  background: none;
  box-shadow: none;
  border: none;
  padding: 5px;
  align-self: flex-start;
  display: inline-flex;
  z-index: 1;
  ${props => props.hoverEffect}: 27px;
  top: 0;

  @media (min-width: ${theme['screen-lg']}) {
    top: 22px;
    margin-top: 13px;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    svg {
      transform: translate(
        ${props => (props.hoverEffect === 'left' ? -5 : 5)}px
      );

      path {
        fill: ${theme.blue};
      }
    }
  }

  svg {
    transition: transform 0.2s cubic-bezier(0.42, 0, 0.58, 1);

    path {
      transition: fill 0.2s cubic-bezier(0.42, 0, 0.58, 1);
    }
  }
`;

type SlideProps = {
  active: boolean;
};

export const Slide = styled.div<SlideProps>`
  width: 100vw !important;
  display: block !important;
  margin: auto;
  cursor: pointer;
  opacity: ${props => (props.active ? '1' : '0.3')};
  transition: opacity ${theme.transitionSmall};

  @media (min-width: ${theme['screen-sm']}) {
    max-width: 1060px !important;
    width: 100% !important;
  }
`;
