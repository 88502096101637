import styled from 'styled-components';
import theme from '../../styles/theme';
import { PageBlockTheme } from '../PageBlock/typings';

export const HeaderWrapper = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 24px 16px;
  z-index: 10;
  min-height: var(--header-height);

  @media (min-width: ${theme['screen-md']}) {
    padding: 20px 40px;
  }

  @media (min-width: ${theme['screen-lg']}) {
    padding: 40px 80px;
  }
`;

export const MenuButtonWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 16px;
  z-index: 10;
  min-height: var(--header-height);
  pointer-events: none;

  @media (min-width: ${theme['screen-md']}) {
    padding: 20px 40px;
  }

  @media (min-width: ${theme['screen-lg']}) {
    padding: 40px 80px;
  }
`;

type MenuButtonProps = {
  state: boolean;
  theme: PageBlockTheme;
};

export const MenuButton = styled.span<MenuButtonProps>`
  display: inline-block;
  pointer-events: auto;
  border-radius: 50%;
  overflow: hidden;

  svg {
    g,
    circle {
      transform-origin: center;
      transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
    }
    
    circle {
      stroke: ${(props): string =>
        props.theme === 'white' ? theme.black : theme.white};
    }

    .open,
    .hover {
      opacity: ${props => (props.state ? '0' : '1')};

      path {
      fill: ${(props): string =>
        props.theme === 'white' ? theme.black : theme.white};
        transform-origin: center;
        transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
      }
    }

    .close {
      path {
        transition:
          stroke 300ms cubic-bezier(0.42, 0, 0.58, 1),
          stroke-width 300ms cubic-bezier(0.42, 0, 0.58, 1);
      }
    }

    .hover {
      path {
        opacity: 0;
      }

      path:nth-child(1) {
        transform: translate(10px);
      }

      path:nth-child(2) {
        transform: translate(-10px);
      }

      path:nth-child(3) {
        transform: translateY(10px);
      }

      path:nth-child(4) {
        transform: translateY(-10px);
      }
    }

    .close {
      /* transition: opacity 0s; */
      /* opacity: ${props => (props.state ? '1' : '0')}; */

      path {
      stroke: ${(props): string =>
        props.theme === 'white' ? theme.black : theme.white};
        stroke-width: ${props => (props.state ? '2' : '5')};
      }
    }
  }

  &:hover {
    svg {
      circle {
        stroke: ${theme.blue};
      }

      .open {
        transform: rotate(180deg);

        path {
          fill: ${theme.blue};
        }

        path:nth-child(1) {
          transform: translate(-30px);
        }

        path:nth-child(2) {
          transform: translate(30px);
        }

        path:nth-child(3) {
          transform: translateY(-30px);
        }

        path:nth-child(4) {
          transform: translateY(30px);
        }
      }

      .hover {
        transform: translateY(1px) rotate(-225deg);

        path {
          opacity: 1;
          transform: translateY(0);
          fill: ${theme.blue};
        }
      }

      .close {
        path {
          stroke: ${theme.blue};
        }
      }
    }
  }
`;

export const HeaderLogoWrapper = styled.div`
  display: flex;
  height: 22px;
`;

export const SubLogo = styled.div`
  margin-left: 5px;
  color: #fff;
  font-size: 22px;
  line-height: 22px;
`;
