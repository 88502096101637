import React, { useState } from 'react';
import TweenOne from 'rc-tween-one';
import Footer from '../../components/Footer';
import FooterMenu from '../../components/FooterMenu';
import Item from '../../components/Item';
import { Address, ModalWrapper } from './index.styled';
import AddressModal from '../../l2_molecules/AddressModal';
import IconCross from '../../l1_atoms/IconCross';
import useResponsiveUtils from '../../utils/useResponsiveUtils';

const FooterBlock: React.FC = () => {
  const [pausedCross, setPausedCross] = useState(true);
  const [reverseCross, setReverseCross] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isMobile } = useResponsiveUtils();

  return (
    <>
      <Footer>
        <FooterMenu header="О нас">
          <Item href="/#anchor-1" title="кто мы" />
          <Item href="/#anchor-21" title="подход" />
          <Item href="/#anchor-22" title="услуги" />
          <Item href="/#anchor-4" title="отзывы" />
          <Item href="/#anchor-5" title="контакты" />
        </FooterMenu>
        <FooterMenu header="">
          {/* <Item href="/team#anchor-1" title="культура" />
          <Item href="/team#anchor-21" title="жизнь компании" />
          <Item href="/team#anchor-31" title="вакансии" /> */}
        </FooterMenu>
        <FooterMenu header="Контакты">
          <Item href="tel:+78123184085" title="+7 (812) 318-40-85" absolute />
          <Item
            className="address"
            title={
              <Address onClick={() => setIsModalVisible(!isModalVisible)}>
                Санкт-Петербург,
                <br />
                ул. Гороховая, д. 47, стр. 2
              </Address>
            }
          />
        </FooterMenu>
      </Footer>
      <ModalWrapper
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        closeIcon={
          <div
            onMouseEnter={() => {
              setPausedCross(false);
              setReverseCross(false);
            }}
            onMouseLeave={() => {
              setReverseCross(true);
              setPausedCross(false);
            }}
          >
            <TweenOne
              paused={pausedCross}
              reverse={reverseCross}
              animation={[
                {
                  rotate: 90,
                  duration: 300,
                  ease: 'linear',
                },
              ]}
              style={{
                lineHeight: 0,
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
            >
              <IconCross
                width={isMobile ? 16 : 19}
                height={isMobile ? 16 : 19}
                id="modal-close"
              />
            </TweenOne>
          </div>
        }
      >
        <AddressModal />
      </ModalWrapper>
    </>
  );
};

export default FooterBlock;
