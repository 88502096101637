import React from 'react';

const StartScreenImageClipPath3: React.FC = () => (
  <svg
    width="0"
    height="0"
    viewBox="0 0 820 769"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <clipPath id="header-clip-path" clipPathUnits="objectBoundingBox">
      <path d="M1.26541 -0.301054C1.27447 -0.264217 1.29377 -0.218797 1.33129 -0.224367V-0.224367C1.34506 -0.226411 1.35389 -0.236577 1.36411 -0.246028C1.39073 -0.270643 1.42209 -0.284845 1.45566 -0.284845C1.55208 -0.284845 1.63025 -0.167697 1.63025 -0.0231872C1.63025 0.0320162 1.61884 0.0832268 1.59937 0.125439C1.57573 0.179193 1.54078 0.215947 1.50202 0.23268C1.45985 0.25089 1.39585 0.240235 1.38289 0.284307V0.284307C1.35583 0.376316 1.29445 0.440547 1.22305 0.440547C1.19398 0.440547 1.16658 0.429904 1.14248 0.411077C1.11237 0.387566 1.05789 0.391014 1.02628 0.412454V0.412454C1.00218 0.428801 0.990173 0.456804 0.99575 0.485387C1.00866 0.551572 1.0042 0.62525 0.979382 0.689683C0.93117 0.814832 0.824393 0.857712 0.740888 0.785457C0.71402 0.762208 0.677669 0.756564 0.655585 0.778294C0.602184 0.83084 0.531431 0.837369 0.472334 0.786234C0.388829 0.713979 0.360218 0.553951 0.40843 0.428802V0.428802C0.418607 0.402384 0.407439 0.369929 0.386222 0.351184C0.370569 0.337354 0.356102 0.319598 0.343483 0.297981C0.279994 0.189219 0.287357 0.0239139 0.359928 -0.0712372C0.421434 -0.15188 0.510039 -0.15625 0.57428 -0.0894961C0.581008 -0.0825049 0.592292 -0.0820313 0.599629 -0.08838V-0.08838C0.626497 -0.111629 0.647931 -0.156012 0.64639 -0.195533C0.642661 -0.291202 0.674268 -0.386422 0.733418 -0.437603C0.766663 -0.466369 0.803596 -0.476886 0.839054 -0.471229C0.86677 -0.46984 0.894667 -0.458508 0.920361 -0.436276C0.947229 -0.413027 0.98358 -0.407383 1.00566 -0.429113C1.05907 -0.481658 1.12982 -0.488188 1.18892 -0.437053C1.22568 -0.405243 1.2518 -0.356422 1.26541 -0.301054Z" />
    </clipPath>
  </svg>
);

export default StartScreenImageClipPath3;
