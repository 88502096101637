import styled from 'styled-components';
import theme from '../../styles/theme';

export const BlockWrapper = styled.div`
  @media (min-width: ${theme['screen-sm']}) {
    display: flex;
  }
`;

export const CarouselNav = styled.div`
  border-right: 1px solid ${theme.black};
  padding: 20px 0;
  width: 220px;
  min-width: 220px;

  @media (min-width: ${theme['screen-lg']}) {
    padding: 30px 0;
    width: 325px;
    min-width: 325px;
  }

  @media (min-width: ${theme['screen-xl']}) {
    width: 400px;
    min-width: 400px;
  }

  @media (min-width: ${theme['screen-xxl']}) {
    width: calc(var(--page-block-left) - 80px);
    min-width: calc(var(--page-block-left) - 80px);
    padding-left: var(--page-block-nav-label);
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  margin-right: -80px;
  padding-top: 20px;
`;

export const CarouselWrapper = styled.div`
  margin-right: -300px;

  .slick-list {
    padding-left: 20px;

    @media (min-width: ${theme['screen-lg']}) {
      padding-left: 40px;
    }
  }
`;

type ContentItemProps = {
  active: boolean;
  leftSide: boolean;
  sliderSpeed: number;
  onClick: () => void;
};

export const ContentItem = styled.div<ContentItemProps>`
  padding: 20px;
  border: 0.5px solid ${theme.black};
  border-radius: 16px;
  background: ${(props): string =>
    props.theme === 'white' ? theme.backgroundWhite : 'none'};
  transform: scale(${props => (props.active || props.leftSide ? 1 : 0.85)})
    ${props => props.leftSide && 'translate(-150px)'};
  opacity: ${props => (props.active ? 1 : 0.4)};
  cursor: ${props => (props.active ? 'default' : 'pointer')};
  transition: opacity ${props => props.sliderSpeed}s
      cubic-bezier(0.42, 0, 0.58, 1),
    transform ${props => props.sliderSpeed}s cubic-bezier(0.42, 0, 0.58, 1),
    border 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  width: 320px !important;

  &:hover {
    border-color: ${props => !props.active && theme.blue};
  }

  @media (min-width: ${theme['screen-lg']}) {
    width: 480px !important;
  }

  @media (min-width: ${theme['screen-xl']}) {
    padding: 20px 40px 40px;
    width: 580px !important;
  }
`;

export const ContentItemNumber = styled.div`
  height: 23px;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
`;

export const ContentItemTitle = styled.div`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.1em;
  letter-spacing: -0.015em;
  text-transform: lowercase;

  @media (min-width: ${theme['screen-lg']}) {
    font-size: 42px;
    margin-bottom: 45px;
  }
`;

export const ContentItemText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5em;

  @media (min-width: ${theme['screen-lg']}) {
    font-size: 18px;
  }

  ul,
  ol {
    margin-bottom: 0;
  }
`;

export const ContentItemBack = styled.button`
  margin-top: 20px;
  background: none;
  box-shadow: none;
  border: none;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  transition: color ${theme.transitionSmall};

  &:focus {
    outline: 0;
  }

  &:hover {
    color: ${theme.blue};

    svg {
      transform: translate(-10px);

      path {
        fill: ${theme.blue};
      }
    }
  }

  svg {
    margin-right: 10px;
    transition: transform ${theme.transitionSmall};

    path {
      transition: fill ${theme.transitionSmall};
    }
  }
`;

type MobilePanelProps = {
  active: boolean;
};

export const MobilePanel = styled.div<MobilePanelProps>`
  border: 1px solid ${theme.white};
  margin-bottom: 20px;
  border-radius: 12px;
  overflow: hidden;
  outline: none;
  background: ${props =>
    props.active ? theme.backgroundWhite : theme.backgroundBlack};
  transition: all ${theme.transitionSmall};
  color: ${props => (props.active ? theme.black : theme.white)};
`;

type MobilePanelHeadProps = {
  index: number | string;
};

export const MobilePanelHead = styled.h3<MobilePanelHeadProps>`
  margin: 0;
  position: relative;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: -0.02em;
  padding: 15px 20px 20px;
  color: inherit;
  /* transition: color ${theme.transitionSmall}; */

  &::after {
    content: '${props => props.index}';
    font-weight: 400;
    font-size: 12.8px;
    line-height: 15px;
    text-align: right;
    position: absolute;
    top: 6px;
    right: 7px;
  }
`;

export const MobilePanelBody = styled.div`
  padding: 5px 20px;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`;

type MobilePanelFootProps = {
  active: boolean;
};

export const MobilePanelFoot = styled.div<MobilePanelFootProps>`
  text-align: center;
  padding: 14px 20px;

  svg {
    transition: transform ${theme.transitionSmall};
    transform: rotate(${props => (props.active ? 180 : 0)}deg);

    path {
      transition: stroke ${theme.transitionSmall};
      stroke: ${props => (props.active ? theme.black : theme.white)};
    }
  }
`;
