import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  id?: string;
};

const IconArrowRight: React.FC<Props> = (props: Props) => {
  const { width, height, fill, id } = props;
  const useTag = `<use xlink:href="#${id || `arrow_left_${Date.now()}`}"><svg
        id="${id || `arrow_left_${Date.now()}`}"
        viewBox="0 0 42 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      ><path d="M2 12.401L1.33999 11.6498L0.493318 12.3936L1.33263 13.1458L2 12.401ZM42 11.401L2 11.401L2 13.401L42 13.401L42 11.401ZM1.33263 13.1458L14.2761 24.7447L15.6108 23.2553L2.66737 11.6563L1.33263 13.1458ZM2.66002 13.1523L15.6035 1.78074L14.2834 0.278234L1.33999 11.6498L2.66002 13.1523Z" 
      fill=${fill}
        /></svg></use>`;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: useTag }}
    />
  );
};

IconArrowRight.defaultProps = {
  width: 42,
  height: 25,
  fill: '#060606',
};

export default IconArrowRight;
