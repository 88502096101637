import React from 'react';

type Props = {
  width?: number;
  height?: number;
  stroke?: string;
};

const IconEllipse: React.FC<Props> = (props: Props) => {
  const { width, height, stroke } = props;
  const useTag = `<use xlink:href="#ellipse_${Date.now()}">
  <svg viewBox="0 0 56 56" fill="none" id="ellipse_${Date.now()}" xmlns="http://www.w3.org/2000/svg">
    <circle cx="28" cy="28" r="27" stroke="${stroke}" stroke-width="2" stroke-dasharray="2 6"/>
  </svg>    
</use>`;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: useTag }}
    />
  );
};

IconEllipse.defaultProps = {
  width: 56,
  height: 56,
  stroke: '#1B61DA',
};

export default IconEllipse;
