import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Collapse } from 'react-collapse';
import { Carousel } from 'antd';
import useItemsData from '../../components/Item/useItemsData';
import {
  BlockWrapper,
  CarouselNav,
  CarouselWrapper,
  ContentWrapper,
  ContentItem,
  ContentItemNumber,
  ContentItemText,
  ContentItemBack,
  ContentItemTitle,
  MobilePanel,
  MobilePanelHead,
  MobilePanelBody,
  MobilePanelFoot,
} from './index.styled';
import BlockNavigation, {
  BlockNavigationItem,
} from '../../l2_molecules/BlockNavigation';
import useResponsiveUtils from '../../utils/useResponsiveUtils';
import Chevron from './assets/chevron';
import { moveCarousel } from '../../utils/helpers';
import useKeyPressUtils from '../../utils/useKeyPressUtils';

type Props = {
  children: React.ReactNode;
};

type ItemData = {
  title: string;
  children: React.ReactNode;
  logos?: Array<string>;
};

const CarouselWithNavigation: React.FC<Props> = ({ children }: Props) => {
  const data = useItemsData<ItemData>(children);
  const slickRef = useRef<Carousel>(null);
  const [activeItemIndex, setActiveIndex] = useState(0);

  const { isMobile } = useResponsiveUtils();

  const onSlideChange = (_from: number, to: number) => {
    setActiveIndex(to);
  };

  const onItemClick = (idx: number) => {
    if (slickRef.current) {
      slickRef.current.goTo(idx);
    }
  };

  const [expanded, setExpanded] = React.useState<number | null>(null);
  const handleExpandClick = (index: number) => {
    setExpanded(index !== expanded ? index : null);
  };

  const sliderSpeed = isMobile ? 300 : 700;
  const cssEase = isMobile
    ? 'ease'
    : 'cubic-bezier(0.420, 0.000, 0.580, 1.000)';

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    setIsInitialized(true);
  }, []);

  const slickWrapperRef = useRef<HTMLDivElement>(null);

  const onKeyPress = useCallback((keyCode: number): void => {
    moveCarousel(slickRef.current, slickWrapperRef.current, keyCode);
  }, []);

  useKeyPressUtils({ onKeyPress });

  if (!isInitialized) {
    return <></>;
  }

  return (
    <>
      {isMobile ? (
        data.map((item, idx) => {
          const isActive = expanded === idx;
          const index = idx < 9 ? `0${idx + 1}` : idx + 1;

          return (
            <MobilePanel
              role="button"
              tabIndex={0}
              key={String(idx)}
              active={isActive}
              onClick={() => handleExpandClick(idx)}
              onKeyPress={() => handleExpandClick(idx)}
            >
              <MobilePanelHead index={index}>{item.title}</MobilePanelHead>
              <Collapse isOpened={isActive}>
                <MobilePanelBody>{item.children}</MobilePanelBody>
              </Collapse>
              <MobilePanelFoot active={isActive}>
                <Chevron />
              </MobilePanelFoot>
            </MobilePanel>
          );
        })
      ) : (
        <BlockWrapper>
          <CarouselNav>
            <BlockNavigation
              items={data.map(
                (item, idx): BlockNavigationItem => ({
                  id: idx,
                  title: item.title,
                }),
              )}
              onClick={(clickedItem): void =>
                onItemClick(parseInt(String(clickedItem.id), 10))
              }
              activeItemId={activeItemIndex}
            />
          </CarouselNav>
          <ContentWrapper>
            <CarouselWrapper ref={slickWrapperRef}>
              <Carousel
                ref={slickRef}
                slidesToShow={3}
                variableWidth
                speed={sliderSpeed}
                cssEase={cssEase}
                beforeChange={onSlideChange}
                infinite={false}
                responsive={[
                  {
                    breakpoint: 1920,
                    settings: { slidesToShow: 2 },
                  },
                ]}
              >
                {data.map((item, idx) => (
                  <ContentItem
                    key={item.title}
                    active={idx === activeItemIndex}
                    leftSide={idx < activeItemIndex}
                    sliderSpeed={sliderSpeed / 1000}
                    onClick={() => onItemClick(idx)}
                  >
                    <ContentItemNumber>{`0${idx + 1}`}</ContentItemNumber>
                    <ContentItemTitle>{item.title}</ContentItemTitle>
                    <ContentItemText>{item.children}</ContentItemText>
                    {idx + 1 === data.length && (
                      <ContentItemBack
                        type="button"
                        onClick={() => {
                          onItemClick(data.length - 2);
                        }}
                      >
                        <svg
                          width="28"
                          height="16"
                          viewBox="0 0 42 25"
                          fill="none"
                        >
                          <path
                            d="M2 12.3861L1.33951 11.6353L0.494397 12.3787L1.33215 13.1304L2 12.3861ZM42 11.3861L2 11.3861L2 13.3861L42 13.3861L42 11.3861ZM1.33215 13.1304L14.2756 24.7443L15.6113 23.2557L2.66785 11.6418L1.33215 13.1304ZM2.6605 13.137L15.6039 1.75083L14.2829 0.249166L1.33951 11.6353L2.6605 13.137Z"
                            fill="#060606"
                          />
                        </svg>
                        Назад
                      </ContentItemBack>
                    )}
                  </ContentItem>
                ))}
                {/* 1 empty block to allow carousel move to last position */}
                <div />
              </Carousel>
            </CarouselWrapper>
          </ContentWrapper>
        </BlockWrapper>
      )}
    </>
  );
};

export default CarouselWithNavigation;
