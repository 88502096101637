import React from 'react';

import {
  ContactsWrapper,
  ContactsImgWrap,
  ContactsImg,
  ContactsSystem,
  ContactsSun,
  ContactsOrbit,
  ContactsInfo,
  ContactsTitle,
  ContactsDesc,
} from './index.styled';
import ContactSvg from './contactSvg';

type Props = {
  title: React.ReactNode;
  email?: string;
  desc: React.ReactNode;
};

const Contacts: React.FC<Props> = (props: Props) => {
  const { title, email, desc } = props;

  return (
    <ContactsWrapper>
      <ContactsImgWrap>
        <ContactsImg>
          <ContactsSystem>
            <ContactsSun>
              <ContactSvg />
            </ContactsSun>
            <ContactsOrbit index={1} />
            <ContactsOrbit index={2} />
            <ContactsOrbit index={3} />
          </ContactsSystem>
        </ContactsImg>
      </ContactsImgWrap>

      <ContactsInfo>
        <ContactsTitle>
          {title}
          {email && (
            <>
              {' '}
              <a href={`mailto:${email}`}>{email}</a>
            </>
          )}
        </ContactsTitle>
        <ContactsDesc>{desc}</ContactsDesc>
      </ContactsInfo>
    </ContactsWrapper>
  );
};

export default Contacts;
