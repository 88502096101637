import styled from 'styled-components';
import size from 'polished/lib/shorthands/size';
import position from 'polished/lib/shorthands/position';
import theme from '../../styles/theme';

type FooterMenuListItemProps = {
  menuNumber: number;
  className: string;
};

type FooterMenuListProps = {
  menuNumber: number;
};

export const FooterMenuWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 2;

  @media (min-width: ${theme['screen-sm']}) {
    display: block;
    width: 100%;
  }
`;

export const FooterMenuHeader = styled.div`
  width: 138px;
  position: relative;
  color: #fdfdfd;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-transform: lowercase;
  margin-top: 45px;

  @media (min-width: ${theme['screen-sm']}) {
    width: auto;
    padding-left: 50px;
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 23px;
    margin-top: 0;
  }

  &:after {
    content: ' ';
    display: block;
    ${size('9px', '9px')};
    border-radius: 50%;
    ${position('absolute', '10px', '20px', 'auto', 'auto')};
    background: #fff;

    @media (min-width: ${theme['screen-sm']}) {
      display: none;
    }
  }

  &:before {
    @media (min-width: ${theme['screen-sm']}) {
      content: ' ';
      display: block;
      ${size('9px', '9px')};
      border-radius: 50%;
      ${position('absolute', '10px', 'auto', 'auto', '0px')};
      background: #fff;
    }
  }

  &.hidden {
    visibility: hidden;
    margin-top: 0;
  }
`;

export const FooterMenuList = styled.ul<FooterMenuListProps>`
  margin-top: 45px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 0;
  width: calc(100% - 138px);

  &.without-header {
    margin-top: 0;
  }

  @media (min-width: ${theme['screen-sm']}) {
    margin-top: 0;
    counter-reset: my-awesome-counter;
    width: auto;
    column-count: ${(props): string => (props.menuNumber === 0 ? '2' : '1')};
  }
`;

export const FooterMenuListItem = styled.li<FooterMenuListItemProps>`
  padding: 0;
  list-style: none;
  color: #fdfdfd;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 10px;
  counter-increment: my-awesome-counter;

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    margin-left: 0;
  }

  @media (min-width: ${theme['screen-sm']}) {
    padding-left: 50px;
    position: relative;
    margin-bottom: 3px;
  }

  &:before {
    @media (min-width: ${theme['screen-sm']}) {
      font-size: 12px;
      font-weight: 700;
      position: absolute;
      left: -5px;
      top: 0;
      content: ${(props): string => {
        let prefix = '';
        if (props.menuNumber === 0) {
          prefix = 'A';
        } else if (props.menuNumber === 1) {
          prefix = 'B';
        }
        return prefix !== '' ? `"${prefix}"counter(my-awesome-counter)` : '';
      }};
    }
  }

  &.address {
    @media (min-width: ${theme['screen-sm']}) {
      margin-top: 35px;
    }
  }

  a {
    &:hover {
      color: ${theme.blue};
    }
  }
`;
