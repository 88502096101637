import React, { SyntheticEvent, useRef, useCallback, useState } from 'react';
import { Carousel } from 'antd';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AdaptiveText from 'react-adaptive-text';
import HowWeWorkCarouselItem from '../../l1_atoms/HowWeWorkCarouselItem';
import { ContentWrapper, CarouselWrapper, NextButton } from './index.styled';
import useItemsData from '../../components/Item/useItemsData';
import useResponsiveUtils from '../../utils/useResponsiveUtils';
import IconArrowRight from '../../l1_atoms/IconArrowRight';
import { truncate, moveCarousel } from '../../utils/helpers';
import useKeyPressUtils from '../../utils/useKeyPressUtils';

type Props = {
  children: React.ReactNode;
};

type ItemData = {
  title: string;
  children: React.ReactNode;
};

enum Directions {
  Left = 'left',
  Right = 'right',
}

const HowWeWorkCarousel: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const data = useItemsData<ItemData>(children);
  const { isMobile, isDesktop } = useResponsiveUtils();

  const [paused, setPaused] = useState(true);
  const [reverse, setReverse] = useState(true);
  const [direction, setDirection] = useState<
    Directions.Left | Directions.Right
  >(Directions.Left);

  const [isPlaying, setIsPlaying] = useState(false);
  const slickRef = useRef<Carousel>(null);
  const slickWrapperRef = useRef<HTMLDivElement>(null);

  const [currentItem, setCurrentItem] = React.useState(0);

  const goToSlide = (index: number): void => {
    if (isPlaying) return;

    if (!slickRef) return;

    setCurrentItem(index);

    if (slickRef.current) {
      slickRef.current.innerSlider.slickGoTo(index);
    }
  };

  const handleMoveClick: React.EventHandler<SyntheticEvent<
    HTMLButtonElement,
    MouseEvent
  >> = () => {
    const nextIndex = currentItem < data.length - 1 ? currentItem + 1 : 0;
    goToSlide(nextIndex);
  };

  const handleItemClick = (index: number) => {
    goToSlide(index);
  };

  let itemIndex = 0;
  const titles = (
    <QueueAnim type={['left', 'alpha']}>
      {data.map((item, idx) => {
        itemIndex += 1;
        const isVisible = idx === currentItem;
        return isVisible ? (
          <AdaptiveText
            fontSizeMax={22}
            fontSizeMin={16}
            key={itemIndex}
            text={truncate(item.title, 50)}
          />
        ) : null;
      })}
    </QueueAnim>
  );

  const beforeChange = (oldIndex: number, newIndex: number) => {
    setIsPlaying(true);
    setDirection(newIndex > oldIndex ? Directions.Right : Directions.Left);
  };

  const afterChange = (index: number) => {
    setCurrentItem(index);
    setIsPlaying(false);

    if (!isDesktop) {
      setReverse(true);
    }
  };

  const onKeyPress = useCallback((keyCode: number): void => {
    moveCarousel(slickRef.current, slickWrapperRef.current, keyCode);
  }, []);

  useKeyPressUtils({ onKeyPress });

  return (
    <CarouselWrapper ref={slickWrapperRef}>
      <NextButton
        onClick={handleMoveClick}
        onTouchStart={() => {
          setPaused(false);
          setReverse(false);
        }}
        onMouseEnter={() => {
          setPaused(false);
          setReverse(false);
        }}
        onMouseLeave={() => {
          setReverse(true);
        }}
        isClicked={!reverse}
        after={
          <TweenOne
            paused={paused}
            animation={[
              {
                left: !reverse ? '0' : '-5px',
                duration: 200,
                ease: 'linear',
              },
            ]}
            style={{
              left: '-5px',
              position: 'relative',
            }}
          >
            <IconArrowRight
              width={32}
              height={17}
              fill={reverse ? '#ffffff' : '#1b61da'}
            />
          </TweenOne>
        }
      >
        {titles}
      </NextButton>
      <ContentWrapper>
        <Carousel
          afterChange={afterChange}
          beforeChange={beforeChange}
          dots={!isDesktop}
          dotPosition="bottom"
          variableWidth
          infinite={false}
          ref={slickRef}
          slidesToShow={1}
          cssEase={
            isMobile ? 'ease' : 'cubic-bezier(0.210, 0.000, 0.290, 1.000)'
          }
          speed={isMobile ? 300 : 900}
        >
          {data.map((item, key) => (
            <HowWeWorkCarouselItem
              key={item.title}
              paused={!isPlaying}
              direction={direction}
              onClick={() => handleItemClick(key)}
              onKeyDown={() => handleItemClick(key)}
              data-index={key}
            >
              {item.children}
            </HowWeWorkCarouselItem>
          ))}
        </Carousel>
      </ContentWrapper>
    </CarouselWrapper>
  );
};

export default HowWeWorkCarousel;
