import React from 'react';

const Logo: React.FC = () => {
  return (
    <svg width="89" height="20" viewBox="0 0 89 20" fill="none">
      <path
        d="M15.6291 2.25787V3.45131L16.4797 2.61427C16.8794 2.22097 17.3589 1.86744 17.907 1.55619C18.372 1.29572 18.9222 1.06008 19.5983 0.818884C20.2303 0.609332 20.9641 0.5 21.8065 0.5C22.8583 0.5 23.827 0.686547 24.7168 1.04078C25.5886 1.39425 26.3327 1.89778 26.9409 2.54955C27.555 3.20765 28.0524 4.00626 28.401 4.96654C28.7497 5.92703 28.9355 7.02208 28.9355 8.2404V18.7166H26.0323V8.28984C26.0323 6.79025 25.5559 5.53903 24.5993 4.56156C23.6431 3.5845 22.4301 3.10929 21.0323 3.10929C19.4372 3.10929 18.1034 3.60974 17.1057 4.66528C16.1013 5.71133 15.6291 7.20506 15.6291 9.06444V18.7166H12.7258V0.532962H15.6291V2.25787Z"
        fill="none"
        stroke="white"
      />
      <path
        d="M45.0766 3.59857L45.0766 3.59855L45.0727 3.59682C44.3029 3.25717 43.4925 3.07633 42.6451 3.07633C41.8006 3.07633 40.9731 3.25596 40.2155 3.59769C39.4473 3.93693 38.7847 4.40107 38.1962 4.98476C37.6053 5.57081 37.1318 6.25004 36.7984 7.05444C36.447 7.84685 36.2741 8.70579 36.2741 9.62478C36.2741 10.5461 36.4478 11.4036 36.7813 12.1926C37.1129 12.9775 37.5852 13.6748 38.1801 14.2648C38.7716 14.8515 39.4505 15.3138 40.1974 15.651L40.1974 15.651L40.2013 15.6527C40.9711 15.9924 41.7815 16.1732 42.6289 16.1732C43.4734 16.1732 44.3009 15.9936 45.0586 15.6519C45.8268 15.3126 46.4893 14.8485 47.0778 14.2648C47.6687 13.6788 48.1422 12.9995 48.4756 12.1951C48.827 11.4027 48.9999 10.5438 48.9999 9.62478C48.9999 8.70345 48.8262 7.84595 48.4927 7.05691C48.161 6.27207 47.6888 5.57476 47.0939 4.98476C46.5024 4.39807 45.8235 3.93579 45.0766 3.59857ZM33.3547 9.62478C33.3547 8.39052 33.599 7.22432 34.0853 6.13727C34.576 5.04059 35.2498 4.07027 36.1089 3.22382C36.967 2.37835 37.947 1.72066 39.0688 1.23377C40.201 0.749295 41.3757 0.5 42.6451 0.5C43.8996 0.5 45.0899 0.749789 46.2041 1.23328C47.3224 1.71854 48.3044 2.39189 49.1667 3.22542C50.025 4.0715 50.6983 5.04128 51.1887 6.13727C51.675 7.22432 51.9193 8.39052 51.9193 9.62478C51.9193 10.859 51.675 12.0252 51.1887 13.1123C50.698 14.209 50.0242 15.1793 49.1651 16.0257C48.3068 16.8715 47.3263 17.5293 46.2041 18.0163C45.0895 18.4999 43.9153 18.7496 42.6451 18.7496C41.3905 18.7496 40.2003 18.4998 39.086 18.0163C37.9677 17.531 36.9856 16.8576 36.1233 16.0241C35.2651 15.178 34.5918 14.2083 34.1015 13.1123C33.6132 12.0209 33.3547 10.8708 33.3547 9.62478Z"
        fill="none"
        stroke="white"
      />
      <path
        d="M62.901 14.194L68.2444 0.536133H71.2791L64.0477 18.7198H60.823L53.5917 0.536133H56.6263L61.9697 14.194L62.4354 15.3842L62.901 14.194Z"
        fill="none"
        stroke="white"
      />
      <path
        d="M85.5967 17.0082V15.8147L84.7461 16.6518C84.3461 17.0453 83.8686 17.3968 83.3272 17.6888L83.3271 17.6887L83.3201 17.6927C82.8547 17.9534 82.3042 18.1893 81.6274 18.4307C80.9894 18.6423 80.2543 18.766 79.4193 18.766C78.4851 18.766 77.6451 18.6252 76.9068 18.3385C76.1445 18.0425 75.5 17.6733 74.9506 17.2186C74.4162 16.7631 73.9937 16.2387 73.7049 15.6632C73.4145 15.0845 73.2742 14.5017 73.2742 13.9263C73.2742 12.5151 73.7292 11.3909 74.6154 10.4854C75.5104 9.57082 76.7448 8.96707 78.3245 8.70056C78.3247 8.70053 78.3249 8.7005 78.3251 8.70047L85.1626 7.56348L85.5806 7.49398V7.07026C85.5806 5.9609 85.1692 5.00212 84.3382 4.2464C83.5203 3.48764 82.4402 3.10929 81.1613 3.10929C80.2742 3.10929 79.4889 3.24817 78.8089 3.56623C78.1861 3.84183 77.6379 4.15573 77.1847 4.49416L77.1846 4.49409L77.1775 4.49962C76.8302 4.76881 76.5149 5.06425 76.2322 5.3859L74.4023 3.57531C74.8305 3.08408 75.3284 2.62625 75.8881 2.21924C76.485 1.79729 77.207 1.38887 78.0866 1.04196L78.0867 1.04204L78.0951 1.03853C78.9714 0.674298 79.9836 0.5 81.1774 0.5C82.3027 0.5 83.3069 0.673098 84.2116 1.01208C85.1209 1.35276 85.8851 1.82924 86.5107 2.42281C87.1397 3.01964 87.6327 3.7221 87.9766 4.51659C88.3202 5.31036 88.5 6.16563 88.5 7.07026V18.7331H85.5967V17.0082ZM85.5967 10.0368V9.45338L85.0202 9.54269L78.9557 10.4821L78.9462 10.4836L78.9367 10.4854C78.1867 10.6314 77.5251 10.9312 77.0041 11.4438C76.471 11.9684 76.1935 12.6252 76.1935 13.4154C76.1935 14.1788 76.5668 14.8211 77.2158 15.3517C77.9044 15.9145 78.8587 16.1567 80.0161 16.1567C81.678 16.1567 83.0596 15.6813 84.0784 14.6598C85.1195 13.6323 85.5967 12.2018 85.5967 10.4159V10.0368Z"
        fill="none"
        stroke="white"
      />
      <path
        d="M1.54279 6.82144L1.5429 6.82154L1.55098 6.81247C1.84695 6.47979 2.16876 6.19654 2.53172 5.95918L2.53177 5.95925L2.53956 5.95394C2.73432 5.82127 2.92081 5.69285 3.10018 5.56929L3.11759 5.5573C3.29103 5.43782 3.45757 5.32309 3.6202 5.21231C3.99306 4.95832 4.29636 4.68509 4.54659 4.41113C4.85963 4.06842 5.01613 3.65021 5.01613 3.18396C5.01613 2.69983 4.86797 2.25997 4.52157 1.93129C4.17458 1.60205 3.69579 1.46438 3.17742 1.46438C2.82885 1.46438 2.48277 1.54047 2.19876 1.73971C1.93972 1.88687 1.75158 2.05978 1.59018 2.26594C1.53436 2.33723 1.48015 2.41451 1.42956 2.49778L0.645983 2.12824C0.754172 1.91597 0.886947 1.71579 1.04142 1.53111C1.14448 1.41314 1.2619 1.30518 1.4204 1.15944C1.4253 1.15493 1.43024 1.15038 1.43523 1.1458C1.54877 1.04138 1.69614 0.950222 1.90232 0.83678C2.0591 0.75052 2.25446 0.681154 2.50398 0.621164C2.73487 0.565651 2.98546 0.536133 3.29032 0.536133C3.7174 0.536133 4.11041 0.608846 4.45641 0.736122C4.78939 0.858609 5.08788 1.0508 5.33561 1.27463C5.57241 1.5045 5.7451 1.76127 5.88187 2.06872C6.01249 2.36235 6.08064 2.68797 6.08064 3.05212C6.08064 3.41892 6.01153 3.72453 5.90121 3.96252L5.89666 3.97232L5.89255 3.98231C5.79237 4.22542 5.6228 4.46965 5.41684 4.68009C5.18856 4.91336 4.93996 5.13594 4.68624 5.30877L4.67844 5.31408L4.67085 5.31969C4.37852 5.53542 4.10065 5.74028 3.80952 5.92816L3.80174 5.93318L3.79415 5.93849C3.47679 6.16036 3.19381 6.38148 2.92205 6.59383L2.91795 6.59703L2.91081 6.60261L2.90388 6.60845C2.59808 6.86578 2.33563 7.15053 2.12596 7.48164L1.63994 8.24914H2.54839H6.08064V9.17739H0.519435C0.558051 8.716 0.653217 8.32384 0.796034 8.0007C1.00524 7.52737 1.25132 7.13345 1.54279 6.82144Z"
        fill="none"
        stroke="white"
      />
    </svg>
  );
};

export default Logo;
