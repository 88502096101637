import React, { useContext, useMemo, useRef } from 'react';
import { get } from 'lodash';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import {
  PageBlockWrapper,
  PageBlockHead,
  PageBlockNav,
  PageBlockNavLabel,
  PageBlockNavText,
  PageBlockNavPoint,
  PageBlockTitle,
  PageBlockTitleText,
  PageBlockTitleDescription,
  PageBlockBody,
} from './index.styled';
import PageBlockLink from './link';
import useResponsiveUtils from '../../utils/useResponsiveUtils';
import navMenu from '../NavigationMenu/assets/menu';
import { PageBlockProps } from './typings';
import usePageBlockScrollHandler from './context/scrollHandler';
import PageBlockContext from './context/context';

const PageBlock: React.FC<PageBlockProps> = (props: PageBlockProps) => {
  const {
    nav = 'a',
    navIndex = '1',
    title,
    titleSize = 'normal',
    description,
    children,
    link,
    fullHeight,
    linkTitle = '',
    theme = 'black',
    themeMobile = 'black',
    isEmpty = false,
    leftPadding = false,
  } = props;
  const { isDesktop } = useResponsiveUtils();
  const rootRef = useRef<HTMLDivElement>(null);
  const pageBlockContext = useContext(PageBlockContext);
  usePageBlockScrollHandler({
    ref: rootRef,
    onVisibleChange: visible => {
      if (!pageBlockContext || !visible) {
        return;
      }
      pageBlockContext.setCurrentBlock(props);
    },
  });

  const { navLabel, navText, blockTitle } = useMemo<{
    navLabel: string;
    navText: string | null;
    blockTitle: string | null;
  }>(() => {
    const navLetter = nav.toLocaleLowerCase();
    const pageMenu = get(navMenu, navLetter, null);
    const navIndexFirstSymbol = get(navIndex, 0);
    const navIndexSecondSymbol = get(navIndex, 1);
    const currentBlock = navIndexFirstSymbol
      ? get(pageMenu, navIndexFirstSymbol)
      : null;

    return {
      navLabel: `${navLetter}${navIndexFirstSymbol}`,
      navText: currentBlock ? currentBlock.name : null,
      blockTitle:
        navIndexSecondSymbol && currentBlock
          ? get(currentBlock, navIndexSecondSymbol, null)
          : null,
    };
  }, [nav, navIndex]);

  const handleCompleteAnimation = (e: {
    index: number;
    target: HTMLElement;
  }) => {
    const { target } = e;
    if (target.style.opacity === '1') {
      target.classList.add('visible');
    } else {
      target.classList.remove('visible');
    }
  };

  const startShowBlock = '30vh';

  return (
    <PageBlockWrapper
      ref={rootRef}
      theme={theme}
      themeMobile={themeMobile}
      fullHeight={fullHeight}
    >
      {isEmpty ? (
        <div />
      ) : (
        <>
          <PageBlockHead id={`anchor-${navIndex}`}>
            <OverPack
              id={`nav-${navIndex}`}
              playScale={startShowBlock}
              style={{ width: '100%' }}
            >
              <PageBlockNav key="0" type="left" interval={200} duration={500}>
                <PageBlockNavLabel key="0">{navLabel}</PageBlockNavLabel>
                <PageBlockNavText key="1">{navText}</PageBlockNavText>
                <PageBlockNavPoint
                  key="2"
                  theme={theme}
                  themeMobile={themeMobile}
                />
              </PageBlockNav>
            </OverPack>
            <PageBlockTitle>
              <OverPack
                id={`title-${navIndex}`}
                playScale={startShowBlock}
                style={{ width: '100%' }}
              >
                <TweenOne
                  key="0"
                  animation={{ opacity: 1, y: 0 }}
                  style={{ opacity: 0, transform: 'translateY(150px)' }}
                >
                  <PageBlockTitleText size={titleSize}>
                    {title || blockTitle}
                  </PageBlockTitleText>
                </TweenOne>
              </OverPack>

              <OverPack
                id={`desc-${navIndex}`}
                playScale={startShowBlock}
                style={{ width: '100%' }}
              >
                <TweenOne
                  key="0"
                  animation={{ opacity: 1, y: 0 }}
                  style={{ opacity: 0, transform: 'translateY(150px)' }}
                >
                  {description && (
                    <PageBlockTitleDescription>
                      {description}
                    </PageBlockTitleDescription>
                  )}
                </TweenOne>
              </OverPack>
            </PageBlockTitle>

            {link && (
              <OverPack
                id={`link-${navIndex}`}
                playScale={startShowBlock}
                style={{ width: '100%' }}
              >
                <TweenOne
                  key="0"
                  animation={{ opacity: 1, x: 0 }}
                  style={{ opacity: 0, transform: 'translate(100px)' }}
                >
                  {isDesktop ? (
                    <QueueAnim key="2">
                      <PageBlockLink link={link} linkTitle={linkTitle} />
                    </QueueAnim>
                  ) : null}
                </TweenOne>
              </OverPack>
            )}
          </PageBlockHead>

          <OverPack
            id={`body-${navIndex}`}
            playScale={startShowBlock}
            style={{ width: '100%' }}
          >
            <TweenOne
              key="0"
              animation={{
                opacity: 1,
                y: 0,
                onComplete: handleCompleteAnimation,
              }}
              style={{ opacity: 0, transform: 'translateY(150px)' }}
            >
              {children && (
                <PageBlockBody leftPadding={leftPadding}>
                  {children}
                </PageBlockBody>
              )}
            </TweenOne>
          </OverPack>
          {!isDesktop && link && (
            <PageBlockLink link={link} linkTitle={linkTitle} />
          )}
        </>
      )}
    </PageBlockWrapper>
  );
};

export default PageBlock;
