import styled from 'styled-components';
import Img from 'gatsby-image';
import theme from '../../styles/theme';

export const CarouselWrapper = styled.div`
  margin-bottom: 40px;
  width: 100vw;

  @media (min-width: ${theme['screen-md']}) {
    margin-left: calc(var(--page-block-nav-label) - 2px);
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    width: 120px;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
`;

type BlockImgNavItemProps = {
  active?: boolean;
};

export const BlockImgNavItem = styled.button<BlockImgNavItemProps>`
  background: none;
  box-shadow: none;
  border: none;
  outline: none;
  padding: 0;
  opacity: ${props => (!props.active ? 0.3 : 1)};
  transition: opacity ${theme.transitionSmall};
  cursor: pointer;
  animation: 0.6s cubic-bezier(0.42, 0, 0.58, 1) both;
  /* animation-name: ${props => props.active && 'scale'}; */
  padding: 0 15px;

  @media (min-width: ${theme['screen-md']}) {
    padding: 0;
    display: flex;
  }

  &:not(:last-child) {
    margin-bottom: 60px;
  }

  &:hover {
    opacity: 1;
  }

  @keyframes scale {
    0 {
      transform: scale(1);
    }

    25% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes BlockImgNavigation-active {
    0% {
      transform: rotate(15deg);
    }

    5% {
      transform: rotate(-15deg);
    }

    10% {
      transform: rotate(15deg);
    }

    15% {
      transform: rotate(-15deg);
    }
    20% {
      transform: rotate(15deg);
    }

    25% {
      transform: rotate(-15deg);
    }

    50% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }
`;

export const BlockImgNavImage = styled(Img)``;

export const BlockImgNavText = styled.p`
  font-size: 16px;
  line-height: 1.2em;
  text-align: left;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;

  @media (min-width: ${theme['screen-sm']}) {
    font-size: 18px;
    white-space: normal;
  }
`;
