import React from 'react';
import { PageBlockProps } from '../typings';

export interface PageBlockContextData {
  currentBlock: PageBlockProps | null;
  setCurrentBlock: (props: PageBlockProps) => void;
}

const PageBlockContext = React.createContext<PageBlockContextData>({
  currentBlock: null,
  setCurrentBlock: () => {
    // eslint-disable-next-line no-console
    console.warn('setCurrentBlock is not implemented!');
  },
});
export default PageBlockContext;
