import styled from 'styled-components';
import theme from '../../styles/theme';

type ContentItemWrapper = {
  id: string;
};

export const ContentItemWrapper = styled.div<ContentItemWrapper>`
  position: relative;
  opacity: 0;
  transform: translateY(100px);
  transition: all 500ms ease-in
    ${props => (props.id ? (parseInt(props.id, 10) - 1) * 100 : 100)}ms;
  @media (max-width: ${theme['screen-md']}) {
    opacity: 1;
    transform: translateY(0px);
    padding-bottom: 70px;
  }
`;

type ContentItemImageType = {
  bg?: string;
};

export const ContentItemImage = styled.div<ContentItemImageType>`
  display: block;
  width: 100%;
  background-size: contain !important;
  height: 150px;
  @media (min-width: ${theme['screen-md']}) {
    height: 170px;
  }
  ${props =>
    props.bg
      ? `background: url(${props.bg}) center center no-repeat;`
      : 'background: #ccc;'}
`;

export const ContentItemTitle = styled.div`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 26px;
  line-height: 1.1em;
  letter-spacing: -0.015em;
  text-transform: lowercase;
  padding-top: 10px;

  @media (min-width: ${theme['screen-md']}) {
    font-size: 40px;
    padding-top: 15px;
    margin-bottom: 20px;
  }
`;

export const ContentItemText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
`;
