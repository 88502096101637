import styled from 'styled-components';
import { Row } from 'antd';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import theme from '../../styles/theme';

export const FooterWrapper = styled('footer')`
  position: relative;
  z-index: 2;
  color: ${theme.white};
  overflow: hidden;
  padding: 0 16px;
  padding-bottom: 55px;
  background-image: linear-gradient(180deg, transparent, rgba(14, 14, 14, 0.4));

  @media (min-width: ${theme['screen-md']}) {
    padding-left: 20px;
    padding-right: 20px;
    background: none;
  }

  @media (min-width: ${theme['screen-lg']}) {
    padding: 0 80px 160px;
  }

  a {
    color: #fff;
  }
`;

export const FooterRow = styled(Row)`
  display: block;
  max-width: var(--contect-width);

  @media (min-width: ${theme['screen-sm']}) {
    display: flex;
  }
`;

export const FooterCol = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  &:first-child {
    display: none;
    @media (min-width: 1250px) {
      display: flex;
      flex: 1 1 auto;
    }
  }
  &:nth-child(2) {
    margin-top: 0;
  }
  @media (min-width: ${theme['screen-sm']}) {
    margin-top: 45px;
  }
  @media (min-width: ${theme['screen-sm']}) {
    display: flex;
    width: auto;
    flex: 1 1 auto;
  }
  @media (min-width: ${theme['screen-lg']}) {
    margin-top: 0;
  }
  &.col-0 {
    display: block;
    width: 100%;
    @media (min-width: ${theme['screen-lg']}) {
      display: flex;
      flex: 2 1 auto;
      width: auto;
    }
  }
`;

export const FooterLogo = styled(AnchorLink)`
  display: none;

  @media (min-width: ${theme['screen-sm']}) {
    display: block;
    align-self: flex-start;
  }
`;

export const FooterBg = styled.div``;
