import styled from 'styled-components';
import Img from 'gatsby-image';
import theme from '../../styles/theme';

export const START_SCREEN_IMAGE_WIDTH = 880;
export const START_SCREEN_IMAGE_HEIGHT = 825;

export const StartScreenWrapper = styled.section`
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 84vh;
  display: flex;
  padding-top: var(--header-height);

  @media (min-width: ${theme['screen-md']}) {
    min-height: 100vh;
  }
`;

export const StartScreenContent = styled.div`
  width: 100%;
  flex-direction: column;
`;

export const StartScreenBody = styled('div')`
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 0 16px 32px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme['screen-md']}) {
    padding: 0 40px 80px;
  }

  @media (min-width: ${theme['screen-lg']}) {
    padding: 0 80px 120px;
  }
`;

export const StartScreenImage = styled(Img)`
  clip-path: url(#header-clip-path);
  position: absolute !important;
  right: 0;

  @media (max-width: ${theme['screen-md']}) {
    left: 50%;
    right: auto;
    margin-left: -550px;
    zoom: 0.75;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 50%;
    background-image: linear-gradient(
      90deg,
      transparent,
      rgba(14, 14, 14, 0.5)
    );

    @media (max-width: ${theme['screen-md']}) {
      background-image: linear-gradient(
        243.04deg,
        rgba(14, 14, 14, 0.5) 26.35%,
        rgba(14, 14, 14, 0) 55.49%,
        rgba(14, 14, 14, 0.319924) 55.49%
      );
    }
  }
`;
