import React from 'react';
import {
  FooterWrapper,
  FooterLogo,
  FooterRow,
  FooterBg,
  FooterCol,
} from './index.styled';
import Logo from '../Logo';
import FooterMenu from '../FooterMenu';
import useItemsData from '../Item/useItemsData';

type Props = {
  children: React.ReactNode;
};

type Menu = {
  header: string;
  children: React.ReactNode;
  menuNumber: number;
};

const Footer: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const menus = useItemsData<Menu>(children);
  return (
    <FooterWrapper>
      <FooterRow>
        <FooterCol>
          <FooterLogo to="/#start-page" title="2nova">
            <Logo />
          </FooterLogo>
        </FooterCol>
        {menus.map((menu, i) => (
          <FooterCol className={`col-${i}`} key={menu.header}>
            <FooterMenu header={menu.header} menuNumber={i}>
              {menu.children}
            </FooterMenu>
          </FooterCol>
        ))}
      </FooterRow>
      <FooterBg />
    </FooterWrapper>
  );
};

export default Footer;
