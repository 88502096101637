import React from 'react';

const StartScreenImageClipPath1: React.FC = () => (
  <svg
    width="0"
    height="0"
    viewBox="0 0 820 769"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <clipPath id="header-clip-path" clipPathUnits="objectBoundingBox">
      <path d="M0.426188 0.204815C0.428776 0.151895 0.442081 0.100738 0.464751 0.0588944L0.464887 0.0585424C0.475254 0.0317005 0.488322 0.00864318 0.503282 -0.0103054C0.527876 -0.0414559 0.561805 -0.0707308 0.560285 -0.11039V-0.11039C0.556622 -0.205961 0.588307 -0.30104 0.64755 -0.352169C0.6813 -0.381296 0.718841 -0.391714 0.754799 -0.385574C0.782107 -0.383867 0.80955 -0.372546 0.83486 -0.350703C0.861872 -0.32739 0.898454 -0.321748 0.920681 -0.343557C0.974218 -0.396087 1.04515 -0.40261 1.10439 -0.35148C1.14155 -0.319411 1.16787 -0.270052 1.18142 -0.214126C1.19022 -0.177796 1.20944 -0.13337 1.24644 -0.138727V-0.138727C1.26018 -0.140717 1.26905 -0.15081 1.27924 -0.160242C1.30598 -0.185018 1.33753 -0.199322 1.37131 -0.199322C1.46798 -0.199322 1.54635 -0.0821735 1.54635 0.0623362C1.54635 0.115165 1.53588 0.164338 1.51787 0.205467C1.49409 0.262429 1.45783 0.301206 1.41749 0.318425C1.37532 0.336427 1.31154 0.325867 1.29857 0.369846V0.369846C1.29192 0.392377 1.28321 0.413242 1.2728 0.431905C1.22019 0.534863 1.12335 0.565903 1.04629 0.499395C1.01928 0.476082 0.982696 0.47044 0.96047 0.492249C0.957589 0.495075 0.954658 0.497768 0.951681 0.500327L0.944908 0.506476C0.940058 0.510662 0.935385 0.515531 0.93099 0.520934C0.894438 0.565864 0.903339 0.62981 0.893822 0.686943V0.686943C0.883404 0.755563 0.854611 0.817742 0.81063 0.855699C0.767612 0.892825 0.718434 0.899554 0.674227 0.880345C0.630814 0.861482 0.584345 0.810133 0.550558 0.843284V0.843284C0.49702 0.895814 0.426092 0.902337 0.366848 0.851207C0.283126 0.778952 0.254441 0.618924 0.302777 0.493775C0.312787 0.46786 0.325314 0.445473 0.33963 0.426905C0.349961 0.411746 0.361728 0.398271 0.374845 0.38695C0.401839 0.363653 0.423377 0.319157 0.421857 0.279506C0.420891 0.254309 0.422383 0.229147 0.426188 0.204815Z" />
    </clipPath>
  </svg>
);

export default StartScreenImageClipPath1;
