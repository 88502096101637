import styled from 'styled-components';
import { HTMLAttributes } from 'react';
import QueueAnim from 'rc-queue-anim';
import theme from '../../styles/theme';

export const PageBlockHead = styled(QueueAnim)`
  width: 100%;
  display: block;

  @media (min-width: ${theme['screen-md']}) {
    display: grid;
    grid-template-columns: var(--page-block-left) 1fr min-content;
  }

  @media (min-width: ${theme['screen-xxl']}) {
    display: grid;
    grid-template-columns: var(--page-block-left) var(--page-block-right) min-content;
  }
`;

export const PageBlockNav = styled(QueueAnim)`
  display: flex;
  align-items: baseline;
  padding-right: 0;

  @media (min-width: ${theme['screen-md']}) {
    padding-right: 20%;
  }
`;

export const PageBlockNavLabel = styled('p')`
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: inherit;
  text-transform: uppercase;
  margin-right: 30px;
  width: 20px;

  @media (min-width: ${theme['screen-md']}) {
    margin-right: var(--page-block-nav-label);
  }
`;

export const PageBlockNavText = styled('p')`
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: inherit;
  text-transform: lowercase;
  flex-grow: 0;
  flex-direction: row-reverse;
  font-size: 18px;

  @media (min-width: ${theme['screen-md']}) {
    flex-grow: 1;
    flex-direction: row;
    font-size: 22px;
  }
`;

type PageBlockNavPointProps = {
  theme: 'black' | 'white';
  themeMobile: 'black' | 'white';
};

export const PageBlockNavPoint = styled.div<PageBlockNavPointProps>`
  display: block;
  flex: 0 0 10px;
  width: 10px;
  height: 10px;
  background-color: ${(props): string =>
    props.themeMobile === 'white' ? 'black' : 'white'};
  border-radius: 50%;
  margin-left: 30px;

  @media (min-width: ${theme['screen-md']}) {
    margin-left: 0;
    background-color: ${(props): string =>
      props.theme === 'white' ? 'black' : 'white'};
  }
`;

export const PageBlockTitle = styled('div')`
  margin-top: 25px;

  @media (min-width: ${theme['screen-md']}) {
    margin-top: 0;
    margin-right: 20px;
  }

  a {
    color: inherit;

    &:hover {
      color: ${theme.blue};
    }
  }
`;

type PageBlockTitleTextProps = {
  size: 'normal' | 'small';
};

export const PageBlockTitleText = styled.h3<PageBlockTitleTextProps>`
  font-style: normal;
  font-weight: 500;
  text-transform: ${(props): string =>
    props.size === 'small' ? 'none' : 'lowercase'};
  letter-spacing: ${(props): string =>
    props.size === 'small' ? '-0.015em' : '-0.02em'};
  color: inherit;
  max-width: none;
  font-size: ${(props): string => (props.size === 'small' ? '24px' : '36px')};
  line-height: ${(props): string => (props.size === 'small' ? '28px' : '42px')};
  margin: 0;

  @media (min-width: ${theme['screen-md']}) {
    font-size: ${(props): string => (props.size === 'small' ? '36px' : '52px')};
    line-height: ${(props): string =>
      props.size === 'small' ? '42px' : '64px'};
    margin-top: ${(props): string => (props.size === 'small' ? '0' : '-16px')};
  }

  @media (min-width: ${theme['screen-lg']}) {
    font-size: ${(props): string => (props.size === 'small' ? '42px' : '60px')};
    line-height: 1em;
  }

  @media (min-width: ${theme['screen-xl']}) {
    font-size: ${(props): string => (props.size === 'small' ? '42px' : '76px')};
    line-height: 1em;
    margin-top: ${(props): string => (props.size === 'small' ? '0' : '-28px')};
  }

  @media (min-width: 2000px) {
    font-size: ${(props): string =>
      props.size === 'small' ? '42px' : '100px'};
    line-height: ${(props): string =>
      props.size === 'small' ? '46px' : '100px'};
  }
`;

export const PageBlockTitleDescription = styled('p')`
  font-style: normal;
  font-weight: 500;
  color: inherit;
  font-size: 16px;
  line-height: 24px;
  margin: 25px 0 0;

  @media (min-width: ${theme['screen-xs']}) {
    max-width: 70%;
  }

  @media (min-width: ${theme['screen-md']}) {
    font-size: 18px;
    line-height: 26px;
    margin-top: 44px;
  }
`;

type PageBlockBodyProps = {
  leftPadding: boolean;
};

export const PageBlockBody = styled.div<PageBlockBodyProps>`
  width: 100%;
  margin-top: 50px;

  @media (min-width: ${theme['screen-md']}) {
    margin-top: 60px;
    padding-left: ${props =>
      props.leftPadding ? 'var(--page-block-left)' : '0'};
  }
`;

type ButtonProps = {
  reverse: boolean;
};

export const Button = styled.button<ButtonProps>`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: white;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: lowercase;
  border: none;
  outline: none;
  cursor: pointer;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
`;

export const PageLink = styled.div`
  display: block;
  text-align: center;
  width: 100%;

  @media (min-width: ${theme['screen-lg']}) {
    text-align: left;
    width: auto;
  }

  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: white;
  align-items: center;
  text-transform: lowercase;
`;

type LinkSuffixProps = {
  marginRight: boolean;
};

export const LinkSuffix = styled.div<LinkSuffixProps>`
  ${props => (props.marginRight ? 'margin-right' : 'margin-left')}: 14px;
  width: 66px;
  height: 66px;
  position: relative;

  span {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

interface PageBlockWrapperProps extends HTMLAttributes<HTMLDivElement> {
  theme: 'black' | 'white';
  themeMobile: 'black' | 'white';
  fullHeight?: boolean;
}

export const PageBlockWrapper = styled.section<PageBlockWrapperProps>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 16px 120px;
  z-index: 5;
  min-height: ${(props): string => (props.fullHeight ? '100vh' : '0')};
  color: ${(props): string =>
    props.themeMobile === 'white' ? 'black' : 'white'};
  background: ${(props): string =>
    props.themeMobile === 'white' ? theme.backgroundWhite : 'none'};

  @media (min-width: ${theme['screen-md']}) {
    justify-content: center;
    padding: 40px 20px;
    color: ${(props): string => (props.theme === 'white' ? 'black' : 'white')};
    background: ${(props): string =>
      props.theme === 'white' ? theme.backgroundWhite : 'none'};
  }

  @media (min-width: ${theme['screen-lg']}) {
    padding: 100px 80px;
  }

  ${PageBlockNavText}:after {
    background-color: ${(props): string =>
      props.themeMobile === 'white' ? 'black' : 'white'};

    @media (min-width: ${theme['screen-md']}) {
      background-color: ${(props): string =>
        props.theme === 'white' ? 'black' : 'white'};
    }
  }

  .visible {
    .culture-card {
      opacity: 1;
      top: 0;
      transform: translateY(0px);
    }
  }
`;
