import React from 'react';
import {
  ReviewContent,
  ReviewName,
  ReviewPosition,
  ReviewWrapper,
} from './index.styled';

type Props = {
  name?: string;
  position?: string;
  isQuote?: boolean;
  children?: React.ReactNode;
};

const Review: React.FC<Props> = ({
  name,
  position,
  isQuote = false,
  children,
}: Props) => {
  return (
    <ReviewWrapper>
      <ReviewContent isQuote={isQuote}>{children}</ReviewContent>
      {name && <ReviewName>{name}</ReviewName>}
      {position && <ReviewPosition>{position}</ReviewPosition>}
    </ReviewWrapper>
  );
};

export default Review;
