import React from 'react';
import {
  BlockNav,
  BlockNavImage,
  BlockNavItem,
  BlockNavItemArrow,
} from './index.styled';

export interface BlockNavigationItem {
  id: string | number;
  title: string;
  image?: string;
}
type Props = {
  stretch?: boolean;
  items: BlockNavigationItem[];
  activeItemId?: string | number | null;
  onClick: (item: BlockNavigationItem) => void;
};

const BlockNavigation: React.FC<Props> = ({
  stretch = false,
  items,
  activeItemId,
  onClick,
}: Props) => (
  <BlockNav stretch={stretch}>
    {items.map(item => (
      <BlockNavItem
        key={item.id}
        active={item.id === activeItemId}
        onClick={() => onClick(item)}
        contentType={item.image ? 'image' : 'text'}
      >
        <BlockNavItemArrow>
          <svg width="16" height="12" viewBox="0 0 16 12" fill="none">
            <path
              d="M9.83057 1.08472L14.5001 5.99997L9.83057 10.9152"
              stroke="#060606"
              strokeWidth="2"
            />
            <path d="M14.5 5.99997H0" stroke="#060606" strokeWidth="2" />
          </svg>
        </BlockNavItemArrow>
        {item.image && <BlockNavImage src={item.image} />}
        {!item.image && item.title}
      </BlockNavItem>
    ))}
  </BlockNav>
);

export default BlockNavigation;
