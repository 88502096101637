import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'antd';
import { FixedObject } from 'gatsby-image';
import {
  BlockImgNavText,
  BlockImgNavImage,
  BlockImgNavItem,
  CarouselWrapper,
} from './index.styled';
import useResponsiveUtils from '../../utils/useResponsiveUtils';

export interface BlockImgNavigationItem {
  id: number;
  title: string;
  image: FixedObject;
}

type Props = {
  items: BlockImgNavigationItem[];
  activeItemId: number;
  onClick: (clickedItemId: number) => void;
};

const BlockImgNavigation: React.FC<Props> = ({
  items,
  activeItemId,
  onClick,
}: Props) => {
  const { isMobile } = useResponsiveUtils();

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  const slickRef = useRef<Carousel>(null);

  const navItems = () =>
    items.map(item => (
      <BlockImgNavItem
        key={item.id}
        active={item.id === activeItemId}
        onClick={() => onClick(item.id)}
      >
        {item.image ? (
          <BlockImgNavImage fixed={item.image} />
        ) : (
          <BlockImgNavText>{item.title}</BlockImgNavText>
        )}
      </BlockImgNavItem>
    ));

  const carouselComponent = (
    <Carousel
      ref={slickRef}
      dots={false}
      slidesToShow={2}
      variableWidth
      centerMode
      speed={300}
      cssEase="ease"
    >
      {navItems()}
    </Carousel>
  );

  useEffect(() => {
    if (
      slickRef &&
      typeof slickRef.current !== 'undefined' &&
      slickRef.current
    ) {
      slickRef.current.innerSlider.slickGoTo(activeItemId);
    }
  }, [activeItemId]);

  if (!isInitialized) {
    return <></>;
  }

  return (
    <CarouselWrapper>
      {isMobile ? carouselComponent : navItems()}
    </CarouselWrapper>
  );
};

export default BlockImgNavigation;
