import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { YMInitializer } from 'react-yandex-metrika';

const Analytics: React.FC = () => {
  useEffect(() => {
    ReactGA.initialize('UA-29558093-1');
  });
  return (
    <>
      <YMInitializer accounts={[7253980]} options={{ enableAll: true }} />
    </>
  );
};

export default Analytics;
