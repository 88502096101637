import React from 'react';
import { get } from 'lodash';
import { graphql, PageProps } from 'gatsby';
import Layout from '../components/layout';
import StartScreen from '../components/StartScreen';
import Header from '../components/Header';
import PageBlock from '../components/PageBlock';
import FooterBlock from '../l3_organism/FooterBlock';
import Item from '../components/Item';
import SEO from '../components/seo';
import CultureCard from '../l2_molecules/CultureCard';
// import ValueCard from '../l2_molecules/ValueCards/Card';
import CarouselCards from '../l3_organism/CarouselCards';
// import AccordionCards from '../l3_organism/Accordion';
import StorySlide from '../l2_molecules/StorySlide';
import Stories from '../l3_organism/Stories';
// import CardsGrid from '../l3_organism/CardsGrid';
// import PersonCard from '../l2_molecules/PersonCard';
import PageBlockLink from '../components/PageBlock/link';
import NavigatedContent from '../l3_organism/NavigatedContent';
import Review from '../l2_molecules/Review';
// import PopularVacancies from '../l3_organism/Vacancies/Popular';
// import OpenVacancies from '../l3_organism/Vacancies/Open';
// import ValueCardModal from '../l2_molecules/ValueCards/CardModal';
// import VacancyCard from '../l2_molecules/Vacancy/Card';

const TeamPage: React.FC<PageProps> = ({ data }: PageProps) => {
  const mainImage = get(data, 'mainImage.childImageSharp.fixed');

  const navLabel = 'b';

  return (
    <Layout footerImage={mainImage}>
      <SEO title="Team" />
      <Header />

      <StartScreen
        title="dream"
        subtitle="team"
        text={`
Привет! Мы — команда 2nova.
Нас объединяет не только работа, но и общие ценности.        
            `}
        image={mainImage}
      />

      <PageBlock
        nav={navLabel}
        navIndex="1"
        title={<>У нас хорошо тем, кто стремится расти!</>}
        description={
          <>
            Мы собираем вместе сильных ребят и крутые проекты. Доверяем нашим
            командам в принятии важных решений и управление процессами. Мы
            любознательны, поэтому многому учимся во время работы и делимся
            новым.
            <br />
          </>
        }
        titleSize="small"
      />

      <PageBlock
        nav={navLabel}
        navIndex="21"
        title={
          <>
            Мы гордимся нашей
            <br />
            командой и культурой
          </>
        }
        titleSize="small"
      >
        <CarouselCards defaultActiveItem={2} transitionType="horizontal">
          <Item
            id="1"
            card={
              <CultureCard
                id="1"
                image="/images/culture/culture-3.svg"
                title="Горизонтальная структура"
              >
                Для нас это не модное решение, а осознанный выбор.
                <br />
                У нас нет линейных ролей начальников и подчиненных, мы за
                менторство в управлении и возможность честно говорить обо всем.
                <br />
                От менеджеров продуктов до DevOps инженеров, команды знают
                экономику и цели проектов, каждый понимает что происходит и как
                на это влиять.
              </CultureCard>
            }
          />
          <Item
            id="2"
            card={
              <CultureCard
                id="2"
                image="/images/culture/culture-2.svg"
                title="Децентрализованные решения"
              >
                Мы за то, чтобы каждая команда и ее участник умели принимать
                решения и обосновывать их, основываясь на здравом смысле, данных
                и заниях.
                <br />
                Ответственность, командная и личная, помогает минимизировать
                риски связанные со сроками и качеством работы.
                <br />
                Мы стараемся нанимать талантливых специалистов, с
                соответствующим опытом и жизненной позицией.
                <br />
                Готовы их поддержать, учить и дать возможность быть лидером.
              </CultureCard>
            }
          />
          <Item
            id="3"
            card={
              <CultureCard
                id="3"
                image="/images/culture/culture-1.svg"
                title="Распределенные команды"
              >
                Сейчас наша команда разнесена на
                <br />
                7 городов России и 2 за ее пределами.
                <br />
                Нам не важно, работает человек дома или в офисе, главное –
                качественное командное взаимодействие.
                <br />
                Эффективность работы и ее организованность- наш приоритет.
              </CultureCard>
            }
          />
        </CarouselCards>
      </PageBlock>

      {/*
      <PageBlock
        nav={navLabel}
        navIndex="22"
        title={<>Наши ценности</>}
        fullHeight
        theme="white"
      >
        <AccordionCards color="white">
          <Item
            id="1"
            card={
              <ValueCard
                idx={1}
                title="Непрерывное улучшение"
                nowrap
                text={
                  <>
                    То, что называется в agile – relentless improvement.
                    <br />
                    Чтобы развиваться каждый день, с каждой новой задачей, мы
                    создаем среду открытости и взаимопомощи.
                    <br /> Обмен знаниями и обратная связь – часть ежедневной
                    работы.
                    <br />
                    Смена роли, проекта и круга задач – нормальная часть
                    развития.
                    <br />
                    Проблема- это не что-то плохое, это просто задача, которую
                    нужно решить.
                  </>
                }
              />
            }
          />
          <Item
            id="2"
            card={
              <ValueCard
                idx={2}
                title="Коллаборация"
                nowrap
                text={
                  <>
                    Взаимодействие в командах, между командами и с клиентом —
                    то, на чем строятся все наши процессы. Командная работа
                    складывается из взаимного уважения, заботы, открытости и
                    понимания того, какую ценность каждая команда приносит
                    клиенту и конечному пользователю.
                  </>
                }
              />
            }
          />
          <Item
            id="3"
            card={
              <ValueCard
                idx={3}
                title="Честность и открытость"
                nowrap
                text={
                  <>
                    Мы делаем прозрачными все дела компании: от стратегических
                    планов до финансовых показателей, чтобы каждый понимал, что
                    происходит, как он может принести пользу и синхронизировать
                    свои персональные цели с целями компании. Можно поделиться
                    переживаниями, сомнениями и планами прямо с основателями
                    компании.
                  </>
                }
              />
            }
          />
          <Item
            id="4"
            card={
              <ValueCard
                idx={4}
                title="Свобода и самостоятельность"
                nowrap
                text={
                  <>
                    Мы собираем сильных ребят и даем им действовать
                    самостоятельно.
                    <br />У каждого человека и команды в 2nova есть свобода в
                    организации своего рабочего процесса и выбрать способ
                    достижения общий цели.
                  </>
                }
              />
            }
          />
        </AccordionCards>
      </PageBlock>
      */}

      <PageBlock
        nav={navLabel}
        navIndex="22"
        theme="white"
        themeMobile="white"
        title={<>Наши ценности</>}
        fullHeight
      >
        <NavigatedContent>
          <Item title="Непрерывное улучшение">
            <Review>
              То, что называется в agile – relentless improvement.
              <br />
              Чтобы развиваться каждый день, с каждой новой задачей, мы создаем
              среду открытости и взаимопомощи.
              <br /> Обмен знаниями и обратная связь – часть ежедневной работы.
              <br />
              Смена роли, проекта и круга задач – нормальная часть развития.
              <br />
              Проблема- это не что-то плохое, это просто задача, которую нужно
              решить.
            </Review>
          </Item>
          <Item title="Коллаборация">
            <Review>
              Взаимодействие в командах, между командами и с клиентом — то, на
              чем строятся все наши процессы. Командная работа складывается из
              взаимного уважения, заботы, открытости и понимания того, какую
              ценность каждая команда приносит клиенту и конечному пользователю.
            </Review>
          </Item>
          <Item title="Честность и открытость">
            <Review>
              Мы делаем прозрачными все дела компании: от стратегических планов
              до финансовых показателей, чтобы каждый понимал, что происходит,
              как он может принести пользу и синхронизировать свои персональные
              цели с целями компании. Можно поделиться переживаниями, сомнениями
              и планами прямо с основателями компании.
            </Review>
          </Item>
          <Item title="Свобода и самостоятельность">
            <Review>
              Мы собираем сильных ребят и даем им действовать самостоятельно.
              <br />У каждого человека и команды в 2nova есть свобода в
              организации своего рабочего процесса и выбрать способ достижения
              общий цели.
            </Review>
          </Item>
        </NavigatedContent>
      </PageBlock>

      {/* <PageBlock
        nav={navLabel}
        navIndex="31"
        title={<>Истории о нас</>}
        fullHeight
      >
        <CardsGrid>
          <PersonCard
            idx="1"
            name="Валерия Макарова"
            title="Бухгалтер-экономист"
            image="/images/people/lera-makarova.jpg"
          >
            <p>
              Когда попадаешь в такую компанию как 2Nova, то многое становится
              возможно. Но эти возможности нужно видеть и не бояться
              действовать.
              <br />В этом году я поняла, что если я не начну действовать, то
              так и останусь классическим бухгалтером, а мне хотелось большего.
              <br />
              Благодаря нашей внутренней культуре и коллегам мне удалось
              поучаствовать в исследовании по созданию новых цифровых продуктов.
              <br />
              Это был первый шаг, вторым стало поступление на программу MBA.
              <br />
              Компания помогла мне и в этом.
              <br /> Я понимаю, что мне еще многое предстоит узнать и освоить,
              но оглядываясь назад я задаюсь только одним вопросом: почему я не
              сделала этого всего раньше?
            </p>
          </PersonCard>
          <PersonCard
            idx="2"
            name="Дмитрий Огородников"
            title="Директор"
            image="/images/people/Dima-Ogorodnikov.jpg"
          >
            <p>
              Дима — человек, который помогает командам фокусироваться,
              понимать, что важно, и расти, осваивать новые подходы и фреймворки
              и применять их на практике. Дима умеет быстро уловить суть любого
              вопроса — будь то стратегические задачи, новые технологии или
              личное общение — и помочь найти оптимальное решение.
            </p>
          </PersonCard>
          <PersonCard
            idx="3"
            name="Афанасьева Лена"
            title="Менеджер проектов, сертифицированный Эриксоновский коуч"
            image="/images/people/lena-afanaseva.jpg"
          >
            <p>
              Я уже много лет работаю в iT сфере, но психология была интересна
              всегда. Так, в 2016 году я решила пойти на коучинг, программа
              Эриксоновского университета. Обучение помогло мне посмотреть иначе
              на мою работу и коммуникацию с людьми. Прорабатывая материалы ты
              понимаешь, что могие рабочие моменты можно выстроить иначе, с
              позиции помощи и поддержки. Если клиент не понимает чего-то в
              проекте или не готов к чему-то, то моя задача научить и
              попробовать объяснить.
            </p>
            <p>
              Изменилось отношение к качеству работы. Ты понимаешь, что многое в
              будущем проекта зависит от тебя, от вас как от команды, что это не
              клиент плохой/хороший, а то что вы работаете без багов, поднимаете
              качество, стараетесь что-то улучшить - дает качественный
              результат. Со временем ты понимаешь, что ничего не работает
              само-по-себе, процесс нужно строить и на это потребуется много сил
              и терпения. Но это того стоит, если ты собираешься строить
              долгосрочные отношения.
            </p>
            <p>
              Все хотят работать с классными людьми, так почему бы тебе самому
              не быть классным? Лично я согласна с этим и знаю к чему
              стремлюсь!)
            </p>
          </PersonCard>
          <PersonCard
            idx="4"
            name="Ксения Хоменко"
            title="HR"
            image="/images/people/ksusha-hr.jpg"
          >
            <p>Я пришла в 2Nova пару лет назад и просто влюбилась.</p>
          </PersonCard>
          <PersonCard
            idx="4"
            name="Герман Кухтенков"
            title="Директор по маркетингу"
            image="/images/people/german.jpg"
          >
            <p>
              Один из основателей 2Nova, Герман Кухтенков – креативный директор
              фестиваля OFFF в Москве.
            </p>
          </PersonCard>
          <PersonCard
            idx="5"
            name="Анастасия Сапина"
            title="Менеджер проектов"
            image="/images/people/"
          >
            <p>
              О том как выходить из зоны комфорта, менять виденье проекта и
              клиента
            </p>
          </PersonCard>
          <PersonCard
            idx="6"
            name="Аня Логвиненко"
            title="Менеджер проектов"
            image="/images/people/"
          >
            <p>О том как все успевать и не сойти с ума</p>
          </PersonCard>
        </CardsGrid>
      </PageBlock> */}

      <PageBlock nav={navLabel} navIndex="32" title={<>Истории</>} fullHeight>
        <Stories>
          <StorySlide
            name="Герман Кухтенков"
            number="01"
            images={[
              '/images/people/german.jpg',
              '/images/stories/offf1.jpg',
              '/images/stories/offf2.jpg',
            ]}
          >
            Один из основателей 2Nova, Герман Кухтенков – креативный директор
            фестиваля OFFF в Москве. <br /> OFFF — международный фестиваль
            дизайна и цифрового искусства. Каждый год OFFF проходит в Барселоне
            и путешествует по всему миру. Организаторы собирают команды
            дизайнеров, художников и креаторов из разных стран — они
            рассказывают о своём опыте и показывают, как может выглядеть
            будущее.
          </StorySlide>
          <StorySlide
            name="Little co-workers"
            number="02"
            images={[
              '/images/stories/children1.jpg',
              '/images/stories/children-day2.jpg',
              '/images/stories/children-day3.jpg',
            ]}
          >
            <p>Как объяснить ребенку что такое управление проектом?</p>
            <p>Кто такой менеджер?</p>
            <p>
              Мы решили организовать день, когда сотрудники могут привести на
              работу своих детей и показать им офис, познакомить с коллегами.
            </p>
            <p>
              Мы ходили с мамами на коллы, просматривали почту и отвечали
              клиентам с папами. Рисовали и пили какао с нашим HR (за одно
              обсудили кто кем хочет стать когда вырастет).
            </p>
          </StorySlide>
          <StorySlide
            name="Киноклуб 2Nova"
            number="03"
            images={[
              '/images/stories/kino1.jpg',
              '/images/stories/kino2.jpg',
              '/images/stories/kino3.jpg',
            ]}
          >
            Раз в две недели у нас в офисе проходит киноклуб. <br /> К каждому
            просмотру мы готовим материалы о фильме и режиссере, а после
            просмотра детально обсуждаем увиденное за пиццей и попкорном. <br />
            Это открытое мероприятие на которое можно прийти вместе с друзьями,
            вторыми половинками и даже родителями!)
          </StorySlide>
          <StorySlide
            name="Не просто коллеги"
            number="04"
            images={[
              '/images/stories/our-faces.jpg',
              '/images/stories/team3.jpg',
              '/images/stories/team5.jpg',
              '/images/stories/team6.jpg',
            ]}
          >
            Нам везет, нам не только удалось создать классную корпоративную
            культуру, но и найти внутреннюю поддержку среди коллег.
            <br />
            Мы организовываем праздники,тематические вечеринки и любим
            праздновать дни рождения вместе.
          </StorySlide>
        </Stories>
      </PageBlock>

      <PageBlock
        nav={navLabel}
        navIndex="4"
        title={
          <>
            Даже если сейчас у нас нет вакансий, мы открыты новым знакомствам.
            Напишите нам на <a href="mailto:hello@2nova.ru">hello@2nova.ru</a>.
            Мы будем вам рады!
          </>
        }
        titleSize="small"
        fullHeight
        leftPadding
      >
        {/*
        <OpenVacancies>
          <Item
            id="1"
            card={
              <VacancyCard
                title="аналитик:"
                text="1Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer odio facilisis Inter liquet massa lacinia in"
              />
            }
          />
          <Item
            id="2"
            card={
              <VacancyCard
                title="аналитик:"
                text="2Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer odio facilisis Inter liquet massa lacinia in"
              />
            }
          />
          <Item
            id="3"
            card={
              <VacancyCard
                title="аналитик:"
                text="3Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer odio facilisis Inter liquet massa lacinia in"
              />
            }
          />
        </OpenVacancies>
        */}
        {/*
        PopularVacancies>
          <Item id="1" title="разработчиков" />
          <Item id="2" title="дизайнеров" />
          <Item id="3" title="аналитиков" />
          <Item id="4" title="devops-менеджеров" />
        </PopularVacancies>
        */}

        <PageBlockLink
          link="/#anchor-22"
          linkTitle="Узнать больше о нашей инженерной культуре"
          leftIcon
        />
      </PageBlock>

      <FooterBlock />
    </Layout>
  );
};

export const query = graphql`
  query {
    mainImage: file(relativePath: { eq: "team-bg.jpg" }) {
      childImageSharp {
        fixed(height: 825, width: 1237) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default TeamPage;
