import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactHyphenated from 'react-hyphen';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ru from 'hyphenated-ru';

type Props = {
  children?: React.ReactNode;
};

const Hyphenated: React.FC<Props> = ({ children }: Props) => (
  <ReactHyphenated language={ru}>{children}</ReactHyphenated>
);

export default Hyphenated;
