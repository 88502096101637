import styled from 'styled-components';
import theme from '../../styles/theme';
import quoteImage from './assets/quote.svg';

export const ReviewWrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;

  @media (min-width: ${theme['screen-lg']}) {
    max-width: 550px;
  }
`;

type ReviewContentProps = {
  isQuote: boolean;
};

export const ReviewContent = styled.div<ReviewContentProps>`
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  padding-top: ${props => (props.isQuote ? '20px' : '0')};

  @media (min-width: ${theme['screen-lg']}) {
    padding-top: 0;
    padding-left: ${props => (props.isQuote ? '25px' : '0')};
  }

  &::before {
    content: '';
    display: ${props => (props.isQuote ? 'block' : 'none')};
    background: url(${quoteImage});
    width: 15px;
    height: 10px;
    position: absolute;
    top: 0px;

    @media (min-width: ${theme['screen-lg']}) {
      top: auto;
      margin-left: -25px;
      margin-top: 8px;
    }
  }

  ol,
  ul {
    margin: 0;
  }
`;
export const ReviewName = styled.h4`
  margin: 0;
  margin-top: 35px;
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;
  padding-bottom: 5px;

  @media (min-width: ${theme['screen-lg']}) {
    margin-top: 50px;
  }
`;

export const ReviewPosition = styled.p`
  margin: 0;
  margin-top: 6px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
`;
