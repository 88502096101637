import styled from 'styled-components';
import Img from 'gatsby-image';
import theme from '../../styles/theme';

export const LayoutWrapper = styled('div')`
  --header-height: 100px;

  // 480px
  @media (min-width: ${theme['screen-xs']}) {
    --page-block-left: 0;
    --page-block-right: 100%;
    --page-block-nav-label: 20px;

    --contect-width: 100%;
  }

  // 576px
  @media (min-width: ${theme['screen-sm']}) {
    --page-block-left: 0;
    --page-block-right: 100%;

    --contect-width: 100%;
  }

  // 768px
  @media (min-width: ${theme['screen-md']}) {
    --page-block-left: 200px;
    --page-block-right: calc(100% - 200px);
    --page-block-nav-label: 2em;

    --contect-width: 720px;
  }

  // 992px
  @media (min-width: ${theme['screen-lg']}) {
    --header-height: 140px;

    --page-block-left: 300px;
    --page-block-right: calc(100% - 450px);
    --page-block-nav-label: 6em;

    --contect-width: 880px;
  }

  // 1200px
  @media (min-width: ${theme['screen-xl']}) {
    --page-block-left: 32vw;
    --page-block-right: 40vw;
    --page-block-nav-label: 11em;

    --contect-width: 1100px;
  }

  // 1600px
  @media (min-width: ${theme['screen-xxl']}) {
    --page-block-left: 32vw;
    --page-block-right: 35vw;

    --contect-width: 1440px;
  }

  position: relative;
  background: ${theme['layout-body-background']};
  width: 100%;
  //overflow: hidden;
`;

export const FooterImageWrapper = styled.div`
  clip-path: url(#footer-clip-path-mobile);
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 300px;
  height: 685px;
  overflow: hidden;

  .gatsby-image-wrapper {
    width: 100% !important;
  }

  @media (min-width: ${theme['screen-lg']}) {
    clip-path: url(#footer-clip-path-desktop);
    right: auto;
    left: 0;
    bottom: 0;
    width: 1050px;
    height: 520px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      opacity: 50%;
      background: linear-gradient(
        0deg,
        rgba(23, 23, 23, 0.2),
        rgba(23, 23, 23, 0.2)
      );
    }
  }
`;

export const FooterImage = styled(Img)``;
