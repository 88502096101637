import React, { ReactNode } from 'react';
import { Wrapper, Btn, Before, After, Content } from './index.styled';

type Props = {
  children: React.ReactNode;
  before?: ReactNode;
  after?: ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onTouchStart?: (event: React.SyntheticEvent<HTMLElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isClicked?: boolean;
};

const Button: React.FC<Props> = ({
  children,
  before,
  after,
  className,
  onClick,
  onTouchStart,
  onMouseEnter,
  onMouseLeave,
  isClicked,
}: Props) => {
  return (
    <Wrapper className={className}>
      <Btn
        className={`button ${isClicked ? 'clicked' : 'not-clicked'}`}
        onClick={onClick}
        onTouchStart={onTouchStart}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {before && <Before className="button__before">{before}</Before>}
        {children && <Content className="button__content">{children}</Content>}
        {after && <After className="button__after">{after}</After>}
      </Btn>
    </Wrapper>
  );
};

export default Button;
