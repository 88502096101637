import React from 'react';

const FooterClipPathDesktop: React.FC = () => (
  <svg width="0" height="0">
    <clipPath id="footer-clip-path-desktop" clipPathUnits="objectBoundingBox">
      <path d="M0.00068455 0.993557L0 0.991245V0.00591033C0.0298112 0.00912376 0.0588299 0.0282188 0.0835422 0.0613775C0.102674 0.0870481 0.130909 0.0629182 0.158581 0.0392699C0.167414 0.0317214 0.176189 0.024222 0.184592 0.0184069C0.227788 -0.0114837 0.277254 -0.00587695 0.320104 0.0418937C0.358172 0.0843323 0.383925 0.152139 0.394943 0.227871L0.396923 0.24152C0.399582 0.261605 0.400574 0.282427 0.401566 0.303236C0.404288 0.360335 0.407005 0.417333 0.444128 0.458718L0.449412 0.464866C0.453786 0.469631 0.458056 0.474801 0.462204 0.480367C0.480249 0.504579 0.513402 0.494602 0.53795 0.467236C0.60955 0.387414 0.699619 0.425315 0.748046 0.549835C0.758968 0.575544 0.767807 0.60468 0.774064 0.63628C0.778975 0.661082 0.804843 0.665138 0.832062 0.669405C0.846794 0.671715 0.861921 0.674087 0.874335 0.679843C0.915107 0.698749 0.952018 0.748045 0.975219 0.822635C0.990926 0.870775 1.00001 0.927776 1.00001 0.988876C1.00001 0.992587 0.999979 0.996283 0.999912 0.999963H0.00265193C0.00198499 0.997848 0.00132912 0.995713 0.00068455 0.993557Z" />
    </clipPath>
  </svg>
);

export default FooterClipPathDesktop;
