import React from 'react';

type Props = {
  width?: number;
  height?: number;
  fill?: string;
  id?: string;
};

const IconPlus: React.FC<Props> = (props: Props) => {
  const { width, height, fill, id } = props;
  const useTag = `<use xlink:href="#${id || `cross_${Date.now()}`}"><svg
        id="${id || `cross_${Date.now()}`}"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="4" class="st0" width="2" fill=${fill} height="10"/>
        <rect x="0" y="4" class="st0" width="10" fill=${fill} height="2"/>
      </svg></use>`;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: useTag }}
    />
  );
};

IconPlus.defaultProps = {
  width: 10,
  height: 10,
  fill: '#060606',
};

export default IconPlus;
