import React from 'react';
import QueueAnim from 'rc-queue-anim';
import DesktopMenu from './desktopMenu';
import MobileMenu from './mobileMenu';
import { Wrapper, Container } from './index.styled';
import useResponsiveUtils from '../../utils/useResponsiveUtils';

type Props = {
  toggleMenu: () => void;
  isOpen: boolean;
};

const NavigationMenu: React.FC<Props> = ({ toggleMenu, isOpen }: Props) => {
  const { isDesktop } = useResponsiveUtils();

  return (
    <QueueAnim
      animConfig={[
        { opacity: 1, x: [0, -1000] },
        { opacity: 0, x: 500 },
      ]}
      ease={['easeOutQuart', 'easeInOutQuart']}
    >
      <Wrapper show={isOpen}>
        {isOpen ? (
          <Container key="0">
            {isDesktop ? (
              <DesktopMenu toggleMenu={toggleMenu} />
            ) : (
              <MobileMenu toggleMenu={toggleMenu} />
            )}
          </Container>
        ) : null}
      </Wrapper>
    </QueueAnim>
  );
};

export default NavigationMenu;
