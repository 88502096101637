import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import useItemsData from '../Item/useItemsData';
import {
  FooterMenuWrapper,
  FooterMenuHeader,
  FooterMenuList,
  FooterMenuListItem,
} from './index.styled';

type Props = {
  header: string;
  children: React.ReactNode;
  menuNumber?: number;
};

type ItemData = {
  title: string;
  href?: string;
  className: string;
  absolute?: boolean;
};

const FooterMenu: React.FC<Props> = (props: Props) => {
  const { header, children, menuNumber } = props;
  const data = useItemsData<ItemData>(children);

  return (
    <FooterMenuWrapper>
      <FooterMenuHeader className={header === '' ? 'hidden' : ''}>
        {header}
      </FooterMenuHeader>
      <FooterMenuList
        menuNumber={menuNumber || 0}
        className={header === '' ? 'without-header' : ''}
      >
        {data.map(item => (
          <FooterMenuListItem
            className={item.className}
            key={item.title}
            menuNumber={menuNumber || 0}
          >
            {item.href && !item.absolute && (
              <AnchorLink to={item.href} title="Вакансии">
                {item.title}
              </AnchorLink>
            )}
            {item.href && item.absolute && <a href={item.href}>{item.title}</a>}
            {!item.href && <>{item.title}</>}
          </FooterMenuListItem>
        ))}
      </FooterMenuList>
    </FooterMenuWrapper>
  );
};

export default FooterMenu;
