import { Carousel } from 'antd';
import { Keys } from './useKeyPressUtils';

export const truncate = (
  string: string,
  length: number,
  ending = '...',
): string => {
  const maxLength = typeof length === 'undefined' ? 100 : length;

  if (string.length > maxLength) {
    return string.substring(0, maxLength - ending.length) + ending;
  }

  return string;
};

export const isVisible = (element: HTMLElement | null): boolean => {
  if (typeof element === undefined || !element) return false;
  const { top } = element.getBoundingClientRect();
  const windowExists = typeof window !== 'undefined';
  return windowExists && top >= 0 && top <= window.innerHeight;
};

export const moveCarousel = (
  carousel: Carousel | null,
  carouselWrapper: HTMLElement | null,
  keyCode: number,
): void => {
  if (carousel && carouselWrapper) {
    if (isVisible(carouselWrapper)) {
      if (keyCode === Keys.ARROW_LEFT) {
        carousel.innerSlider.slickPrev();
      } else if (keyCode === Keys.ARROW_RIGHT) {
        carousel.innerSlider.slickNext();
      }
    }
  }
};

const headerHeight = 100;

export const isVisibleOnTop = (element: HTMLElement | null): boolean => {
  if (typeof element === undefined || !element) return false;
  const { top, bottom } = element.getBoundingClientRect();
  const windowExists = typeof window !== 'undefined';
  return windowExists && top <= headerHeight && bottom > headerHeight;
};

export const moveAccordion = (
  accordion: HTMLElement | null,
  keyCode: number,
  currentItem = 0,
  maxItem = 1,
): number => {
  if (!accordion || !isVisible(accordion)) {
    return 0;
  }

  if (keyCode === Keys.ARROW_LEFT) {
    if (currentItem > 0) return currentItem - 1;
  } else if (keyCode === Keys.ARROW_RIGHT) {
    if (currentItem < maxItem) return currentItem + 1;
  }

  return 0;
};
