import React from 'react';

const FooterClipPathMobile: React.FC = () => (
  <svg width="0" height="0">
    <clipPath id="footer-clip-path-mobile" clipPathUnits="objectBoundingBox">
      <path d="M1.00005 0.00150852C0.938038 -0.00246018 0.875016 0.00147713 0.818591 0.0124324C0.748321 0.0260757 0.665753 0.0333684 0.611322 0.0196692C0.454435 -0.0198165 0.253823 0.00361609 0.163244 0.0720074C0.0726649 0.140399 0.126419 0.22785 0.283306 0.267336C0.318044 0.276079 0.354925 0.281737 0.392281 0.284506C0.425313 0.286954 0.453621 0.349067 0.466412 0.37962C0.477972 0.407233 0.458219 0.438245 0.412771 0.455816C0.35005 0.480065 0.282015 0.526828 0.219249 0.56997L0.219245 0.569973C0.193973 0.587344 0.169555 0.604128 0.146682 0.618618C0.141753 0.621741 0.137067 0.624973 0.132645 0.628312L0.129552 0.630699C0.0890583 0.662646 0.0653686 0.761485 0.0444058 0.848946C0.0319521 0.900907 0.0204607 0.948851 0.00698023 0.976369C0.00309332 0.984303 0.000797871 0.992224 0 1.00007H1.00005V0.871907C0.987335 0.872913 0.974894 0.874234 0.962771 0.87585C0.958077 0.876476 0.953907 0.875126 0.950809 0.871544C0.942292 0.8617 0.947859 0.842103 0.960857 0.841418C0.974067 0.840721 0.987157 0.839677 1.00005 0.838293V0.00150852Z" />
    </clipPath>
  </svg>
);

export default FooterClipPathMobile;
