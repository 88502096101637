import React, { useCallback, useEffect, useRef, useState } from 'react';
import { get, isNull } from 'lodash';
import { Carousel } from 'antd';
import TweenOne from 'rc-tween-one';
import useItemsData from '../../components/Item/useItemsData';
import theme from '../../styles/theme';
import {
  BLOCK_WIDTH,
  BLOCK_WIDTH_MOBILE,
  CardModal,
  CarouselWrapper,
  ContentItem,
} from './index.styled';
import useResponsiveUtils, { ScreenType } from '../../utils/useResponsiveUtils';
import IconArrowRight from '../../l1_atoms/IconArrowRight';
import IconArrowLeft from '../../l1_atoms/IconArrowLeft';
import IconCross from '../../l1_atoms/IconCross';
import { moveCarousel } from '../../utils/helpers';
import useKeyPressUtils from '../../utils/useKeyPressUtils';

type Props = {
  children: React.ReactNode;
  color?: 'black' | 'white';
  defaultActiveItem?: number;
  transitionType?: 'default' | 'horizontal';
};

type ItemData = {
  id: string;
  card: React.ReactNode;
  modal: React.ReactNode;
};

enum Directions {
  Left = 'left',
  Right = 'right',
}

const CarouselCards: React.FC<Props> = ({
  children,
  color,
  defaultActiveItem = 1,
  transitionType = 'default',
}: Props) => {
  const [activeItemIndex, setActiveIndex] = useState(-1);
  const [activeModalIndex, setActiveModalIndex] = useState<number | null>(null);
  const data = useItemsData<ItemData>(children);
  const activeItem = !isNull(activeModalIndex) && get(data, activeModalIndex);
  const slickRef = useRef<Carousel>(null);
  const [carouselEnabled, setCarouselEnabled] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onResize = useCallback(
    (width: number, _height: number, screenType: ScreenType): void => {
      const itemWidth =
        screenType === ScreenType.MOBILE ? BLOCK_WIDTH_MOBILE : BLOCK_WIDTH;
      const isDataBiggerThanScreen =
        data.length > 3 || data.length * itemWidth > width;
      if (screenType !== ScreenType.MOBILE && !isDataBiggerThanScreen) {
        setCarouselEnabled(false);
      } else {
        setCarouselEnabled(true);
      }
    },
    [activeItemIndex],
  );

  const { isMobile } = useResponsiveUtils({ onResize });
  const localDefaultActiveItem = isMobile ? 1 : defaultActiveItem;
  const slideIndexOffset = isMobile ? 0 : 2;

  const onSlideChange = (_from: number, to: number) => {
    setActiveIndex(to - slideIndexOffset);
  };

  const onCarouselItemClick = (idx: number) => () => {
    const clickedItem = get(data, idx);

    if (activeItemIndex !== idx && carouselEnabled) {
      if (slickRef.current) {
        slickRef.current.goTo(idx + slideIndexOffset);
      }
    } else if (clickedItem && clickedItem.modal) {
      setActiveModalIndex(idx);
      setIsModalVisible(true);
    }
  };

  useEffect(() => {
    if (carouselEnabled) return;

    if (slickRef.current) {
      slickRef.current.goTo(localDefaultActiveItem - 1 + slideIndexOffset);
    }
  }, [carouselEnabled]);

  useEffect(() => {
    if (slickRef.current) {
      if (activeItemIndex < 0) {
        const newItemIndex = localDefaultActiveItem - 1;
        setActiveIndex(newItemIndex);
        slickRef.current.goTo(newItemIndex + slideIndexOffset, true);
      }

      if (activeItemIndex > data.length - 1) {
        slickRef.current.goTo(data.length - 1, true);
      }
    }
  }, [activeItemIndex]);

  const [pausedLeft, setPausedLeft] = useState(true);
  const [reverseLeft, setReverseLeft] = useState(true);
  const [pausedRight, setPausedRight] = useState(true);
  const [reverseRight, setReverseRight] = useState(true);
  const [pausedCross, setPausedCross] = useState(true);
  const [reverseCross, setReverseCross] = useState(true);
  const [isTouch, setIsTouch] = useState(false);

  const arrow = (direction: string, active: boolean): JSX.Element | null => {
    return !isMobile ? (
      <button
        onTouchStart={() => {
          if (direction === Directions.Left) {
            setPausedLeft(false);
            setReverseLeft(false);
          } else {
            setPausedRight(false);
            setReverseRight(false);
          }
          setIsTouch(true);
        }}
        onMouseEnter={() => {
          if (direction === Directions.Left) {
            setPausedLeft(false);
            setReverseLeft(false);
          } else {
            setPausedRight(false);
            setReverseRight(false);
          }
        }}
        onMouseLeave={() => {
          if (direction === Directions.Left) {
            setReverseLeft(true);
          } else {
            setReverseRight(true);
          }
        }}
        className={direction === Directions.Left ? 'prev' : 'next'}
        type="button"
        onClick={() => {
          if (!slickRef.current) return;

          if (direction === Directions.Right) {
            slickRef.current.innerSlider.slickNext();
          } else if (direction === Directions.Left && activeItemIndex > 0) {
            slickRef.current.innerSlider.slickPrev();
          }
        }}
      >
        {direction === Directions.Right ? (
          <IconArrowRight
            width={42}
            height={25}
            fill={active ? '#1b61da' : '#ffffff'}
          />
        ) : (
          <IconArrowLeft
            width={42}
            height={25}
            fill={active ? '#1b61da' : '#ffffff'}
          />
        )}
      </button>
    ) : null;
  };

  const appendDots = (dots: Array<React.ReactNode>): JSX.Element => {
    if (!isMobile && dots) {
      dots.splice(0, 2);
    }
    return (
      <div className="dots-wrapper">
        <TweenOne
          paused={pausedLeft}
          animation={[
            {
              left: !reverseLeft ? '-5px' : '0',
              duration: 200,
              ease: 'linear',
            },
          ]}
          style={{
            left: '0',
            position: 'absolute',
          }}
        >
          {arrow(Directions.Left, !reverseLeft)}
        </TweenOne>
        <ul>{dots}</ul>
        <TweenOne
          paused={pausedRight}
          animation={[
            {
              right: !reverseRight ? '-5px' : '0',
              duration: 200,
              ease: 'linear',
            },
          ]}
          style={{
            right: '0',
            position: 'absolute',
          }}
        >
          {arrow(Directions.Right, !reverseRight)}
        </TweenOne>
      </div>
    );
  };

  const afterChange = () => {
    if (isTouch) {
      setReverseLeft(true);
      setReverseRight(true);
      setIsTouch(false);
    }
  };

  const slickWrapperRef = useRef<HTMLDivElement>(null);

  const onKeyPress = useCallback((keyCode: number): void => {
    moveCarousel(slickRef.current, slickWrapperRef.current, keyCode);
  }, []);

  useKeyPressUtils({ onKeyPress });

  return (
    <>
      <CarouselWrapper ref={slickWrapperRef} color={color || 'black'}>
        <Carousel
          ref={slickRef}
          beforeChange={onSlideChange}
          slidesToShow={5}
          responsive={[
            {
              breakpoint: parseInt(theme['screen-lg'], 10),
              settings: { initialSlide: 3 },
            },
            {
              breakpoint: parseInt(theme['screen-sm'], 10),
              settings: { slidesToShow: 3, slidesToScroll: 1, initialSlide: 1 },
            },
          ]}
          centerMode
          infinite={false}
          dots={carouselEnabled}
          swipe={carouselEnabled}
          dotPosition="bottom"
          cssEase={
            isMobile ? 'ease' : 'cubic-bezier(0.420, 0.000, 0.580, 1.000)'
          }
          speed={isMobile ? 300 : 600}
          slidesToScroll={1}
          touchThreshold={isMobile ? 12 : 5}
          centerPadding={isMobile ? '20px' : '50px'}
          appendDots={appendDots}
          afterChange={afterChange}
          initialSlide={3}
        >
          {!isMobile && <ContentItem blank />}
          {!isMobile && <ContentItem blank />}
          {data.map((item, idx) => (
            <ContentItem
              key={item.id}
              current={idx === activeItemIndex || !carouselEnabled}
              active={
                carouselEnabled &&
                (idx === activeItemIndex - 1 || idx === activeItemIndex + 1)
              }
              direction={
                idx < activeItemIndex ? Directions.Left : Directions.Right
              }
              onClick={onCarouselItemClick(idx)}
              hasModal={Boolean(item.modal)}
              transitionType={transitionType}
            >
              {item.card}
            </ContentItem>
          ))}
          <ContentItem blank />
          <ContentItem blank />
          {!isMobile && <ContentItem blank />}
          {!isMobile && <ContentItem blank />}
        </Carousel>
      </CarouselWrapper>
      <CardModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        afterClose={() => {
          setActiveModalIndex(null);
        }}
        closeIcon={
          <div
            onMouseEnter={() => {
              setPausedCross(false);
              setReverseCross(false);
            }}
            onMouseLeave={() => {
              setReverseCross(true);
            }}
          >
            <TweenOne
              paused={pausedCross}
              animation={[
                {
                  rotate: !reverseCross ? 0 : -90,
                  duration: 300,
                  ease: 'linear',
                },
              ]}
              style={{
                lineHeight: 0,
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
            >
              <IconCross
                width={isMobile ? 16 : 19}
                height={isMobile ? 16 : 19}
                id="modal-close"
              />
            </TweenOne>
          </div>
        }
      >
        {activeItem && activeItem.modal}
      </CardModal>
    </>
  );
};

export default CarouselCards;
