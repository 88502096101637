import styled from 'styled-components';
import theme from '../../styles/theme';

export const ModalWrapper = styled.div`
  color: ${theme.black};
  padding: 0;
  margin: 0 -10px;
  height: 100%;
  @media (min-width: ${theme['screen-md']}) {
    margin: 0;
    padding: 60px 0 0;
  }
`;

export const ModalTitle = styled.div`
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 36px;
  line-height: 1.1em;
  letter-spacing: -0.015em;
  text-transform: lowercase;

  @media (min-width: ${theme['screen-md']}) {
    font-size: 100px;
    margin-bottom: 48px;
    letter-spacing: -0.02em;
    line-height: 75px;
  }
`;

export const ModalBody = styled.div`
  height: 100%;
  width: 100%;
  [class*='ymaps-2-1'][class*='-ground-pane'] {
    -webkit-filter: grayscale(100%);
  }
  @media (min-width: ${theme['screen-md']}) {
    height: 400px;
  }
`;
