import styled from 'styled-components';
import theme from '../../../styles/theme';
import Badge from '../../../l1_atoms/Badge';

export const ContentItemWrapper = styled.div`
  padding: 20px 16px 40px;
  border-radius: 16px;
  background: ${theme.backgroundWhite};
  color: ${theme.black};
`;

export const ContentItemNumber = styled.div`
  height: 23px;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
`;

export const ContentItemImage = styled.div`
  display: block;

  img {
    width: 100%;
    height: 150px;
    object-fit: contain;

    @media (min-width: ${theme['screen-md']}) {
      height: 170px;
    }
  }
`;

type ContentItemTitleProps = {
  whiteSpace?: boolean;
};

export const ContentItemTitle = styled.div<ContentItemTitleProps>`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 26px;
  line-height: 1.1em;
  letter-spacing: -0.015em;
  text-transform: lowercase;
  padding-top: 10px;

  @media (min-width: ${theme['screen-md']}) {
    font-size: 40px;
    padding-top: 15px;
    margin-bottom: 20px;
    white-space: ${props => (props.whiteSpace ? 'nowrap' : 'normal')};
  }
`;

export const ContentItemText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2em;

  @media (min-width: ${theme['screen-md']}) {
    font-size: 18px;
  }
`;

export const ContentItemLinks = styled.div`
  padding-top: 17px;
  display: flex;
  @media (min-width: ${theme['screen-md']}) {
    font-size: 42px;
    padding-top: 37px;
  }
`;

export const ContentItemLink = styled(Badge)``;
