import React from 'react';

const Logo: React.FC = () => {
  return (
    <svg width="89" height="20" viewBox="0 0 89 20" fill="none">
      <path
        d="M16.1291 2.5064C16.5645 2.0779 17.0807 1.69885 17.6613 1.36923C18.1613 1.08906 18.742 0.841844 19.4355 0.594632C20.129 0.363901 20.9194 0.248535 21.8065 0.248535C22.9194 0.248535 23.9516 0.446305 24.9032 0.825363C25.8387 1.20442 26.6452 1.74829 27.3065 2.45696C27.9677 3.16564 28.5 4.02264 28.871 5.04445C29.2419 6.06626 29.4355 7.21991 29.4355 8.48893V19.4651H25.5323V8.53838C25.5323 7.15399 25.0968 6.0333 24.2419 5.15981C23.3871 4.28633 22.3065 3.85783 21.0323 3.85783C19.5484 3.85783 18.3549 4.31929 17.4678 5.2587C16.5807 6.18162 16.1291 7.53305 16.1291 9.31297V19.4651H12.2258V0.281497H16.1291V2.5064Z"
        fill="#FFFFFF"
      />
      <path
        d="M32.8547 9.87332C32.8547 8.57133 33.1128 7.33527 33.6289 6.18162C34.1451 5.02796 34.8547 4.00615 35.758 3.11619C36.6612 2.22623 37.6934 1.53404 38.8709 1.02313C40.0644 0.512228 41.3064 0.248535 42.6451 0.248535C43.9676 0.248535 45.2257 0.512228 46.4031 1.02313C47.5805 1.53404 48.6128 2.24271 49.516 3.11619C50.4193 4.00615 51.1289 5.02796 51.6451 6.18162C52.1612 7.33527 52.4193 8.57133 52.4193 9.87332C52.4193 11.1753 52.1612 12.4114 51.6451 13.565C51.1289 14.7187 50.4193 15.7405 49.516 16.6304C48.6128 17.5204 47.5805 18.2126 46.4031 18.7235C45.2257 19.2344 43.9838 19.4981 42.6451 19.4981C41.3225 19.4981 40.0644 19.2344 38.887 18.7235C37.7096 18.2126 36.6773 17.5039 35.7741 16.6304C34.8709 15.7405 34.1612 14.7187 33.6451 13.565C33.1289 12.4114 32.8547 11.1918 32.8547 9.87332ZM48.4999 9.87332C48.4999 9.01631 48.3386 8.22524 48.0322 7.50008C47.7257 6.77493 47.2902 6.13218 46.7418 5.58831C46.1934 5.04444 45.5644 4.61594 44.8709 4.30281C44.1612 3.98967 43.4193 3.82487 42.6451 3.82487C41.8709 3.82487 41.1128 3.98967 40.4193 4.30281C39.7096 4.61594 39.0967 5.04444 38.5483 5.58831C37.9999 6.13218 37.5644 6.75845 37.258 7.50008C36.9354 8.22524 36.7741 9.01631 36.7741 9.87332C36.7741 10.7303 36.9354 11.5214 37.2418 12.2465C37.5483 12.9717 37.9838 13.6145 38.5322 14.1583C39.0805 14.7022 39.7096 15.1307 40.4031 15.4438C41.1128 15.757 41.8547 15.9218 42.6289 15.9218C43.4031 15.9218 44.1612 15.757 44.8547 15.4438C45.5644 15.1307 46.1773 14.7022 46.7257 14.1583C47.2741 13.6145 47.7096 12.9882 48.016 12.2465C48.3386 11.5214 48.4999 10.7303 48.4999 9.87332Z"
        fill="#FFFFFF"
      />
      <path
        d="M60.4838 19.4683L52.8547 0.284668H56.9676L62.4354 14.2604L67.9031 0.284668H72.016L64.387 19.4683H60.4838Z"
        fill="#FFFFFF"
      />
      <path
        d="M85.0967 19.4651V17.2567C84.6613 17.6852 84.1451 18.0643 83.5645 18.3774C83.0645 18.6576 82.4838 18.9048 81.7903 19.152C81.0967 19.3827 80.3064 19.5146 79.4193 19.5146C78.4355 19.5146 77.5322 19.3662 76.7258 19.0531C75.9193 18.74 75.2258 18.3444 74.629 17.85C74.0484 17.3556 73.5806 16.7788 73.258 16.136C72.9355 15.4933 72.7742 14.834 72.7742 14.1748C72.7742 12.6421 73.2742 11.3895 74.258 10.3842C75.2419 9.37889 76.5806 8.73614 78.2419 8.45597L85.0806 7.31879C85.0806 6.34642 84.7258 5.52239 84 4.86315C83.2903 4.20392 82.3387 3.85783 81.1613 3.85783C80.3226 3.85783 79.6129 3.98967 79.0161 4.26985C78.4193 4.53354 77.9032 4.83019 77.4838 5.14333C77.0161 5.5059 76.6129 5.91792 76.2742 6.37939L73.7258 3.85783C74.2419 3.1986 74.8709 2.58881 75.5967 2.06142C76.2258 1.61644 76.9838 1.18794 77.9032 0.825363C78.8548 0.429824 79.9355 0.248535 81.1774 0.248535C82.3548 0.248535 83.4193 0.429824 84.3871 0.792401C85.3548 1.15498 86.1774 1.66588 86.8548 2.30863C87.5322 2.95138 88.0645 3.7095 88.4355 4.5665C88.8064 5.4235 89 6.34642 89 7.31879V19.4816H85.0967V19.4651ZM80.0161 15.9053C81.5806 15.9053 82.8226 15.4603 83.7258 14.5539C84.6451 13.6474 85.0967 12.3619 85.0967 10.6644V10.2853L79.0322 11.2247C78.3548 11.3566 77.7903 11.6203 77.3548 12.0488C76.9193 12.4773 76.6935 13.0047 76.6935 13.6639C76.6935 14.2407 76.9677 14.7516 77.5322 15.2131C78.0967 15.6745 78.9193 15.9053 80.0161 15.9053Z"
        fill="#FFFFFF"
      />
      <path
        d="M0 9.90944C0 9.18429 0.112903 8.55802 0.33871 8.04711C0.564516 7.53621 0.838709 7.09123 1.17742 6.72865C1.5 6.36608 1.85484 6.05294 2.25806 5.78925C2.64516 5.52556 3 5.27834 3.33871 5.04761C3.67742 4.81688 3.95161 4.56967 4.17742 4.32246C4.40322 4.07525 4.51613 3.77859 4.51613 3.4325C4.51613 3.05344 4.40322 2.75678 4.17742 2.54253C3.95161 2.32828 3.6129 2.21292 3.17742 2.21292C2.8871 2.21292 2.64516 2.27884 2.46774 2.41069C2.25806 2.52605 2.1129 2.6579 1.98387 2.82271C1.85484 2.98752 1.74193 3.18528 1.67742 3.41602L0 2.62494C0.145161 2.19644 0.370968 1.8009 0.66129 1.4548C0.790322 1.30648 0.935484 1.17463 1.09677 1.0263C1.25806 0.877976 1.45161 0.76261 1.66129 0.647245C1.87097 0.53188 2.1129 0.449476 2.3871 0.383553C2.66129 0.31763 2.95161 0.284668 3.29032 0.284668C3.77419 0.284668 4.2258 0.367072 4.62903 0.515399C5.03226 0.663726 5.38709 0.894457 5.67742 1.15815C5.96774 1.43832 6.17742 1.75146 6.33871 2.11403C6.5 2.47661 6.58064 2.87215 6.58064 3.30065C6.58064 3.72915 6.5 4.10821 6.35484 4.42134C6.2258 4.73448 6.01613 5.03113 5.77419 5.27834C5.53226 5.52556 5.25806 5.77277 4.96774 5.97054C4.67742 6.18479 4.38709 6.39904 4.08064 6.59681C3.77419 6.81106 3.5 7.02531 3.22581 7.23956C2.95161 7.47029 2.72581 7.7175 2.54839 7.99767H6.58064V9.92592H0V9.90944Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export default Logo;
