import React, { ReactNode, useState } from 'react';
import TweenOne from 'rc-tween-one';

import { Component, Before, After, Content } from './index.styled';
import IconPlus from '../IconPlus';

type Props = {
  children: ReactNode;
  before?: ReactNode;
  after?: ReactNode;
  id?: string;
  className?: string;
};

const Badge: React.FC<Props> = ({
  children,
  before,
  after,
  id,
  className,
}: Props) => {
  const [paused, setPaused] = useState(true);
  const [reverse, setReverse] = useState(false);

  return (
    <Component
      className={`badge ${className}`}
      onMouseEnter={() => {
        setPaused(false);
        setReverse(false);
      }}
      onMouseLeave={() => {
        setPaused(false);
        setReverse(true);
      }}
    >
      {before && <Before className="badge__before">{before}</Before>}
      {children && <Content className="badge__content">{children}</Content>}

      <After className="badge__after">
        <TweenOne
          paused={paused}
          reverse={reverse}
          animation={[
            {
              rotate: 90,
              duration: 300,
              ease: 'linear',
            },
          ]}
          style={{}}
        >
          {after || <IconPlus id={id} />}
        </TweenOne>
      </After>
    </Component>
  );
};

export default Badge;
