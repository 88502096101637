import React from 'react';

const Next: React.FC = () => {
  return (
    <svg width="42" height="25" viewBox="0 0 42 25" fill="none">
      <path
        d="M40 12.599L40.66 13.3502L41.5067 12.6064L40.6674 11.8542L40 12.599ZM0 13.599L40 13.599V11.599L0 11.599L0 13.599ZM40.6674 11.8542L27.7239 0.255274L26.3892 1.74473L39.3326 13.3437L40.6674 11.8542ZM39.34 11.8477L26.3966 23.2193L27.7166 24.7218L40.66 13.3502L39.34 11.8477Z"
        fill="#060606"
      />
    </svg>
  );
};

export default Next;
