import React from 'react';
import StoryProvider from '../../components/StoryProvider';
import StorySlides from '../../l2_molecules/StorySlides';

type Props = {
  title?: React.ReactNode;
  email?: string;
  desc?: React.ReactNode;
  children: React.ReactNode[];
};

const Stories: React.FC<Props> = ({ children }: Props) => {
  return (
    <StoryProvider>
      <StorySlides>{children}</StorySlides>
    </StoryProvider>
  );
};

export default Stories;
