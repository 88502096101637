import React from 'react';
import 'rc-texty/assets/index.css';
import { get, sample } from 'lodash';
import { graphql, PageProps } from 'gatsby';
import Item from '../components/Item';
import Layout from '../components/layout';
import SEO from '../components/seo';
import StartScreen from '../components/StartScreen';
import Header from '../components/Header';
import PageBlock from '../components/PageBlock';
import FooterBlock from '../l3_organism/FooterBlock';
import HowWeWorkCarousel from '../l3_organism/HowWeWorkCarousel';
import CarouselWithNavigation from '../l3_organism/CarouselWithNavigation';
import CarouselCards from '../l3_organism/CarouselCards';
import NavigatedContent from '../l3_organism/NavigatedContent';
import Contacts from '../l3_organism/Contacts';
import ValueCard from '../l2_molecules/ValueCards/Card';
// import ValueCardModal from '../l2_molecules/ValueCards/CardModal';
import Review from '../l2_molecules/Review';
// import Images from '../l1_atoms/Images';

const IndexPage: React.FC<PageProps> = ({ data }: PageProps) => {
  const mainImages = [
    get(data, 'mainImage1.childImageSharp.fixed'),
    get(data, 'mainImage2.childImageSharp.fixed'),
    get(data, 'mainImage3.childImageSharp.fixed'),
    get(data, 'mainImage4.childImageSharp.fixed'),
  ];

  const bgImage = sample(mainImages);

  const navLabel = 'A';

  return (
    <Layout footerImage={bgImage}>
      <SEO title="2Nova" />

      <Header />
      <StartScreen
        websiteTitle="digital solutions"
        title="design"
        subtitle="delivery"
        image={bgImage}
        text={`
Мы проектируем, создаем
и доставляем до пользователей цифровые
решения для крупнейших российских компаний
        `}
      />

      <PageBlock
        nav={navLabel}
        navIndex="1"
        title={<>2Nova — технологическая компания.</>}
        description={
          <>
            Мы занимаемся созданием product development services.
            <br /> Наши продуктовые и сервисные команды работают в долгосрочном
            партнерстве с цифровыми подразделениями крупнейших российских
            компаний. Мы сопровождаем наших клиентов на протяжении всего
            процесса. От исследовании и определении иновационной стратегии до
            продуктовой разработки и дальнейшей поддержки.
            <br />
            <br />В нашей работе мы сочетаем практики человеко-ориентированного
            проектирования, lean-agile ценности и высокий уровень инженерной
            культуры.
          </>
        }
        titleSize="small"
        fullHeight
      />

      <PageBlock
        nav={navLabel}
        navIndex="21"
        title={<>Как мы работаем</>}
        // Скрыл линк на страницу команды, так как нам нужно запуститься, а фотографий команды все еще нет
        // link="/team"
        // linkTitle="Команда"
        fullHeight
      >
        <HowWeWorkCarousel>
          <Item title="Продуктовый подход">
            Во время работы над цифровых решениий мы фокусируемся на ценности
            для бизнеса и человека, действуем в коллаборации с функциями,
            принимаем решения на основе данных и двигаемся небольшими
            итерациями.
          </Item>
          <Item title="Непрерывное улучшение">
            Вместе с нашими клиентами мы выстраиваем процесс непрерывного
            улучшения, который позволяет поддерживать эффективное взаимодействие
            даже в постоянно меняющейся внутренней и внешней среде.
          </Item>
          <Item title="Прозрачность и гибкость">
            Мы умеем адаптироваться под правила игры и понимаем все ограничения,
            связанные с созданием цифровых решения в крупных и государственных
            компаниях. Прозрачность для нас — основа долгосрочных партнерских
            отношений.
          </Item>
          <Item title="Современные практики">
            Мы комбинируем последние технологические практики с современными
            подходами к производству цифровых проектов. В основе нашего процесса
            взаимодействия с клиентом — Scaled Agile Framework.
          </Item>
        </HowWeWorkCarousel>
      </PageBlock>

      <PageBlock
        nav={navLabel}
        navIndex="22"
        theme="white"
        themeMobile="black"
        title={<>Инженерная культура</>}
        fullHeight
      >
        <CarouselWithNavigation>
          <Item title="Технологический стек">
            <p>
              Мы используем гибкий, надежный и поддерживаемый технологический
              стек при разработке продуктов.
            </p>
            <p>
              Мы имеем опыт работы как с реляционными БД, так и с
              документоориентированными.
            </p>
            <p>
              При выборе технологического стека мы обращаем внимание на экономию
              расходов для серверного окружения.
            </p>
            <ul>
              <li>.Net Core</li>
              <li>PHP &amp;&amp; (Laravel || Symfony)</li>
              <li>React &amp;&amp; (Next.js || Gatsby.js), Vue</li>
              <li>Node.js</li>
              <li>Cordova, React Native, Flutter</li>
              <li>REST API, GQL</li>
            </ul>
          </Item>
          <Item title="Процесс релиза">
            <p>
              Процесс поставки и развертывания релиза имеет множество рисков,
              связанных с человеческим фактором.
            </p>
            <p>
              Для снижения количества ошибок или длительной недоступности
              продукта по причине развертывания релиза наши команды
              автоматизируют этот процесс.
            </p>
            <p>
              Дополнительно используются инструменты автоматизированного
              тестирования, чтобы снизить количество функциональных ошибок в
              релизе.
            </p>
            <ul>
              <li>CI/CD TeamCity, Bitbuket , Github</li>
              <li>Git &amp;&amp; Git flow, Github flow, Gitlab flow</li>
              <li>Docker, k8s</li>
              <li>Unit тесты</li>
              <li>Интеграционные тесты</li>
            </ul>
          </Item>
          <Item title="Архитектурные паттерны">
            <p>
              Для разработки продуктов мы используем актуальные архитектурные
              паттерны и принципы проектирования, необходимые для текущих
              потребностей продукта.
            </p>
            <p>
              Это позволяет не тратить бюджет на поддержание избыточных
              архитектурных решений, но, в тоже время, иметь поддерживаемую и
              расширяемую архитектуру, требуемую при развитии продукта.
            </p>
            <ul>
              <li>SOA (сервисная архитектура)</li>
              <li>3х-звенная архитектура</li>
              <li>Agile архитектура</li>
              <li>Swagger</li>
            </ul>
          </Item>
          <Item title="Дизайн-система">
            <p>
              Применение дизайн-систем и библиотек компонентов в
              прототипировании продукта обеспечивает высокую скорость
              проектирования интерфейса с учетом устоявшихся паттернов поведения
              пользователя.
            </p>
            <p>
              Дизайн-система по умолчанию обеспечивает кросcбраузерность и
              адаптивность, и при этом она гибкая и оставляет возможность выбора
              визуального решения.
            </p>
            <p>
              При этом в библиотеках уже есть исходная логика компонентов,
              визуальная привлекательность и чистота интерфейсов, что позволяет
              увидеть кликабельный интерфейс сразу.
            </p>
            <ul>
              <li>Material UI</li>
              <li>Ant Design</li>
              <li>Custom Design Sys (2Nova)</li>
              <li>Storybook для React</li>
              <li>Mock Server на NodeJS</li>
            </ul>
          </Item>
          <Item title="Масштабирование нагрузки">
            <p>
              Наш опыт позволяет создавать масштабируемый и устойчивый продукт,
              работающий безотказно при большом количестве интеграций и большом
              количестве единовременных посещений.
            </p>
            <ul>
              <li>Балансировка нагрузки при горизонтальном масштабировании</li>
              <li>Системы мониторинга производительности (Zabbix)</li>
              <li>Системы кэширования (Redis)</li>
              <li>Управление очередями сообщений (Rabbit MQ, Kafka)</li>
              <li>Фокус на устойчивость интеграций</li>
            </ul>
          </Item>
          <Item title="Гигиена кода">
            <p>
              В каждой команде мы стремимся к структурируемому, безопасному и
              чистому коду в приложениях. Это помогает снизить количество ошибок
              в продукте и увеличить количество полезной работы команды.
            </p>
            <p>
              С таким кодом удобно работать, что сокращает накладные расходы при
              привлечении новых участников разработки.
            </p>
            <p>
              Для чего мы используем правила разработки, регулярный рефакторинг
              и дополнительные инструменты кодогенерации и документирования.
            </p>
          </Item>
        </CarouselWithNavigation>
      </PageBlock>

      <PageBlock
        nav={navLabel}
        navIndex="3"
        title={<>наш опыт и экспертиза</>}
        /* description={
          <>
            Мы помогаем крупнейшим компаниям России проходить процесс цифровой
            трансформации путем создания, поддержки и развития портфеля цифровых
            продуктов.
          </>
         } */
        fullHeight
      >
        <CarouselCards>
          <Item
            id="1"
            card={
              <ValueCard
                idx={1}
                image="/images/value-carousel/value-1.svg"
                title="Цифровизация каналов взаимодействия с клиентами"
                // text="Пилот в облаке апробации Дисперсия расточительно отражает интеграл Фурье. Собственное подмножество реально создает предел."
                // tags={['Процесс']}
              />
            }
            /* modal={
              <ValueCardModal
                idx={1}
                image="/images/value-carousel/value-1.svg"
                title="Цифровизация каналов взаимодействия с клиентами"
                process={[
                  'Дизайн-спринт',
                  'Консалтинг по инновационной стратегии',
                ]}
              />
            } */
          />
          <Item
            id="2"
            card={
              <ValueCard
                idx={2}
                image="/images/value-carousel/value-2.svg"
                title="Автоматизация повторяющихся бизнес-процессов"
                // text="Пилот в облаке апробации Дисперсия расточительно отражает интеграл Фурье. Собственное подмножество реально создает предел."
                // tags={['Процесс', 'Технологии']}
              />
            }
            /* modal={
              <ValueCardModal
                idx={2}
                image="/images/value-carousel/value-2.svg"
                title="Прототип"
                process={[
                  'Создание и дизайн прототипа,  а также его тестирование',
                  'Scrum с System Demo',
                  'CustDev, JTBD, CJM',
                ]}
                tech={['Figma, MS Azure', 'Mock Server', 'Atomic UI']}
              />
            } */
          />
          <Item
            id="3"
            card={
              <ValueCard
                idx={3}
                image="/images/value-carousel/value-3.svg"
                title="Управление активами на основе данных"
                // text="Пилот в облаке апробации Дисперсия расточительно отражает интеграл Фурье. Собственное подмножество реально создает предел."
                // tags={['Процесс', 'Технологии']}
              />
            }
            /* modal={
              <ValueCardModal
                idx={3}
                image="/images/value-carousel/value-3.svg"
                title="Пилотирование"
                process={[
                  'MVP на ключевой пользовательский кейс или наименее обслуженнную пользовательскую работу, приоритезация по WSJF',
                  'Scrum/Kanban',
                  'Подсчет количественных и качественных метрик ценности для бизнеса и Unit-экономика',
                ]}
                tech={[
                  'React Native, (??? вероятно это гигиена - Swift, Kotlin, Unity)',
                  'Symphony, Laravel, Flutter и т.п.',
                  'Selectel VPC',
                  'Heroku',
                  'Машины автосборки',
                ]}
              />
            } */
          />
          <Item
            id="4"
            card={
              <ValueCard
                idx={4}
                image="/images/value-carousel/value-4.svg"
                title="Цифровизация опыта сотрудника"
                // text="Пилот в облаке апробации Дисперсия расточительно отражает интеграл Фурье. Собственное подмножество реально создает предел."
                // tags={['Процесс', 'Технологии']}
              />
            }
            /* modal={
              <ValueCardModal
                idx={4}
                image="/images/value-carousel/value-3.svg"
                title="Пилотирование 2"
                process={[
                  'Создание MVP продукта в инфраструктуре заказчика',
                  'Подключение нескольких продуктовых команд',
                  'SAFe-практики разработки',
                  'BI-планирование, Kanban (Program Boadrs)',
                ]}
                tech={[
                  'Swagger',
                  'Docker',
                  'ASP.NET, NodeJS, Bitrix + Laravel',
                  'Amazon Web Services',
                  'Авто-тесты',
                ]}
              />
            } */
          />
        </CarouselCards>
      </PageBlock>

      <PageBlock
        nav={navLabel}
        navIndex="4"
        theme="white"
        themeMobile="white"
        title={<>Нам доверяют</>}
        fullHeight
      >
        <NavigatedContent>
          <Item
            title="Газпром-Нефть"
            image={get(data, 'reviewGazprom.childImageSharp.fixed')}
          >
            <Review
              name="Егор Музыченко"
              position="Старший менеджер продукта, Дирекция инновационного развития"
              isQuote
            >
              Команда завершила проект строго в сроки и достигла поставленных
              целей. Все оказались довольны полученными результатами.
            </Review>
          </Item>
          <Item
            title="Русал"
            image={get(data, 'reviewRusal.childImageSharp.fixed')}
          >
            <Review
              name="Дмитрий Захаров"
              position="Руководитель проектов"
              isQuote
            >
              Резюме:
              <ol>
                <li>
                  очень инициативны и целеустремленны. При этом в некоторой
                  степени идеалистичны;
                </li>
                <li>
                  технологически полностью компетентны и поддерживают степень
                  своей компетенции на высоком уровне для всей команды;
                </li>
                <li>
                  высокая гибкость управления задачами и взаимодействия с
                  заказчиком в виду AGILE подхода.
                </li>
              </ol>
            </Review>
          </Item>
          <Item
            title="Danone"
            image={get(data, 'reviewDanone.childImageSharp.fixed')}
          >
            <Review
              name="Анастасия Захарова"
              position="Digital Manager, Danone Specialized Nutrition CIS"
              isQuote
            >
              В 2Nova сочетают нестандартный подход и необычные методы работы с
              очень практической и чёткой нацеленностью на результат.
              Способность смотреть на задачу глазами клиента, оперативная
              обратная связь и экспертиза в сфере диджитал делает 2Nova
              уникальным для российского рынка технологическим агентством.
            </Review>
          </Item>
          <Item
            title="Estee Lauder"
            image={get(data, 'reviewEsteeLauder.childImageSharp.fixed')}
          >
            <Review
              name="Екатерина Алексеева"
              position="Руководитель продакшн направления"
              isQuote
            >
              Ребята отлично делают свою работу, учитывая наш масштаб,
              стрессоустойчивы и готовы к изменениям. Я довольна таким партнером
              и желаю нам продуктивного сотрудничества в дальнейшем.
            </Review>
          </Item>
        </NavigatedContent>
      </PageBlock>

      <PageBlock nav={navLabel} navIndex="5" title={<>мы на связи</>}>
        <Contacts
          title={<>Мы всегда рады новым партнерам и амбициозным задачам:</>}
          email="hello@2nova.ru"
          desc={
            <>
              Чтобы процесс знакомства и синхронизации прошел эффективней,
              пожалуйста, расскажите о вашем проекте в письме.
            </>
          }
        />
      </PageBlock>

      <FooterBlock />
    </Layout>
  );
};

export const query = graphql`
  query {
    mainImage1: file(relativePath: { eq: "bg/bg1.jpg" }) {
      childImageSharp {
        fixed(height: 825, width: 1237) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mainImage2: file(relativePath: { eq: "bg/bg2.jpg" }) {
      childImageSharp {
        fixed(height: 825, width: 1237) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mainImage3: file(relativePath: { eq: "bg/bg3.jpg" }) {
      childImageSharp {
        fixed(height: 825, width: 1237) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mainImage4: file(relativePath: { eq: "bg/bg4.jpg" }) {
      childImageSharp {
        fixed(height: 825, width: 1237) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    reviewGazprom: file(relativePath: { eq: "reviews/gazprom.png" }) {
      childImageSharp {
        fixed(width: 94) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    reviewRusal: file(relativePath: { eq: "reviews/rusal.png" }) {
      childImageSharp {
        fixed(width: 94) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    reviewDanone: file(relativePath: { eq: "reviews/danone.png" }) {
      childImageSharp {
        fixed(width: 94) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    reviewRosatom: file(relativePath: { eq: "reviews/rosatom.png" }) {
      childImageSharp {
        fixed(width: 94) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    reviewEsteeLauder: file(relativePath: { eq: "reviews/estee-lauder.png" }) {
      childImageSharp {
        fixed(width: 94) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default IndexPage;
