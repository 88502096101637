import styled, { keyframes } from 'styled-components';
import theme from '../../styles/theme';

const moveLeft = keyframes`
    0% { left: 0; }
    38% { left: 64px; }
    100% { left: 0; }
`;

const moveRight = keyframes`
    0% { left: 0; }
    38% { left: -64px; }
    100% { left: 0; }
`;

// eslint-disable-next-line import/prefer-default-export
export const Item = styled.div`
  font-style: normal;
  font-weight: 500;
  color: white;
  width: 300px;
  flex: 0 0 300px;
  margin-right: 10px;
  position: relative;

  font-size: 16px;
  line-height: 24px;

  @media (min-width: ${theme['screen-lg']}) {
    margin-right: 142px;
    font-size: 18px;
    line-height: 26px;
  }

  i {
    border-radius: 50%;
    content: '';
    width: 10px;
    height: 10px;
    background-color: #fff;
    z-index: 2;
    display: none;

    left: 0;
    top: -79px;
    position: absolute;

    @media (min-width: ${theme['screen-lg']}) {
      display: block;
    }

    &.move-left {
      animation: ${moveLeft} cubic-bezier(0.42, 0, 0.58, 1) 900ms;
    }
    &.move-right {
      animation: ${moveRight} cubic-bezier(0.42, 0, 0.58, 1) 900ms;
    }
  }
`;
