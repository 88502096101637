import React from 'react';
import { Item } from './index.styled';
import Hyphenated from '../../utils/hyphenated';

type Props = {
  children: React.ReactNode;
  paused: boolean;
  direction?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
};

const HowWeWorkCarouselItem: React.FC<Props> = ({
  children,
  paused = true,
  direction = '',
  onClick,
  onKeyDown,
}: Props) => (
  <Item>
    {/* <TweenOne
      animation={[
        {
          translateX: '-64px',
          duration: 250,
          ease: 'linear',
        },
        {
          translateX: '-96px',
          duration: 250,
          ease: 'linear',
        },
        {
          translateX: '0',
          duration: 250,
          ease: 'linear',
        },
        {
          translateX: '0',
          duration: 250,
          ease: 'linear',
        },
      ]}
      paused={paused}
      repeat={-1}
      resetStyle
      style={{ position: 'absolute', left: '0', top: '-79px' }}
    >
   </TweenOne> */}
    <i className={paused ? '' : `move-${direction}`} />
    <div onClick={onClick} onKeyDown={onKeyDown} tabIndex={-1} role="button">
      <Hyphenated>{children}</Hyphenated>
    </div>
  </Item>
);

export default HowWeWorkCarouselItem;
